import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import close_icon from 'assests/icons/close_icon.png';

const ModalEdit = ({ isOpen, onRequestClose, setEditorContent }: any) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    setEditorContent(newValue); 
  };

  const handleSubmit = () => {
    console.log("Editor content:", value);
    onRequestClose();
  };

  const handleClose = () => {
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={{
        content: {
          width: "50%",
          height: "60%",
          margin: "auto",
        },
      }}
    >
      <div onClick={handleClose} className="-mt-2 float-right w-4 h-4 cursor-pointer">
        <img src={close_icon} alt="close" />
      </div>
      
      <textarea
        onChange={handleChange}
        value={value}
        placeholder="Write something..."
        className="w-full h-[70%] mb-6 mt-6 p-2 border rounded"
      />
      
      <div className="gap-x-4">
        <button onClick={handleSubmit} className="relative bottom-[-14px] text-white bg-green px-4 py-3 rounded-md hover:px-6 hover:translate-x hover:transition-all">Save Content</button>
        <button onClick={handleClose} className="relative bottom-[-14px] ml-4 border border-main py-2 px-5 rounded-md text-primary hover:px-6 hover:translate-x hover:transition-all">Cancel</button>
      </div>
    </Modal>
  );
};

export default ModalEdit;

