
import React, { useEffect, useState } from "react";
import { book } from "assests";
import { useFormik } from "formik";
import UploadForm from "./components/Form";
import GoodServices from "../AddTradeMark/components/Services";
import CustomRadioButton from "./Checkbox";
import Input from "components/Input";
import Table from "components/Table";
import { trademarkSchema } from "utils/schema";
import { ADDTRADEMARK ,ADDTRADEMARKCOLUMN,TRADEMARKFORM} from './../../constants/index';
import { logo_1,logo_2,logo_3 } from "assests";

const TradeMarkForm = ({ onSubmit,previousValues }:any) => {
  const [selectedOption, setSelectedOption] = React.useState("Logo Trademark");
  const [goodServicesOpen, setGoodServicesOpen] = useState(false);
  const [activeOption, setActiveOption] = useState("");
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  console.log('previousValues', previousValues)
  console.log('activeOption', activeOption)
  const handleSearch = () => {
    if (searchValue.trim() === '') {
      setError('Search input cannot be empty');
      
   
    } else {
      setShowTable(true)
      setError(''); // Clear any previous error messages
    }
  };
  const [showTable,setShowTable]=useState(false)
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    setFieldValue("trademarkLogo", selectedValue);
    if (selectedValue === "Word trademark" && values.wordTrademark==='') {
        console.log("error")
    } else {
      setError(""); 
    }
  };
  
  const handleOptionClick = (option: string) => {
    setActiveOption(option);
    setFieldValue("service",option);
    if (option=== "Services description") {
        setFieldValue("trademarkCount",'');
        setFieldValue("selectClass",'');
        setFieldValue("selectClassNote",'');
    }
    if (option=== "Enter Manually")  {
        setFieldValue("trademarkCount",'');
        setFieldValue("serviceDesc",'');
        setFieldValue("webUrl",'');
    }
    if (option=== "Select Pre-Approved"){
        setFieldValue("webUrl",'');
        setFieldValue("selectClass",'');
        setFieldValue("selectClassNote",'');
        setFieldValue("serviceDesc",'');
    }
  };
  

  const handleUploadSuccess = (file: File) => {
    setGoodServicesOpen(true);
    setUploadedFile(file);
    console.log('file', file.name)
    setFieldValue("fileUpload",file.name);
  };
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    if (previousValues && previousValues.fileUpload) {
      setPreviewImage(previousValues.fileUpload);
    } else {
      setPreviewImage(null);
    }
  }, [previousValues]);
  useEffect(() => {
    if (previousValues) {
      setSelectedOption(previousValues.trademarkLogo);
      setGoodServicesOpen(true)
      setFieldValue("fileUpload",previousValues.fileUpload);
      setFieldValue("service",previousValues.service)
      if (previousValues.service==="Services description"){
        setActiveOption("Services description")
        setFieldValue('serviceDesc',previousValues.serviceDesc)
        setFieldValue('webUrl',previousValues.webUrl)
      }
      if (previousValues.service==="Enter Manually")  {
        setActiveOption("Enter Manually")
        setFieldValue("selectClass",previousValues.selectClass);
        setFieldValue("selectClassNote",previousValues.selectClassNote);
    }
    if (previousValues.service==="Select Pre-Approved"){
      setActiveOption("Select Pre-Approved")
      setFieldValue("trademarkCount",previousValues.trademarkCount);
  }

    }
  }, [previousValues]);
  const { handleSubmit, handleChange, values, touched,  setFieldValue, errors } = useFormik({
    initialValues: TRADEMARKFORM,
    validationSchema: trademarkSchema,
    onSubmit: (values, { setSubmitting }) => {
        if (values.trademarkLogo === "Word trademark" && values.wordTrademark === "" || values.service === "Services description" && values.serviceDesc === ""||values.service === "Services description" && values.webUrl === "") {
          setSubmitting(false);
        } else {
          onSubmit(values);
         
        }
      }})
    
  return (
    <div className="max-w-full mt-8 px-6">
     <form
     className="max-w-full mt-2"
      noValidate
      onSubmit={handleSubmit}
    >
        <CustomRadioButton
        id="radioButton1"
        name="trademarkLogo"
        value="Logo Trademark"
        label="Logo Trademark"
        title='Brand logo including text'
        imageSrc={logo_1}
        checked={selectedOption === "Logo Trademark"}
        onChange={(e:any) => {
            handleRadioChange(e);
        }}
      />
      <CustomRadioButton
        name="trademarkLogo"
        value="Figurative trademark"
        label="Figurative trademark"
        title='Graphical elements of the brand without text'
        imageSrc={logo_2}
        checked={selectedOption === "Figurative trademark"}
        onChange={(e:any) => {
            handleRadioChange(e);
        }}
        
/>        
      <CustomRadioButton
        id="radioButton3"
        name="trademarkLogo"
        value="Word trademark"
        label="Word trademark"
        title='Brand name or slogan'
        imageSrc={logo_3}
        checked={selectedOption === "Word trademark"}
        onChange={(e:any) => {
            handleRadioChange(e);
        }}
      />
        {
         selectedOption === "Word trademark"&&
          <div className="mt-8">
            <Input
            name="wordTrademark"
            placeholder="Example : Nike"
            className="w-full max-w-[600px]  border-b-2 border-main"
            onChange={handleChange}
            value={values.wordTrademark}
            error={(values.trademarkLogo === "Word trademark" && values.wordTrademark === "") && touched.wordTrademark && "Word of TradeMark is Required"}
            title="Enter your word trademark"
          />
          
        </div>
        }
        <div className="w-full">
        <UploadForm onSuccess={handleUploadSuccess} previewImage={previewImage} />

        </div>

      <div className="flex flex-col mt-8">
            {goodServicesOpen && (
              <>
                <GoodServices
                  title={
                    "Let us help - add in goods and services description"
                  }
                  desc={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus placeat eligendi voluptas facere, quae consequatur adipisci, nemo dolor aut expedita tenetur optio officiis ad iste omnis itaque sequi beatae."
                  }
                  onClick={() => handleOptionClick("Services description")}
                />
                <GoodServices
                  title={"Select Pre-Approved"}
                  desc={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque repellendus placeat eligendi voluptas facere, quae consequatur"
                  }
                  onClick={() => handleOptionClick("Select Pre-Approved")}
                />
                <GoodServices
                  title={"Enter Manually"}
                  desc={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit."
                  }
                  onClick={() => handleOptionClick("Enter Manually")}
                />
              </>
            )}
            {activeOption === "Services description" &&  <div className="max-w-[550px]">
      <div className="mt-10">
        <h1 className="text-4xl text-primary font-semibold my-5">
          What goods and services do you sell?
        </h1>
        <label className="text-lg text-primary my-7">
          Description of what your business offers helps us carry out a more
          thorough assessment and similarity screening.
        </label>
      </div>

        <div className="my-10">
          <div className="">
        <Input
             title="Goods and services description"
             placeholder="Example : Software platfrom for creating CVs"
             className="w-full pt-4  border-b-2 border-main"
             name="serviceDesc"
             value={values.serviceDesc}
             onChange={handleChange}
             error={(values.service === "Services description" && values.serviceDesc === "") && touched.wordTrademark && "Services description of TradeMark is Required"}
           />
          </div>
          <div className="">
        <Input
          title="Web (optional, but more than welcome)"
          placeholder="https://www.google.com"
            className="w-full  pt-4  border-b-2 border-main "
            name="webUrl"
            value={values.webUrl}
            onChange={handleChange}
            error={(values.service === "Services description" && values.webUrl === "") && touched.wordTrademark && "web url of TradeMark is Required"}
          />
          </div>
        </div>
      
        <button className="w-full bg-[#033d44] max-w-[400px] mx-auto rounded-md justify-center items-center flex py-4  px-4 text-white my-10" type="submit">
        Continue
      </button>
    </div>}
            {activeOption === "Enter Manually" &&  <div className="max-w-[550px]">
      <div className="mt-10">
        <h1 className="text-4xl text-primary font-semibold my-5">
          Goods & Services
        </h1>
        <div className="flex gap-x-4 mt-4">
          <div className="w-[10px] bg-green h-18" />
          <label className="text-lg text-primary ">
            Your description of the goods and servies must be clear and
            unambiguious
            <br />
            Only list goods and servies which belong to selected class. You will
            be able to add additional classes on the next screen
          </label>
        </div>
      </div>
    
      <div className="my-10">
        <label className="text-xl text-primary font-semibold mt-5">
          Select Class
        </label>
        <select className="w-full rounded-md py-4 px-4 border-b-2 border-main text-primary" name="selectClass" value={values.selectClass} onChange={handleChange}>
          {Array.from({ length: 30 }, (_, index) => (
            <option key={index + 1} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label className="text-xl text-primary font-bold mt-5">
          Add Your Goods and/or Services
        </label>
        <p className="text-md text-primary">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis, minima
          neque aut dignissimos id debitis consequuntur a mollitia sequi
          pariatur nam fuga dolores ratione illo fugit eos, nemo vel. Id.
        </p>
        <textarea className="w-full rounded-md focus:outline-none px-4 border-b-2 border-main mt-4" name="selectClassNote" value={values.selectClassNote} onChange={handleChange} />
      </div>
      <button className="w-full bg-[#033d44] rounded-md justify-center items-center flex py-4  px-4 text-white my-10 max-w-[400px] mx-auto" type="submit">
        Continue
      </button>
    </div>}
    {activeOption === "Select Pre-Approved" &&  <div className="max-w-[550px] mt-10">
      <h2 className="text-4xl text-primary font-bold mt-5">Apply to Register Trade Mark</h2>
      <label className="text-lg text-primary ">Your description of the goods and services must be clear and unambiguous next screen</label>
      <select className="w-full py-4 px-4 border-b-2 border-main focus:outline-none text-primary" name="trademarkCount" value={values.trademarkCount} onChange={handleChange}>
          {Array.from({ length: 30 }, (_, index) => (
            <option key={index + 1} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>
      <div className="flex gap-x-4 mt-4">
        <input
          placeholder="Enter a search Item"
          className="w-full mt-4 py-4 px-4 border-b-2 border-main focus:outline-none"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setError(''); // Clear error message when user types
          }}
        />
        <button className="bg-green rounded-md text-white px-4 py-4 mt-4" onClick={handleSearch} >
          Search
        </button>
      </div>
      <div className='mt-4'>
      {showTable && <Table data={ADDTRADEMARK} columns={ADDTRADEMARKCOLUMN}/>}

      </div>
      
      {error && <p className="text-[#F70000]">{error}</p>}
      <button className="w-full bg-[#033d44] rounded-md justify-center items-center flex py-4  px-4 text-white my-10 max-w-[400px] mx-auto " type="submit">
        Continue
      </button>
    </div>}
          </div>
   
    </form>
      
    </div>
  );
};

export default TradeMarkForm



