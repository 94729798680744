import { useState } from "react"
import RecommendationCard from "../RecommendationCard";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
const Main = () => {
  const [selectedTimeframe, setSelectedTimeframe] = useState('1year');
  const [baseValuation] = useState(5000000);
  const sampleRecommendations = [
    {
      type: 'missingTrademarks',
      title: 'Missing Trade Mark Protection',
      description: 'Critical gaps in trade mark portfolio requiring immediate action',
      markets: ['US', 'EU', 'China'],
      timeframe: 6, // months
      steps: [
        'File trade mark applications in identified markets',
        'Review and update existing portfolio',
        'Implement watching services',
        'Establish defence strategy'
      ]
    },
    {
      type: 'licenseRisk',
      title: 'License Revenue Risk',
      description: 'Potential revenue loss from inadequate license protection',
      markets: ['Global'],
      timeframe: 3, // months
      steps: [
        'Review existing license agreements',
        'Implement license monitoring system',
        'Update license terms and conditions',
        'Establish enforcement protocol'
      ]
    },
    {
      type: 'marketCoverage',
      title: 'Market Coverage Gaps',
      description: 'Insufficient protection in key growth markets',
      markets: ['India', 'Brazil', 'Japan'],
      timeframe: 9, // months
      steps: [
        'Analyze market opportunities',
        'File strategic applications',
        'Establish local partnerships',
        'Implement market monitoring'
      ]
    }
  ];

  const calculateImpact = (recommendation: any) => {
    const baseImpact: any = {
      missingTrademarks: 0.15,
      licenseRisk: 0.20,
      marketCoverage: 0.10,
      protectionGaps: 0.12
    };

    const implementationCost: any = {
      missingTrademarks: recommendation.markets.length * 1500,
      licenseRisk: 25000,
      marketCoverage: 50000,
      protectionGaps: 35000
    };

    return {
      potentialLoss: baseValuation * baseImpact[recommendation.type],
      implementationCost: implementationCost[recommendation.type],
      projectedSavings: (baseValuation * baseImpact[recommendation.type]) * 0.8,
      timeToImplement: recommendation.timeframe,
      roi: (baseValuation * baseImpact[recommendation.type] * 0.8) / implementationCost[recommendation.type]
    };
  };

  const generateProjections = (recommendation: any, months: any) => {
    const impact = calculateImpact(recommendation);
    const monthlyData = [];

    for (let i = 0; i <= months; i++) {
      const implementationProgress = Math.min(i / recommendation.timeframe, 1);
      const projectedValue = baseValuation - (impact.potentialLoss * (1 - implementationProgress));
      const protectedValue = baseValuation - (impact.implementationCost * (i === 0 ? 1 : 0));

      monthlyData.push({
        month: i,
        'Without Action': projectedValue,
        'With Protection': protectedValue + (impact.projectedSavings * implementationProgress)
      });
    }

    return monthlyData;
  };


  return (
    <div className="container mx-auto mt-10 mb-5 pb-5">
      <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
        <span className='text-xl font-bold'>Valuation Impact Analysis</span>
        <p className="font-medium mb-3">Compare valuation outcomes with and without implementing recommended actions</p>
        <div className="mb-6">
          <div className="flex gap-4">
            <button
              className={`px-4 py-2 rounded-lg ${selectedTimeframe === '1year' ? 'bg-green text-white' : 'bg-white border text-black'}`}
              onClick={() => setSelectedTimeframe('1year')}
            >
              1 Year Projection
            </button>
            <button
              className={`px-4 py-2 rounded-lg ${selectedTimeframe === '2year' ? 'bg-green text-white' : 'bg-white border text-black'}`}
              onClick={() => setSelectedTimeframe('2year')}
            >
              1 Year Projection
            </button>
          </div>
        </div>
        {sampleRecommendations.map((recommendation, index) => (
          <RecommendationCard
            key={index}
            recommendation={recommendation}
            calculateImpact={calculateImpact}
            generateProjections={generateProjections}
            selectedTimeframe={selectedTimeframe}
          />
        ))}
        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl mt-6">
          <span className='text-xl font-bold'>Total Impact Summary</span>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-5">
            <div className="space-y-4">
              <div className="p-4 bg-[#FEF2F2] rounded-lg">
                <h3 className="font-semibold text-[#B91C1C]">Total Potential Loss</h3>
                <p className="text-2xl font-bold text-[#991B1B]">
                  {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                    .format(sampleRecommendations.reduce((acc, rec) =>
                      acc + calculateImpact(rec).potentialLoss, 0
                    ))}
                </p>
              </div>

              <div className="p-4 bg-[#F0FDF4] rounded-lg">
                <h3 className="font-semibold text-[#15803D]">Total Protected Value</h3>
                <p className="text-2xl font-bold text-[#166534]">
                  {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                    .format(sampleRecommendations.reduce((acc, rec) =>
                      acc + calculateImpact(rec).projectedSavings, 0
                    ))}
                </p>
              </div>
            </div>

            <BarChart
              width={500}
              height={300}
              data={[
                {
                  name: 'Current',
                  value: baseValuation
                },
                {
                  name: 'Without Action',
                  value: baseValuation - sampleRecommendations.reduce((acc, rec) =>
                    acc + calculateImpact(rec).potentialLoss, 0
                  )
                },
                {
                  name: 'With Protection',
                  value: baseValuation + sampleRecommendations.reduce((acc, rec) =>
                    acc + calculateImpact(rec).projectedSavings - calculateImpact(rec).implementationCost, 0
                  )
                }
              ]}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#6366f1" />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Main