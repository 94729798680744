type TProps = {
    heading: string | [string],
    value: number | string,
    classname?: string
    titleclass?: string
    valueclass?: string
}
const InfoCards = ({ heading, value, classname, valueclass, titleclass }: TProps) => {
    return (<div className={`bg-white p-[25px] rounded-[5px] h-[104px] shadow-2xl ${classname}`}>
        <h5 className={`${valueclass} font-bold text-black`}>{value}</h5>
        <p className={`${titleclass} mb-0 text-sm text-black`}>{heading}</p>
    </div>)
}
export default InfoCards