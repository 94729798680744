import {
    Settings, Clock, CheckCircle, User,
    Plus, X
} from 'lucide-react';
import { useState } from 'react';
const Main = () => {
    const [showSettings, setShowSettings] = useState(false);
    const [reminders, setReminders] = useState([
        {
            id: 1,
            type: 'Payment',
            licenseId: 'LIC-2024-001',
            dueDate: '2024-03-15',
            amount: 25000,
            parties: [
                { role: 'Licensee', email: 'finance@techcorp.com' },
                { role: 'Legal Rep', email: 'legal@techcorp.com' }
            ],
            frequency: 'Quarterly',
            reminderDays: [30, 14, 7, 1],
            status: 'pending'
        },
        {
            id: 2,
            type: 'Renewal',
            licenseId: 'LIC-2024-003',
            dueDate: '2024-04-01',
            parties: [
                { role: 'Licensor', email: 'ip@brandco.com' },
                { role: 'Licensee', email: 'legal@licensee.com' }
            ],
            frequency: 'Annually',
            reminderDays: [60, 30, 14],
            status: 'scheduled'
        }
    ]);

    const [settings, setSettings] = useState({
        defaultReminders: {
            payment: [30, 14, 7, 1],
            renewal: [60, 30, 14],
            reporting: [14, 7, 3]
        },
        notificationMethods: {
            email: true,
            dashboard: true,
            blockchain: true
        },
        escalation: {
            enabled: true,
            threshold: 3,
            escalateToRoles: ['Legal Representative', 'Finance Director']
        }
    });
    return (
        <div className="container mx-auto mt-10 pb-5">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold">Payment Reminders & Notifications</h2>
                <div className="flex gap-4">
                    <button
                        onClick={() => setShowSettings(true)}
                        className="flex items-center gap-2 px-4 py-2 border rounded-lg"
                    >
                        <Settings className="w-5 h-5" />
                        Settings
                    </button>
                    <button
                        className="flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg"
                    >
                        <Plus className="w-5 h-5" />
                        New Reminder
                    </button>
                </div>
            </div>

            <div className="bg-[#EFF6FF] border border-[#BFDBFE] flex items-center mt-5 p-5">
                <Clock className="w-4 h-4 text-[#2563EB] mr-3" />
                <div className="text-[#1E40AF]">
                    Next payment reminder will be sent on <span className="font-medium">March 1, 2024</span> for
                    License LIC-2024-001 (Amount: $25,000)
                </div>
            </div>

            <div className="mt-5">
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className='font-bold mb-5'>
                        Upcoming Reminders
                    </div>
                    <div>
                        <div className="space-y-4">
                            {reminders.map((reminder) => (
                                <div key={reminder.id} className="p-4 border rounded-lg">
                                    <div className="flex justify-between items-start mb-4">
                                        <div>
                                            <h3 className="font-medium">{reminder.type} Reminder</h3>
                                            <p className="text-sm text-[#6B7280]">License: {reminder.licenseId}</p>
                                        </div>
                                        <div className={`px-2 py-1 rounded-full text-sm ${reminder.status === 'pending' ? 'bg-[#FEF9C3] text-[#854D0E]' :
                                            'bg-[#DCFCE7] text-[#166534]'
                                            }`}>
                                            {reminder.status.charAt(0).toUpperCase() + reminder.status.slice(1)}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-3 gap-4 mb-4">
                                        <div>
                                            <p className="text-sm text-[#6B7280]">Due Date</p>
                                            <p className="font-medium">{reminder.dueDate}</p>
                                        </div>
                                        {reminder.amount && (
                                            <div>
                                                <p className="text-sm text-[#6B7280]">Amount</p>
                                                <p className="font-medium">${reminder.amount.toLocaleString()}</p>
                                            </div>
                                        )}
                                        <div>
                                            <p className="text-sm text-[#6B7280]">Frequency</p>
                                            <p className="font-medium">{reminder.frequency}</p>
                                        </div>
                                    </div>

                                    <div className="space-y-2">
                                        <p className="text-sm text-[#6B7280]">Notification Recipients</p>
                                        {reminder.parties.map((party, index) => (
                                            <div key={index} className="flex items-center gap-2 text-sm">
                                                <User className="w-4 h-4 text-[#6B7280]" />
                                                <span className="text-[#4B5563]">{party.role}:</span>
                                                <span>{party.email}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {showSettings && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl w-full max-w-2xl">
                            <div className="flex flex-row items-center justify-between">
                                <span className='font-bold'>Reminder Settings</span>
                                <button
                                    onClick={() => setShowSettings(false)}
                                    className="text-[#6B7280]"
                                >
                                    <X className="w-5 h-5" />
                                </button>
                            </div>
                            <div>
                                <div className="space-y-6">
                                    {/* Default Reminder Settings */}
                                    <div className="space-y-4">
                                        <h3 className="font-medium">Default Reminder Schedule</h3>
                                        {Object.entries(settings.defaultReminders).map(([type, days]) => (
                                            <div key={type} className="space-y-2">
                                                <label className="text-sm text-[#4B5563]">
                                                    {type.charAt(0).toUpperCase() + type.slice(1)} Reminders
                                                </label>
                                                <div className="flex gap-2 flex-wrap">
                                                    {days.map((day) => (
                                                        <span key={day} className="px-3 py-1 bg-[#F3F4F6] rounded-full text-sm">
                                                            {day} days before
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Notification Methods */}
                                    <div className="space-y-4">
                                        <h3 className="font-medium">Notification Methods</h3>
                                        {Object.entries(settings.notificationMethods).map(([method, enabled]) => (
                                            <div key={method} className="flex items-center justify-between">
                                                <span className="text-[#4B5563]">
                                                    {method.charAt(0).toUpperCase() + method.slice(1)} Notifications
                                                </span>
                                                <div
                                                    className={`w-12 h-6 rounded-full transition-colors ${enabled ? 'bg-green' : 'bg-[#E5E7EB]'
                                                        } cursor-pointer`}
                                                    onClick={() => {
                                                        setSettings(prev => ({
                                                            ...prev,
                                                            notificationMethods: {
                                                                ...prev.notificationMethods,
                                                                [method]: !enabled
                                                            }
                                                        }));
                                                    }}
                                                >
                                                    <div className={`w-5 h-5 rounded-full bg-white transform transition-transform ${enabled ? 'translate-x-6' : 'translate-x-1'
                                                        } mt-0.5`} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Save Button */}
                                    <div className="flex justify-end gap-4 pt-4">
                                        <button
                                            onClick={() => setShowSettings(false)}
                                            className="px-4 py-2 text-[#4B5563]"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => setShowSettings(false)}
                                            className="px-4 py-2 bg-green text-white rounded-lg"
                                        >
                                            Save Settings
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default Main