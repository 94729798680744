// import { useRef } from "react";
// import { DoughnutChart } from "components";
// import { useMemo, useState } from "react";
// import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
// import {
//     STATUSTABLE
// } from "constants/index";
// import ChartTableView from "components/ChartTable";
// type TProps = {
//     dataset: any;
// };
// const StatusDistribution = ({ dataset }: TProps) => {
//     const graphRef = useRef<HTMLDivElement>(null);
//     const { getStatusData } = useTrademarkAnalysisData()
//     const [showStatusTable, setShowStatusTable] = useState(false)
//     const [statusData, setStatusData] = useState([])
//     const [title, setTitle] = useState("")
//     const setStatus = async (data: string) => {
//         const tableData = await getStatusData(data)
//         setTitle(data)
//         setStatusData(tableData)
//         setShowStatusTable(true)
//     }
//     const close = () => {
//         setShowStatusTable(false);
//     };
//     const selectedStatusData = useMemo(
//         () =>
//             statusData?.map(
//                 (item: any, index) => {
//                     return {
//                         col1: item["App. date"] ? item["App. date"] : '-',
//                         col2: item["Country"],
//                         col3: item["Exp. date"],
//                         col4: item["Owner"],
//                         col5: item["Reg. date"],
//                         col6: item["Status"],
//                         col7: item["Trade Mark"],
//                     };
//                 }
//             ),
//         [statusData]
//     );
//     const statusDistribution = useMemo(() => {
//         const totalStatusDistribution =
//             dataset.reduce(
//                 (n: any, { Count }: any) => n + Count,
//                 0
//             ) || 0;
//         let data: any = {
//             labels: [],
//             datasets: [
//                 {
//                     label: "Status Distribution",
//                     data: [],
//                     backgroundColor: ["#033d44", "#8A77FF", "#70FF00", "#FF4D00"],
//                     borderColor: ["#033d44", "#8A77FF", "#70FF00", "#FF4D00"],
//                     borderWidth: 1,
//                 },
//             ],
//         };
//         dataset.map((item: any) => {
//             data["labels"].push(item.Status);
//             data.datasets[0].data.push((item.Count / totalStatusDistribution) * 100);
//         });
//         return data;
//     }, [dataset]);
//     return (

//   <div>
//   <div >  
//             <DoughnutChart
//                 chartData={statusDistribution}
//                 title="Status Distribution"
//                 sign={"$"}
//                 sendSliceData={setStatus}
//             />
//             <ChartTableView column={STATUSTABLE} data={selectedStatusData}
//                 title={`Status: ${title}`}
//                 openModal={showStatusTable}
//                 isClose={close}
//             />
//        </div>
//     </div>
//     )
// }
// export default StatusDistribution

import { useRef, useMemo, useState } from "react";
import { DoughnutChart } from "components";
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
import { STATUSTABLE } from "constants/index";
import ChartTableView from "components/ChartTable";

type TProps = {
    dataset:any[] | undefined
};

const StatusDistribution = ({ dataset = [] }: TProps) => {
    const graphRef = useRef<HTMLDivElement>(null);
    const { getStatusData } = useTrademarkAnalysisData();
    const [showStatusTable, setShowStatusTable] = useState(false);
    const [statusData, setStatusData] = useState([]);
    const [title, setTitle] = useState("");

    const setStatus = async (data: string) => {
        setTitle(data);
        setShowStatusTable(true);
    };

    const close = () => {
        setTitle('')
        setShowStatusTable(false);
    };


    const statusDistribution = useMemo(() => {
        if (!Array.isArray(dataset) || dataset.length === 0) {
            return {
                labels: [],
                datasets: [
                    {
                        label: "Status Distribution",
                        data: [],
                        backgroundColor: ["#033d44", "#8A77FF", "#70FF00", "#FF4D00"],
                        borderColor: ["#033d44", "#8A77FF", "#70FF00", "#FF4D00"],
                        borderWidth: 1,
                    },
                ],
            };
        }

        const totalStatusDistribution = dataset.reduce(
            (n: any, { Count }: any) => n + Count,
            0
        );

        let data: any = {
            labels: [],
            datasets: [
                {
                    label: "Status Distribution",
                    data: [],
                    backgroundColor: ["#033d44", "#8A77FF", "#70FF00", "#FF4D00"],
                    borderColor: ["#033d44", "#8A77FF", "#70FF00", "#FF4D00"],
                    borderWidth: 1,
                },
            ],
        };

        dataset.forEach((item: any) => {
            data.labels.push(item.Status);
            data.datasets[0].data.push((item.Count / totalStatusDistribution) * 100);
        });

        return data;
    }, [dataset]);

    return (
        <div>
            <div>
                <DoughnutChart
                    chartData={statusDistribution}
                    title="Status Distribution"
                    sign={"$"}
                    sendSliceData={setStatus}
                />
                {title ? 
                <ChartTableView
                    column={STATUSTABLE}
                    endpoint={'status_info'}
                    params={'status'}
                    data={null}
                    title={`Status: ${title}`}
                    paramsValue={title}
                    openModal={showStatusTable}
                    isClose={close}
                    fields={
                        ["App. date", "Country", "Exp. date", "Owner", "Reg. date", "Status", "Trade Mark","Image Path"]
                    }
                />
                : null}
            </div>
        </div>
    );
};

export default StatusDistribution;

