import { api } from "config";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useTrademarkAnalysis } from "state/trademarkAnalysis/hooks";

export const useTrademarkAnalysisData = () => {
  const {
    setTrademarkAnalysis,
    tableRecords,
    showResults,
    setMissingProductTerm,
    missingProductTerm,
    uniqueOwner,
    missingProductTermInCountry,
    setUniqueOwner,
    setMissingProductTermInCountries,
    setCountryDistribution,
    countryDistribution,
    setMissingMark,
    missingMark,
    setShowResults,
    processing,
    setProcessing,
    setFilename,
    filename
  } = useTrademarkAnalysis();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [value, setValue] = useState("");
  const [polling, setPolling] = useState(false);
  const [show, setShow] = useState(false)
  const [uniqueOwnerField, setUniqueOwnerField] = useState([])
  const [ownerDiscrepancy, setOwnerDiscrepancy] = useState([])
  const [unusedTrademarks, setUnusedTrademarks] = useState([])
  const [unusedTrademarksCount, setUnusedTrademarksCount] = useState(0)
  const [allCountriesRepresentationData, setAllCompaniesRepresentationData] = useState([])
  const [uniqueTotal, setUniqueTotal] = useState<any>()
  const resetData = () =>{
    setTrademarkAnalysis(null)
    setUnusedTrademarks([])
    setUnusedTrademarksCount(0)
    setMissingProductTermInCountries(null)
    setUniqueOwner(null)
    setUniqueTotal(0)
    setMissingProductTermInCountries(null)
    setCountryDistribution(null)
    setMissingMark(null)
  }
  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const { data } = await api.post("process_excel", formData, {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setValue(`${loaded} bytes of ${total} bytes | ${percent}%`);
          setUploadPercentage(percent);
        },
      });
      return data;
    } catch (error: unknown) {
      console.log("error", error);
      throw error;
    }
  };
  const getOwnerDiscrepancy = async (userFilename:any) => {
    try {
      const ownerDiscrepancy = await api.get(`owner_discrepancy?page=1&filename=${userFilename}`);
      setUniqueOwner(ownerDiscrepancy.data.data.unique_owners)
      setUniqueTotal(ownerDiscrepancy.data.total_unique_owners)

      // setUniqueOwner(ownerDiscrepancy.data[1].unique_owners);
    } catch (error) {
      console.error("Error fetching owner discrepancy data:", error);
    }
  };
  const getUnusedTrademarks = async (userFilename:any) => {
    try {
      const { data } = await api.get(`unused_trademarks?page=1&size=10&filename=${userFilename}`);
      setUnusedTrademarks(data.data)
      setUnusedTrademarksCount(data.total)
    } catch (error) {
      console.error("Error fetching owner discrepancy data:", error);
    }
  };
  const getAllCountryRepresentationData = async (userFilename:any) => {
    try {
      const { data } = await api.get(`country_representative_info?filename=${userFilename}`);
      setAllCompaniesRepresentationData(data)
    } catch (error) {
      console.error("Error fetching owner discrepancy data:", error);
    }
  };
  const checkStatus = async (userFilename:any) => {
    try {
      const { data } = await api.get(`process_status?filename=${userFilename}`, {
        // Commented out the progress tracking for brevity
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setValue(`${loaded} bytes of ${total} bytes | ${percent}%`);
          setUploadPercentage(percent);
        },
      });
      return data;
    } catch (error: unknown) {
      console.error("Error checking status:", error);
      throw error;
    }
  };

  const fetchAnalysisData = async (userFilename:any) => {
    try {
      const { data } = await api.get(`analyze_trademark?filename=${userFilename}`);

      return data;
    } catch (error: unknown) {
      console.error("Error fetching analysis data:", error);
      throw error;
    }
  };
  const processFile = async (file: any) => {
    try {
      setProcessing(true)
      setShowResults(false)
      setUploadPercentage(0)
      resetData()
      const {file_name} =  await uploadFile(file);
      setFilename(file_name)
      setPolling(true);
      const pollInterval = setInterval(async () => {
        try {
          console.log(file_name)
          const statusResponse = await checkStatus(file_name);
          if (statusResponse.status === "Completed") {
            clearInterval(pollInterval);
            setPolling(false);
            const analysisData = await fetchAnalysisData(file_name);
            setTrademarkAnalysis(analysisData.results);
            setAllCompaniesRepresentationData(analysisData.results?.country_representation)
            setShowResults(true)
            setProcessing(false)
            await Promise.all([
              getMissingProductTermInCountries(file_name),
              getOwnerDiscrepancy(file_name),
              getMissingProductTerm(file_name),
              getCountryDistribution(file_name),
              getMissingMark(file_name),
              getUnusedTrademarks(file_name)
            ]);
          } else {
            // Increment the progress gradually between 50% and 100%
            setUploadPercentage((prev) => Math.min(prev + 5, 99));
            setShow(true);
          }
        } catch (error) {
          clearInterval(pollInterval);
          setPolling(false);
          handleError(error);
          setProcessing(false)
        }
      }, 1000); 
    } catch (error) {
      handleError(error);
    }
  };


  const handleError = (e: unknown) => {
    let message = "Something went wrong";
    if (e instanceof Error) {
      message = e.message;
    }
    toast.error(message);
  };
  const getClassesData = useCallback(async (className: string) => {
    try {
      const { data } = await api.get(`classes_info?class_name=${className}`);
      const sanitizedData = data.replace(/NaN/g, "null"); // Replace NaN with null
      const jsonData = JSON.parse(sanitizedData);
      return jsonData;
    } catch (e: unknown) {
      handleError(e);
    }
  }, []);
  const getStatusData = useCallback(async (className: string) => {
    try {
      const { data } = await api.get(`status_info?status=${className}&filename=${filename}`);
      const sanitizedData = data.replace(/NaN/g, "null"); // Replace NaN with null
      const jsonData = JSON.parse(sanitizedData);
      return jsonData;
    } catch (e: any) {
      console.log(e);
      toast.error(e);
    }
  }, []);
  const getMissingProductTermInCountries = async (userFilename:any) => {
    try {
      const missingProductTermInCountries = await api.get(
        `missing_product_term_country_chart?filename=${userFilename}`
      );

      setMissingProductTermInCountries(missingProductTermInCountries.data.data);
    } catch (error) {
      console.error(
        "Error fetching missing product term in countries data:",
        error
      );
    }
  }
  const getMissingMark = async (userFilename:any) => {
    try {
      const missing_mark = await api.get(
        `missing_marks_chart?filename=${userFilename}`
      );

      setMissingMark(missing_mark.data);
    } catch (error) {
      console.error(
        "Error fetching missing product term in countries data:",
        error
      );
    }
  };
  const getCountryDistribution = async (userFilename:any) => {
    try {
      const countryDistribution = await api.get(
        `country_distribution_chart?filename=${userFilename}`
      );

      setCountryDistribution(countryDistribution.data.country_distribution);
    } catch (error) {
      console.error(
        "Error fetching missing product term in countries data:",
        error
      );
    }
  };
  const getMissingProductTerm = async (userFilename:any) => {
    try {
      const missingProductTerm = await api.get(`missing_product_term_chart?filename=${userFilename}`);
      setMissingProductTerm(missingProductTerm.data.data);
    } catch (error) {
      console.error("Error fetching missing product term data:", error);
    }
  };
  return {
    processFile,
    uploadPercentage,
    value,
    setUploadPercentage,
    tableRecords,
    showResults,
    missingProductTerm,
    missingProductTermInCountry,
    uniqueOwner,
    polling,
    getClassesData,
    getStatusData,
    getMissingProductTermInCountries,
    getMissingProductTerm,
    getCountryDistribution,
    countryDistribution,
    missingMark,
    show,
    uniqueOwnerField,
    uniqueTotal,
    ownerDiscrepancy,
    unusedTrademarks,
    unusedTrademarksCount,
    processing,
    filename,
    getAllCountryRepresentationData,
    allCountriesRepresentationData
  };
};

