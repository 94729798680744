const InputField = ({ label, value, onChange, type = "number" }:any) => (
    <div className="space-y-2">
      <label className="text-sm font-medium capitalize">{label}</label>
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none"
        />
    </div>
  );
  
  export default InputField