export const dummyData = [
    {
      budget: 300,
      currentCost: 300,
      case_ref: "T25648WO/CY",
      country: "Cyprus",
      official_no: "9323232",
      title: "@TARNTOOLA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: `Add a link to this in the transcript section or have this pop out.\nThis is an update for case T 5 4 5 5 for the mark T in classes 35 and 39. I'm glad to tell you that this mark is matured to registration. Our summary of this will be available in the report and also we have a helpful guide for you to click on to make sure your mark is validated and used in the first five years.\nThe next day of you to docket is the 14th of January, 2033. When that mark will be open for renewal, we'll docket that as well, and I'll let you know the time when ready to renewed.`,
      summary: `Help Guide <b>here.</b><br/><br/><b> Next docket 14 Jan 2033</b>`,
      cartData: ["Renew GBP 1200"],
    },
    {
      budget: 300,
      currentCost: 300,
      case_ref: "T25648WO/CY",
      country: "Cyprus",
      official_no: "9323232",
      title: "@TARNTOOLA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: `Add a link to this in the transcript section or have this pop out.\nThis is an update for case T 5 4 5 5 for the mark T in classes 35 and 39. I'm glad to tell you that this mark is matured to registration. Our summary of this will be available in the report and also we have a helpful guide for you to click on to make sure your mark is validated and used in the first five years.\nThe next day of you to docket is the 14th of January, 2033. When that mark will be open for renewal, we'll docket that as well, and I'll let you know the time when ready to renewed.`,
      summary: `Help Guide <b>here</b><br/><br/>
  
      <b>Next docket 14 Jan 2033</b>`,
      cartData: ["Renew GBP 1200"],
    },
    {
      budget: 300,
      currentCost: 300,
      case_ref: "T25648WO/CY",
      country: "Cyprus",
      official_no: "9323232",
      title: "@TARNTOOLA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: `Add a link to this in the transcript section or have this pop out.\nThis is an update for case T 5 4 5 5 for the mark T in classes 35 and 39. I'm glad to tell you that this mark is matured to registration. Our summary of this will be available in the report and also we have a helpful guide for you to click on to make sure your mark is validated and used in the first five years.\nThe next day of you to docket is the 14th of January, 2033. When that mark will be open for renewal, we'll docket that as well, and I'll let you know the time when ready to renewed.`,
      summary: `Help Guide <b>here</b><br/><br/>
  
      <b>Next docket 14 Jan 2033</b>`,
      cartData: ["Renew GBP 1200"],
    },
    {
      budget: 300,
      currentCost: 300,
      case_ref: "T25648WO/CY",
      country: "Cyprus",
      official_no: "9323232",
      title: "@TARNTOOLA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: `Add a link to this in the transcript section or have this pop out.\nThis is an update for case T 5 4 5 5 for the mark T in classes 35 and 39. I'm glad to tell you that this mark is matured to registration. Our summary of this will be available in the report and also we have a helpful guide for you to click on to make sure your mark is validated and used in the first five years.\nThe next day of you to docket is the 14th of January, 2033. When that mark will be open for renewal, we'll docket that as well, and I'll let you know the time when ready to renewed.`,
      summary: `Help Guide <b>here</b><br/><br/>
  
      <b>Next docket 14 Jan 2033</b>`,
      cartData: ["Renew GBP 1200"],
    },
    {
      budget: 300,
      currentCost: 300,
      case_ref: "T25648WO/CY",
      country: "Cyprus",
      official_no: "9323232",
      title: "@TARNTOOLA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: `Add a link to this in the transcript section or have this pop out.\nThis is an update for case T 5 4 5 5 for the mark T in classes 35 and 39. I'm glad to tell you that this mark is matured to registration. Our summary of this will be available in the report and also we have a helpful guide for you to click on to make sure your mark is validated and used in the first five years.\nThe next day of you to docket is the 14th of January, 2033. When that mark will be open for renewal, we'll docket that as well, and I'll let you know the time when ready to renewed.`,
      summary: `Help Guide <b>here</b><br/><br/>
  
      <b>Next docket 14 Jan 2033</b>`,
      cartData: ["Renew GBP 1200"],
    },
    {
      budget: 300,
      currentCost: 300,
      case_ref: "T25648WO/CY",
      country: "Cyprus",
      official_no: "9323232",
      title: "@TARNTOOLA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: `Add a link to this in the transcript section or have this pop out.\nThis is an update for case T 5 4 5 5 for the mark T in classes 35 and 39. I'm glad to tell you that this mark is matured to registration. Our summary of this will be available in the report and also we have a helpful guide for you to click on to make sure your mark is validated and used in the first five years.\nThe next day of you to docket is the 14th of January, 2033. When that mark will be open for renewal, we'll docket that as well, and I'll let you know the time when ready to renewed.`,
      summary: `Help Guide <b>here</b><br/><br/>
  
      <b>Next docket 14 Jan 2033</b>`,
      cartData: ["Renew GBP 1200"],
    },
    {
      budget: 300,
      currentCost: 300,
      case_ref: "T25648WO/CY",
      country: "Cyprus",
      official_no: "9323232",
      title: "@TARNTOOLA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: `Add a link to this in the transcript section or have this pop out.\nThis is an update for case T 5 4 5 5 for the mark T in classes 35 and 39. I'm glad to tell you that this mark is matured to registration. Our summary of this will be available in the report and also we have a helpful guide for you to click on to make sure your mark is validated and used in the first five years.\nThe next day of you to docket is the 14th of January, 2033. When that mark will be open for renewal, we'll docket that as well, and I'll let you know the time when ready to renewed.`,
      summary: `Help Guide <b>here</b><br/><br/>
  
      <b>Next docket 14 Jan 2033</b>`,
      cartData: ["Renew GBP 1200"],
    },
    {
      budget: 1000,
      currentCost: 750,
      case_ref: "T2054",
      country: "Ireland",
      official_no: "9323233",
      title: "GAMBSA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: "",
      summary: "",
      cartData: [
        "Proceed with Opposition response GBP 800",
        "Conduct Investigation GBP 400",
      ],
    },
    {
      budget: 1000,
      currentCost: 750,
      case_ref: "T2054",
      country: "Ireland",
      official_no: "9323233",
      title: "GAMBSA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: "",
      summary: "",
      cartData: [
        "Proceed with Opposition response GBP 800",
        "Conduct Investigation GBP 400",
      ],
    },
    {
      budget: 1000,
      currentCost: 750,
      case_ref: "T2054",
      country: "Ireland",
      official_no: "9323233",
      title: "GAMBSA",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: "",
      summary: "",
      cartData: [
        "Proceed with Opposition response GBP 800",
        "Conduct Investigation GBP 400",
      ],
    },
    {
      budget: 800,
      currentCost: 500,
      case_ref: "T20545454",
      country: "Poland",
      official_no: "9323233",
      title: "REET AND DEVICE",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: "",
      summary: "",
      cartData: ["New App GBP 980"],
    },
    {
      budget: 800,
      currentCost: 500,
      case_ref: "T20545454",
      country: "Poland",
      official_no: "9323233",
      title: "REET AND DEVICE",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: "",
      summary: "",
      cartData: ["New App GBP 980"],
    },
    {
      budget: 800,
      currentCost: 500,
      case_ref: "T20545454",
      country: "Poland",
      official_no: "9323233",
      title: "REET AND DEVICE",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: "",
      summary: "",
      cartData: ["New App GBP 980"],
    },
    {
      budget: 800,
      currentCost: 500,
      case_ref: "T20545454",
      country: "Poland",
      official_no: "9323233",
      title: "REET AND DEVICE",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: "",
      summary: "",
      cartData: ["New App GBP 980"],
    },
    {
      budget: 800,
      currentCost: 500,
      case_ref: "T20545454",
      country: "Poland",
      official_no: "9323233",
      title: "REET AND DEVICE",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "video-2.mp4",
      transcript: "",
      summary: "",
      cartData: ["New App GBP 980"],
    },
    {
      budget: 800,
      currentCost: 500,
      case_ref: "T20545454",
      country: "Poland",
      official_no: "9323233",
      title: "REET AND DEVICE",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: "",
      summary: "",
      cartData: ["New App GBP 980"],
    },
    {
      budget: 800,
      currentCost: 500,
      case_ref: "T20545454",
      country: "Poland",
      official_no: "9323233",
      title: "REET AND DEVICE",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "video-2.mp4",
      transcript: "",
      summary: "",
      cartData: ["New App GBP 980"],
    },
    {
      budget: 800,
      currentCost: 500,
      case_ref: "T20545454",
      country: "Poland",
      official_no: "9323233",
      title: "REET AND DEVICE",
      local_classes: "30",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Refused or Removed by IPO (dead status)",
      video: "sonar-logo.mp4",
      transcript: "",
      summary: "",
      cartData: ["New App GBP 980"],
    },
    {
      budget: 1200,
      currentCost: 1400,
      case_ref: "T255445",
      country: "United Kingdom",
      official_no: "9323233",
      title: "SHUI",
      local_classes: "42",
      instructor: "Haynes and Boone, LLP",
      registered_owner: "S&A RESTAURANT CORP.",
      status: "Abandoned",
      video: "video-2.mp4",
      transcript: "",
      summary: "",
      cartData: [],
    },
  ];
  