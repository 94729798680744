// CountriesAccordion.js
import React from 'react';
import { icon2 } from 'assests';

interface IProps {
  trademark: string | undefined
  country: { country: string; price: string }[];
}

export const TradeMarkSummary = ({ trademark, country }: IProps) => {
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        country.forEach((item) => {
          const priceWithoutDollar = parseFloat(item.price.replace('$', ''));
          totalPrice += priceWithoutDollar;
        });
        return `$${totalPrice.toFixed(2)}`;
      };
  return (
    <div className=" border-gray-200 p-4 rounded mb-4 flex-col">
      <div className="gap-x-4 ">
        <div className='border-b border-green w-full '>
        <h2 className="text-4xl font-semibold  w-full mb-2 cursor-pointer text-primary mobile:text-2xl">
          Summary
        </h2>
        </div>
          <div className='mt-6'>
            <h2 className="text-xl font-semibold mb-2 cursor-pointer text-primary  flex mobile:text-lg">
              Trade Mark
            </h2>
            <h2 className="text-4xl font-semibold mb-2 cursor-pointer text-primary mobile:text-2xl">
              {trademark}
            </h2>
          </div>
       
        {country.length>0 && <div className='mt-6 border-b-2 border-green'>
            <h2 className="text-xl mobile:text-lg font-semibold mb-2 cursor-pointer text-primary  flex">
             Countries
           </h2>
        {country.map((item) => (
            
          <div key={item.country} className='flex justify-between'>
            
            <h2 className="text-2xl font-semibold mb-2 mobile:text-xl cursor-pointer text-primary ">
              {item.country}
            </h2>
            <h2 className="text-2xl font-semibold mb-2 mobile:text-xl cursor-pointer text-primary text-center justify-center flex">
              {item.price}
            </h2>
          </div>  
        ))}
        {country.length>0 && 
        <div  className='flex justify-between border-t-2 border-green py-4'>
            <h2 className="text-2xl  mobile:text-xl font-semibold mb-2 cursor-pointer text-primary ">
            Total Price
            </h2>
            <h2 className="text-2xl mobile:text-xl font-semibold mb-2 cursor-pointer text-primary text-center justify-center flex">
            {calculateTotalPrice()}
            </h2>
          </div>}
         </div>}
        </div>
       
    
    </div>
  );
};
