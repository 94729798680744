
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
import { useMemo } from "react";

import ScrollableChartTableView from "components/ChartTable/scrollableTable";

type TProps={
    showTable:boolean
    setShowTable: (showTable: boolean) => void;
}
const UniqueOwners = ({showTable, setShowTable}:TProps) => {
    const { tableRecords,uniqueOwner } = useTrademarkAnalysisData();
    const close = () => {
        setShowTable(false);
    }
    return (
        <ScrollableChartTableView 
            title="Unique Owners"
            openModal={showTable}
            isClose={close}
            reportName={"result_unique_owner"}
        />
      
    )
}
export default UniqueOwners








// import React, { useState, useEffect, useMemo } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { api } from "config";
// import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
// import Loader from "components/Loader";
// import { useTable } from "react-table";
// import { formatDate } from "utils/index";
// import { UNIQUEOWNERSCOLUMNS } from "./../../../../constants";
// import Modal from "components/Modal";
// import close_icon from "../../../../assests/icons/close_icon.png";

// type TProps = {
//   showTable: boolean;
//   setShowTable: (showTable: boolean) => void;
// };

// const UniqueOwners = ({ showTable, setShowTable }: TProps) => {
//   const [hasMore, setHasMore] = useState(true);
//   const [page, setPage] = useState(1);
//   const [items, setItems] = useState<any>([]);

//   const { uniqueOwner } = useTrademarkAnalysisData();

//   const uniqueOwners = useMemo(
//     () =>
//       uniqueOwner?.map((item: any, index) => ({
//         col1: item["Unique Owner Name"],
//         col2: item["Count"],
//       })),
//     [uniqueOwner]
//   );

//   useEffect(() => {
//     if (!uniqueOwners || uniqueOwners.length === 0) {
//       fetchMoreData();
//     } else {
//       setItems(uniqueOwners);
//     }
//   }, [uniqueOwners]);

//   const fetchMoreData = () => {
//     api
//       .get(`owner_discrepancy?page=${page}`)
//       .then((res) => {
//         const data = res.data.data.unique_owners;
//         setItems((prevItems: any) => [...prevItems, ...data]);

//         if (data.length > 0) {
//           setPage((prevPage) => prevPage + 1);
//           setHasMore(true);
//         } else {
//           setHasMore(false);
//         }
//       })
//       .catch((err) => {
//         console.log("Error fetching data:", err);
//         setHasMore(false);
//       });
//   };

//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
//     useTable({ columns: UNIQUEOWNERSCOLUMNS, data: items });

//   const closeAndHide = () => {
//     setShowTable(false);
//   };

//   return (
//     <div className="overflow-hidden">
//       <div id="scrollableDiv" style={{ height: "700px", overflow: "auto" }}>
//         <Modal
//           isOpen={showTable}
//           onRequestClose={closeAndHide}
//           contentLabel="Chart Data Modal"
//           shouldCloseOnOverlayClick={true}
//         >
//           <div className="mx-auto rounded-md">
//             <div
//               onClick={closeAndHide}
//               className="-mt-6 float-right w-4 h-4 cursor-pointer"
//             >
//               <img src={close_icon} alt="close" />
//             </div>
//             <h1 className="text-3xl font-bold my-4 text-[#033d44] justify-center items-center flex">
//               Title Here
//             </h1>
//             <InfiniteScroll
//               dataLength={items.length}
//               next={fetchMoreData}
//               hasMore={hasMore}
//               loader={<Loader />}
//               scrollableTarget="scrollableDiv"
//               className="h-full"
//             >
//               <table
//                 className="w-full text-sm text-left border-main bg-green"
//                 {...getTableProps()}
//               >
//                 <thead className="text-xs text-white uppercase bg-green border border-main sticky">
//                   {headerGroups.map((headerGroup) => (
//                     <tr {...headerGroup.getHeaderGroupProps()}>
//                       {headerGroup.headers.map((column) => (
//                         <th
//                           {...column.getHeaderProps()}
//                           className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider"
//                         >
//                           {column.render("Header")}
//                         </th>
//                       ))}
//                     </tr>
//                   ))}
//                 </thead>
//                 <tbody {...getTableBodyProps()}>
//                   {rows.map((row) => {
//                     prepareRow(row);
//                     return (
//                       <tr
//                         {...row.getRowProps()}
//                         className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main"
//                       >
//                         {row.cells.map((cell) => (
//                           <td
//                             {...cell.getCellProps()}
//                             className="px-6 py-4 text-black border border-main"
//                           >
//                             {cell.column.id === "col3"
//                               ? formatDate(cell.value)
//                               : cell.render("Cell")}
//                           </td>
//                         ))}
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             </InfiniteScroll>
//           </div>
//         </Modal>
//       </div>
//     </div>
//   );
// };

// export default UniqueOwners;
// UniqueOwners.tsx
