import { AlertTriangle, CheckCircle } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const RecommendationCard = ({ recommendation, calculateImpact, generateProjections, selectedTimeframe }: any) => {
    const impact = calculateImpact(recommendation);

    return (
        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl mb-4">
            <div className="flex items-center gap-2">
                <AlertTriangle className="h-5 w-5 text-amber-500" />
                <span className='text-xl font-bold'>{recommendation.title}</span>
            </div>
            <p className='font-medium'>{recommendation.description}</p>
            <div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-5">
                    <div className="space-y-4">
                        <div className="p-4 bg-[#FEF2F2] rounded-lg">
                            <h3 className="font-semibold text-[#B91C1C]">Potential Value Loss</h3>
                            <p className="text-2xl font-bold text-[#991B1B]">
                                {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                                    .format(impact.potentialLoss)}
                            </p>
                        </div>

                        <div className="p-4 bg-[#F0FDF4] rounded-lg">
                            <h3 className="font-semibold text-[#15803D]">Protected Value</h3>
                            <p className="text-2xl font-bold text-[#166534]">
                                {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                                    .format(impact.projectedSavings)}
                            </p>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div className="p-4 bg-[#EFF6FF] rounded-lg">
                                <h3 className="font-semibold text-[#1D4ED8]">Cost</h3>
                                <p className="text-xl font-bold text-[#1E40AF]">
                                    {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                                        .format(impact.implementationCost)}
                                </p>
                            </div>
                            <div className="p-4 bg-[#FAF5FF] rounded-lg">
                                <h3 className="font-semibold text-[#7E22CE]">ROI</h3>
                                <p className="text-xl font-bold text-[#6B21A8]">
                                    {impact.roi.toFixed(1)}x
                                </p>
                            </div>
                        </div>
                    </div>

                    <LineChart
                        width={500}
                        height={300}
                        data={generateProjections(recommendation, selectedTimeframe === '1year' ? 12 : 24)}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Without Action" stroke="#ef4444" strokeWidth={2} />
                        <Line type="monotone" dataKey="With Protection" stroke="#22c55e" strokeWidth={2} />
                    </LineChart>
                </div>

                <div className="mt-6 p-4 bg-[#F9FAFB] rounded-lg">
                    <h3 className="font-semibold mb-2">Implementation Steps</h3>
                    <ol className="list-decimal list-inside space-y-2">
                        {recommendation.steps.map((step: any, index: any) => (
                            <li key={index} className="flex items-start gap-2">
                                <CheckCircle className="h-5 w-5 text-[#22C55E] mt-0.5" />
                                <span>{step}</span>
                            </li>
                        ))}
                    </ol>
                </div>
            </div>
        </div>
    );
};
export default RecommendationCard