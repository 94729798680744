import { DoughnutChart } from "components";
import { useMemo, useState,useRef } from "react";
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
import {
    CLASSESTABLE
} from "constants/index";
import ChartTableView from "components/ChartTable";
type TProps = {
    dataset: any;
};
const Top5Classes = ({ dataset }: TProps) => {
    // const { getClassesData } = useTrademarkAnalysisData()
    const [showClassesTable, setShowClassesTable] = useState(false)
    const [classData, setClassData] = useState([])
    const [title, setTitle] = useState("")
    const setClassesData = async (data: string) => {
        setTitle(data)
        setShowClassesTable(true)
    }
    const close = () => {
        setTitle('')
        setShowClassesTable(false);
    };
    const top5Classes = useMemo(() => {
        let data: any = {
            labels: [],
            datasets: [
                {
                    label: "Top 5 Classes",
                    data: [],
                    backgroundColor: ["#033d44", "#8A77FF", "#70FF00", "#FF4D00", "#DCDCDC"],
                    borderColor: ["#033d44", "#8A77FF", "#70FF00", "#FF4D00", "#DCDCDC"],
                    borderWidth: 1,
                },
            ],
        };
        dataset?.map((item: any) => {
            data["labels"].push(item.Class);
            data.datasets[0].data.push(item.Count);
        });
        return data;
    }, [dataset]);
    return (
        <div >
            <DoughnutChart
                chartData={top5Classes}
                title="Top 5 Classes"
                sendSliceData={setClassesData}
            />
            {title ? 
            <ChartTableView data={null} column={CLASSESTABLE}
                title={`Class: ${title}`}
                endpoint={'classes_info'}
                params={'class_name'}
                openModal={showClassesTable}
                isClose={close}
                paramsValue={title}
                fields={
                    ["App. date", "Country", "Exp. date", "Owner", "Reg. date", "Status", "Trade Mark", "Image Path"]
                }
                reportName={`result_class_distribution_${title.toLowerCase()}`}
            />
            : null}
        </div>
    )
}
export default Top5Classes
// // import React from 'react'

// // const index = () => {
// //   return (
// //     <div>index</div>
// //   )
// // }

// // export default index

