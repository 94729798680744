import { useCallback, useState } from "react";
import { api } from "config";
import { useMostFrequency } from "state/MostFrequency/hook";

export const useMostFrequencyAnalysis= () => {
    const {setMostFrequency,Records,showResult}=useMostFrequency()
    const Frequency = useCallback(async (
        name: string,
    ) => {
        try {
            const { data } = await api.get(`trademark_info?trademark_name=${name}`);
            const sanitizedData = data.replace(/NaN/g, "null"); // Replace NaN with null
            const jsonData = JSON.parse(sanitizedData);
            setMostFrequency(jsonData);
            
        }
        catch (e: any) {
            console.log(e)
         
        }
    }, []);
    return {
        Frequency,
        Records,
        showResult,
        setMostFrequency
    }
}