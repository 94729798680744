
import React, { useState, useEffect } from "react";

const UploadForm = ({ onSuccess, uploadedFile, previewImage }: any) => {
  const [processing, setProcessing] = useState(false);
  const [currentPreviewImage, setCurrentPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    setCurrentPreviewImage(previewImage);
  }, [previewImage]);

  const runScript = async (file: File) => {
    setProcessing(true);
    try {
      // Simulating upload process
      setTimeout(() => {
        onSuccess(file); // Call onSuccess with the uploaded file
        setProcessing(false);
      }, 2000); // Simulating a 2-second upload process
    } catch (error) {
      // Handle error
      console.error("Error processing file:", error);
      setProcessing(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      runScript(file); // Run the upload script
    }
  };


  return (
    <div className="w-full mt-10 flex items-center justify-center ">
      <div className="flex items-center justify-center w-full">
        <div className="relative w-full -ml-4 max-w-[590px] px-8 h-44 border-2 border-[#033d44] border-dashed rounded-lg cursor-pointer bg-white hover:bg-opacity-50">
          <input
            onChange={handleFileChange}
            id="dropzone-file"
            type="file"
            className="cursor-pointer relative block opacity-0 w-full h-full p-20 z-50"
            accept=".jpeg, .png, .jpg"
          />
          {processing && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 z-50">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-main"></div>
            </div>
          )}
          <div className="text-center p-16 absolute top-0 bottom-0 right-0 left-0 m-auto">
            {currentPreviewImage ? (
              <img
                src={currentPreviewImage}
                alt="Preview"
                className="max-h-28 mb-3 -mt-4 mx-auto"
              />
            ) : (
              <>
              <svg
                className="w-10 h-10 mb-3 text-primary"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
            <p className={`mb-2 text-sm text-primary ${currentPreviewImage ?"m-[-100px]":"-m-10"}`}>
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-primary">(PNG OR JPEG)</p>
            </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadForm;
