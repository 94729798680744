import Modal from "react-modal";
import { useRef, useState, useEffect, useCallback } from "react";
import close_icon from "../../../../assests/icons/close_icon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faVolumeUp, faClose, faVolumeOff } from '@fortawesome/free-solid-svg-icons'

type TProps = {
    setShowVideoStory: any;
    title: any;
    videoLink: any
}
const VideoStory = ({ setShowVideoStory, title, videoLink }: TProps) => {
    const handleClose = () => {
        setShowVideoStory(false)
    }
    const storyVideo = useRef<any>(null)
    const [videoActive, setVideoActive] = useState(false)
    const [videoMute, setVideoMute] = useState(false)
    const [progress, setProgress] = useState(0);
    const setVideoActiveStatus = (status: any) => {
        setVideoActive(status)
        if (status) storyVideo.current.play()
        else storyVideo.current.pause()
    }
    const setVideoMuteStatus = (status: any) => {
        setVideoMute(status)
        if (status) storyVideo.current.muted = true
        else storyVideo.current.muted = false
    }
    useEffect(() => {
        console.log(storyVideo)
        storyVideo.current?.addEventListener("timeupdate", (e: any) => {
            setProgress((e.target.currentTime / e.target.duration) * 100)
        });
    }, [storyVideo.current])
    const elRef = useCallback((node:any) => {
        if (node !== null) {
            console.log("ref", node); // node = elRef.current
        }
    }, []);
    return (
        <div>
            <Modal isOpen={true}>
                <div className="mx-auto rounded-md">
                    <div
                        onClick={handleClose}
                        className="-mt-[0.8rem] float-right w-4 h-4 cursor-pointer"
                    >
                        <img src={close_icon} alt="close" />
                    </div>
                </div>
                <div className='flex items-center justify-end mt-5'>
                    <div>
                        <button className="bg-green text-white cursor-pointer rounded-md hover:bg-green-700 text-md py-2 px-3" onClick={() => setVideoActiveStatus(!videoActive)}><FontAwesomeIcon icon={videoActive ? faPause : faPlay} /></button>
                        <button className="bg-green text-white cursor-pointer rounded-md hover:bg-green-700 text-md ml-2 py-2 px-3" onClick={() => setVideoMuteStatus(!videoMute)}><FontAwesomeIcon icon={videoMute ? faVolumeOff : faVolumeUp} /></button>
                    </div>
                </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mt-5">
                        <div className="bg-green h-2.5 rounded-full" style={{width: progress+"%"}}></div>
                    </div>
                <h4 className='text-center mt-5 text-2xl text-black font-bold'>{title}</h4>
                <div className="text-center">
                    <video ref={storyVideo} id='video-section' className="video-story mt-5 mx-auto" width={400} height={400} playsInline onEnded={()=>{setVideoActiveStatus(false); setProgress(0)}}>
                        <source src={`${process.env.PUBLIC_URL}/assets/media/${videoLink}`}  type="video/mp4" />
                        <source src={`${process.env.PUBLIC_URL}/assets/media/${videoLink}`}  type="video/ogg" />
                    </video>
                </div>
            </Modal>
        </div>
    )
}

export default VideoStory