import { useState } from "react"
import { useDomainAnalysis } from "hooks/useDomainAnalysis";
const DomainVariations = () => {
    const { getDomainVariations, downloadReport } = useDomainAnalysis()
    const [variations, setVariations] = useState<any[]>([])
    const [email, setEmail] = useState<string>('')
    const [variationsData, setVariationsData] = useState("")
    const [downloadReportStatus, setDownloadReportStatus] = useState(false);
    const [domainsCount, setDomainsCount] = useState(0)
    const [emailSendingConsent, setEmailSendingConsent] = useState(false)
    const [fileName, setFileName] = useState("")
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const { domains, count, file_path } = await getDomainVariations(variationsData?.split("\n"), email,emailSendingConsent)
        setFileName(file_path)
        setVariations(domains)
        setDomainsCount(count)
    }
    const downloadReportData = async () => {
        setDownloadReportStatus(true);
        try {
            const tempFileName = fileName.split("/")
            const analysisFileName = tempFileName[tempFileName.length - 1]
            await downloadReport({ filename: analysisFileName.split(".xlsx")[0] }, 'Domain Variations');
        } catch (error) {
        } finally {
            setDownloadReportStatus(false);
        }
    };
    return (
        <div className="container mx-auto mt-10 ">
            <form onSubmit={handleFormSubmit}>
                <h3 className="text-2xl text-black font-bold mb-5">Domain Variations</h3>
                {domainsCount > 0 ? <h2 className="font-bold text-2xl text-black mb-3 text-center">Total no. of Variations: {domainsCount}</h2> : null}
                {emailSendingConsent ? 
                <input type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl mb-3 focus:outline-none" placeholder="Enter Email to get Detailed Report on Domain Analysis" required />
                : null}
                <div className="block sm:flex justify-between">
                    <textarea
                        id="input"
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Domain"
                        required
                        value={variationsData}
                        onChange={(e) => setVariationsData(e.target.value)}
                    ></textarea>
                    <div
                        id="domainVariations"
                        className="h-[268px] overflow-y-auto mt-3 sm:mt-0 focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                    >
                        {variations.map((variation, index) => (
                            <p key={index}>{variation}</p>
                        ))}
                    </div>
                </div>
                <div className="my-5 items-center flex">
                    <input
                        className='trademark-checkbox'
                        type='checkbox'
                        id="email-consent"
                        onChange={(e) => setEmailSendingConsent(e.target.checked)}
                    />
                    <label htmlFor="email-consent" className="ms-2 font-medium">Do you want a detailed report on Domain Analysis too?</label>
                </div>
                <div className="flex mt-8 justify-center">
                    <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Submit</button>
                    <button type="button"
                        onClick={() => downloadReportData()}
                        disabled={!variations?.length || downloadReportStatus} className="hover:bg-opacity-50 text-[#dcdcdc] bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 ml-3 disabled:opacity-50 disabled:pointer-events-none"> {downloadReportStatus ? "Downloading..." : "Download All Variations"}</button>
                </div>
            </form>
        </div>
    )
}
export default DomainVariations