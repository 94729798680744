import { combineReducers } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import trademarkAnalysis from "./trademarkAnalysis";
import MostFrequency from "./MostFrequency";
import CountryAnalysis from "./CountryRepresentative";
const rootReducer = combineReducers({
  trademarkAnalysis: trademarkAnalysis,
  mostFrequency: MostFrequency,
  CountryAnalysis: CountryAnalysis
})
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
  
  export default store;
  
  export type RootState = ReturnType<typeof store.getState>;
  export const useAppDispatch: () => AppDispatch = useDispatch;
  export type AppDispatch = typeof store.dispatch;
  