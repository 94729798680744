import Modal from "react-modal";
import { useState, useRef } from "react";
import RecordRTC, { invokeSaveAsDialog, RecordRTCPromisesHandler } from 'recordrtc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import close_icon from "../../../../assests/icons/close_icon.png";
import Button from "components/Button";
type TProps = {
    setShowVideoModal: any;
    title: any;
    caseRef: any;
    officialNo: any;
    summary: any;
}
const VideoReply = ({ setShowVideoModal, title, caseRef, officialNo, summary }: TProps) => {
    const [additionalComments, setAdditionalComments] = useState<any>("")
    const [fileData, sendFileData] = useState<any>(null)
    const handleClose = async () => {
        if (recorder) {
            await recorder.stopRecording(() => {
                closeAllMedia(stream)
                setShowVideoModal(false)
            });
        }
        else setShowVideoModal(false)
    }
    const inputRef: any = useRef();
    const videoRef: any = useRef();
    const liveVideoRef: any = useRef();
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const [stream, setStream] = useState<any>(null);
    const [blob, setBlob] = useState<any>(null);
    const [userFile, setUserFile] = useState<any>(null)
    const [playVideo, setPlayVideo] = useState(false)
    const [startRecording, setStartRecording] = useState(false)
    const [recorder, setRecorder] = useState<any>(null)
    const handleRecording = async () => {
        setStartRecording(true)
        const mediaStream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        })
        setStream(mediaStream);
        setBlob(null)
        setPlayVideo(false)
        setUserFile(null)
        const recorder = new RecordRTCPromisesHandler(mediaStream, { type: 'video' });
        liveVideoRef.current.srcObject = mediaStream;
        setRecorder(recorder)
        await recorder.startRecording();
    }
    const closeAllMedia = (stream: any) => {
        stream.getTracks().forEach(function (track: any) {
            if (track.readyState == 'live') {
                track.stop();
            }
        });
    }
    const handleStop = async () => {
        if (recorder) {
            await recorder.stopRecording()
            liveVideoRef.current.srcObject = null
            let blob = await recorder.getBlob()
            const file = new File([blob], 'video.webm', {
                type: 'video/webm'
            });
            setBlob(blob);
            // invokeSaveAsDialog(file);
            closeAllMedia(stream)
            sendFileData(file)
            recorder.destroy();
            setUserFile(null)
            setStartRecording(false)
            setStream(null)
            setRecorder(null)
        }
    };
    const handleUserVideo = (e: any) => {
        const file = e.target.files[0]
        sendFileData(file)
        const reader = new FileReader()
        reader.onload = (e: any) => {
            const src = e.target.result
            setBlob(null)
            setUserFile(src)
            setPlayVideo(false)
            setStream(null)
            if (recorder) {
                recorder.destroy()
                setRecorder(null)
            }
        }
        reader.readAsDataURL(file)
    }

    const playUserVideo = () => {
        setPlayVideo(true)
        videoRef.current?.play()
    }
    const stopUserVideo = () => {
        setPlayVideo(false)
        videoRef.current?.pause()
    }
    return (
        <Modal isOpen={true}>
            <div className="mx-auto rounded-md">
                <div
                    onClick={handleClose}
                    className="-mt-[0.8rem] float-right w-4 h-4 cursor-pointer"
                >
                    <img src={close_icon} alt="close" />
                </div>
            </div>
            <div>
                <div className={`flex items-center mt-3 ${!startRecording ? 'justify-between' : 'justify-center'}`}>
                    {!startRecording ? <button className='bg-green text-white cursor-pointer rounded-md hover:bg-green-700 text-md font-bold py-2 px-2 w-fit mt-1 hover:px-4 hover:py-3 hover:transition-all' onClick={handleUpload} >Upload from your Device</button> : null}
                    <input ref={inputRef} onChange={(e) => handleUserVideo(e)} className="hidden" type="file" accept="video/mp4,video/x-m4v,video/*" />
                    {!startRecording ? <button className='bg-green text-white cursor-pointer rounded-md hover:bg-green-700 text-md font-bold py-2 px-2 w-fit mt-1 hover:px-4 hover:py-3 hover:transition-all' onClick={() => handleRecording()}>Record</button>
                        : <button className='bg-green text-white cursor-pointer rounded-md hover:bg-green-700 text-md font-bold py-2 px-2 w-fit mt-1 hover:px-4 hover:py-3 hover:transition-all' onClick={() => handleStop()}>Stop Recording</button>}
                </div>
                {blob || userFile ?
                    <div className='position-relative'>
                        <video ref={videoRef} className='mt-3 video-user' onEnded={() => setPlayVideo(false)}>
                            <source src={userFile ? userFile : window.URL.createObjectURL(blob)} type="video/mp4" />
                            <source src={userFile ? userFile : window.URL.createObjectURL(blob)} type="video/ogg" />
                            Your browser does not support HTML video.
                        </video>
                        {!playVideo ?
                            <button className='bg-green text-white cursor-pointer rounded-md hover:bg-green-700 text-md font-bold py-2 px-2 w-full mt-1 hover:px-4 hover:py-3 hover:transition-all' onClick={() => playUserVideo()}><FontAwesomeIcon icon={faPlay} /></button>
                            : <button className='bg-green text-white cursor-pointer rounded-md hover:bg-green-700 text-md font-bold py-2 px-2 w-full mt-1 hover:px-4 hover:py-3 hover:transition-all' onClick={() => stopUserVideo()}><FontAwesomeIcon icon={faPause} /></button>}
                    </div>
                    : <div className='video-box mt-3 testing-123'>
                        <video ref={liveVideoRef} className="video-user" autoPlay playsInline></video>
                    </div>
                }
                <div className="mt-3">
                <label className='font-bold'>Additional Comments</label><br/>
                <textarea className="mt-3 rounded-lg  px-2 border focus-visible:outline-none border-green w-full" value={additionalComments} onChange={(e) => setAdditionalComments(e.target.value)} rows={5}></textarea>
                <div className="text-center w-fit">
                    {additionalComments ? 
                <Button title="Submit" onClick={handleClose}/>
                    : null}
                </div>
                </div>
            </div>
            {/* {blob || userFile || additionalComments || fileData ?
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={sendReviewData}>
                        Submit Review
                    </Button>
                </Modal.Footer>
                : null} */}
        </Modal>
    )
}
export default VideoReply