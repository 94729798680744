

import { useState } from "react";
type TProps = {
  processFile: (file: File) => Promise<void>;
};

const Form = ({ processFile}:TProps) => {

  const runScript = async (file: File) => {
    try {
      await processFile(file);
    } catch (error) {
      // console.error('Error processing file:', error);
    }
  };
  return (
    <div className="max-w-2xl mx-auto mt-10">
      <div className="flex items-center justify-center w-full rounded">
        <div className={`relative w-full h-64 border-2 border-[#033d44] rounded-lg cursor-pointer hover:bg-opacity-50 bg-green`}>
          <input
            onChange={(e) => {
              if (e.target.files) {
                runScript(e.target.files[0]!);
              }
            }}
            id="dropzone-file"
            type="file"
            className="cursor-pointer relative block opacity-0 w-full h-full p-20 z-50"
            accept=".xlsx, .xls, .csv"
          />
           
          {/* {processing && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-100"></div>
            </div>
          )} */}
          <div className="text-center p-16 absolute top-0 bottom-0 right-0 left-0 m-auto">
            <svg
              className="w-10 h-10 mb-3 text-[#dcdcdc]"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p className="mb-2 text-sm text-[#dcdcdc]">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-[#dcdcdc]">XLSX or CSV</p>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Form;
