import WrapperLayout from 'components/Layout';
import Main from './components/Main';
const SpecificationManagement = () => {
    return (
        <>
            <WrapperLayout>
                <Main/>
            </WrapperLayout>
        </>
    )
}
export default SpecificationManagement