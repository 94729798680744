import { api } from "config";
import { useCallback } from "react";
import { toast } from "react-toastify";

export const useSpecificationManagement = () => {
    const generateSpecificationData = useCallback(async (payload: any) => {
        try {
            const { data } = await api.post("post_data", payload);
            return data;
        }
        catch (e: any) {
            toast.error(e)
        }
    }, []);
    const sendDraftData = useCallback(async (email_body: any) => {
        try {
            await api.post("send_email", email_body);
            toast.success("Email Sent Successfully")
        }
        catch (e: any) {
            toast.error(e)
        }
    }, []);
    return {
        generateSpecificationData,
        sendDraftData
    }
}