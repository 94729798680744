import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import { TCOUNTRYDISTRIBUTION, TDATABASEMANAGEMENTSLICE, TMISSINGPRODUCTTERM, TMISSINGPRODUCTTERMINCOUNTRY, TTRADEMARKRECORD, UNIQUEOWNERRECORD,TMISSINGMARK } from "./type"
import { setTrademarkAnalysisAction,setMissingProductTermAction,setMissingProductTermInCountriesAction,setUniqueOwnerAction, setCountryDistributionAction ,setMissingMarkAction,setShowResultsState, setProcessingState, setUserFileName} from ".";
export const useTrademarkAnalysis = () => {
    const { tableRecords, showResults,missingProductTerm ,missingProductTermInCountry,uniqueOwner,countryDistribution,missingMark, processing, filename} = useSelector<RootState, TDATABASEMANAGEMENTSLICE>(
        (state) => state.trademarkAnalysis
    );
    const dispatch = useAppDispatch();
    const setTrademarkAnalysis = useCallback(
        (payload: any) => {
            dispatch(setTrademarkAnalysisAction(payload));
        },
        [dispatch]
    );
    const setShowResults = useCallback(
        (payload: boolean) => {
            dispatch(setShowResultsState(payload));
        },
        [dispatch]
    );
    const setProcessing = useCallback(
        (payload: boolean) => {
            dispatch(setProcessingState(payload));
        },
        [dispatch]
    );
    const setMissingProductTerm = useCallback(
        (payload: TMISSINGPRODUCTTERM) => {
            dispatch(setMissingProductTermAction(payload));
        },
        [dispatch]
    );
    const setMissingMark = useCallback(
        (payload: any) => {
            dispatch(setMissingMarkAction(payload));
        },
        [dispatch]
    );
    const setCountryDistribution = useCallback(
        (payload: any) => {
            dispatch(setCountryDistributionAction(payload));
        },
        [dispatch]
    );
    const setMissingProductTermInCountries = useCallback(
        (payload: any) => {
            dispatch(setMissingProductTermInCountriesAction(payload));
        },
        [dispatch]
    );
    const setUniqueOwner = useCallback(
        (payload: any) => {
            dispatch(setUniqueOwnerAction(payload));
        },
        [dispatch]
    );
    const setFilename = useCallback(
        (payload: any) => {
            dispatch(setUserFileName(payload));
        },
        [dispatch]
    );
    return {
        tableRecords,
        showResults,
        setShowResults,
        setTrademarkAnalysis,
        setMissingProductTerm,
        missingProductTerm,
        missingProductTermInCountry,
        uniqueOwner,
        setMissingProductTermInCountries,
        setUniqueOwner,
        setCountryDistribution,
        countryDistribution,
        setMissingMark,
        missingMark,
        setProcessing,
        processing,
        filename,
        setFilename
    };
}