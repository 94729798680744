import { useCallback, useState } from "react";
import { api } from "config";
import {CountryRepresentative} from "state/CountryRepresentative/hooks";

export const useCountryRepresentative= () => {
    const {setCountry,CountryRecords,showResult}=CountryRepresentative()
    const countryRepresentative = useCallback(async (
        name: string,
        pageSize:number
    ) => {
        try {
            const { data } = await api.get(`country_representative_info?country_name=${name}&page=${pageSize}`);
            setCountry(data);
            
        }
        catch (e: any) {
            console.log(e)
         
        }
    }, []);
    return {
        countryRepresentative,
        CountryRecords,
        showResult,
        setCountry
    }
}