import { useState } from "react"
import { useSpecificationManagement } from "hooks/useSpecificationManagement"
import Loader from "page/DashBoard/Loader"
const Main = () => {
    const { generateSpecificationData, sendDraftData } = useSpecificationManagement()
    const [file, setFile] = useState<any>(null)
    const [trademarksList, setTrademarksList] = useState<any>([])
    const [results, setResults] = useState<any>(null)
    const [classData, setClassData] = useState("")
    const [country, setCountry] = useState("")
    const [processing, setProcessing] = useState(false)
    const [disableTrademarks, setDisableTrademarks] = useState("")
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setProcessing(true)
        setResults(null)
        const formData = new FormData()
        formData.append("file", file)
        formData.append("class", classData)
        formData.append("country", country)
        try {
            const data = await generateSpecificationData(formData)
            setResults(data)
            setTimeout(()=>{
                if (localStorage.getItem("disable-items")) {
                    const disableTrademarksData: any = JSON.parse(localStorage.getItem("disable-items") || "");
                    setDisableTrademarks(disableTrademarksData?.length === 1 ? disableTrademarksData[0] + ";" : disableTrademarksData.join(";"))
                    setDisableItems(JSON.parse(localStorage.getItem("disable-items") || ""))
                }
            },2000)
           
        }
        catch (e) {
        }
        finally {
            setProcessing(false)
        }
    }
    const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            await sendDraftData({ email_body: trademarksList.join("; ") })
        }
        catch (e) {

        }
    }
    const setDisableTrademarksData = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const items = disableTrademarks.split(";")
        localStorage.setItem("disable-items", JSON.stringify(items))
        setDisableItems(items)
    }
    const setDisableItems = (items: any) => {
        if (items) {
            document.querySelectorAll(".trademark-item").forEach((item: any) => {
                items.map((trademarkItem: any) => {
                    if (trademarkItem && trademarkItem.toString().toLowerCase().replace(/\s/g, "").includes(item.getAttribute('data-value').toString().toLowerCase().replace(/\s/g, ""))) {
                        item.setAttribute("data-disabled", true)
                        item.style.background = 'red'
                    }
                })
            })
        }
    }
    const setTrademarkItem = (event:any, item: any) => {
        if (trademarksList.indexOf(item) === -1 && !event.target.getAttribute("data-disabled")) {
            trademarksList.push(item)
            setTrademarksList([...trademarksList])
        }
    }
    return (
        <div className="container mx-auto mt-10 pb-5">
            <h3 className="text-2xl text-black font-bold mb-5">Trademark Builder</h3>
            <form onSubmit={handleFormSubmit}>
                <input type="file" className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none" required
                    onChange={(e) => setFile(e.target.files![0])}
                    accept=".xlsx, .xls, .csv"
                />
                <input type="number"
                    value={classData}
                    onChange={(e) => setClassData(e.target.value)}
                    className="w-full border-4 border-[#033d44] p-2.5 mt-5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none" placeholder="Enter Class" required />
                <select className="mt-5 focus:outline-none w-full rounded-2xl p-2.5 mb-3 border-4 border-main text-primary"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                >
                    <option value="">Select Country</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="EU Countries">European Countries</option>
                    <option value="3">Three</option>
                </select>
                <div className="mt-5 text-center flex flex-wrap">
                    <button disabled={processing} className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 disabled:pointer-events-none">Submit</button>
                    <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 disabled:pointer-events-none ml-3" type="button">Enhance with AI</button>
                </div>
            </form>
            {processing && <Loader />}
            {results ?
                <>
                    {results['in_country'].length > 0 ? <>
                        <h3 className="text-2xl text-black font-bold mb-5 mt-10">In the Country</h3>
                        <div className="overflow-x-auto">
                            <table className="w-full text-sm text-left border-main bg-green">
                                <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                                    <tr>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Product List</th>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Count</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {results['in_country']?.map((item: any, index: any) => (
                                        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main" key={index}>
                                            <td className="px-6 py-4 text-black border border-main cursor-pointer trademark-item" data-value={item['Product List']} onClick={(e) => setTrademarkItem(e,item['Product List'])}>{item['Product List']}</td>
                                            <td className="px-6 py-4 text-black border border-main">{item['Count']}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                        : null
                    }
                    {results['out_country'].length > 0 ? <>
                        <h3 className="text-2xl text-black font-bold mt-10 mb-5">Except the Country</h3>
                        <div className="overflow-x-auto mb-5">
                            <table className="w-full text-sm text-left border-main bg-green">
                                <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                                    <tr>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Product List</th>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Count</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {results['out_country']?.map((item: any, index: any) => (
                                        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main" key={index}>
                                            <td className="px-6 py-4 text-black border border-main cursor-pointer trademark-item" data-value={item['Product List']} onClick={(e) => setTrademarkItem(e, item['Product List'])}>{item['Product List']}</td>
                                            <td className="px-6 py-4 text-black border border-main">{item['Count']}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                        : null
                    }
                    <form onSubmit={sendEmail}>
                        <h3 className="text-2xl text-black font-bold mb-5 mt-10">Draft</h3>
                        <textarea
                            rows={8}
                            className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                            required
                            readOnly
                            value={trademarksList.join("; ")}
                        ></textarea>
                        <div className="mt-5 text-center">
                            <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Send Email</button>
                        </div>
                    </form>
                    <form onSubmit={setDisableTrademarksData} className="mt-5">
                        <h3 className="text-2xl text-black font-bold mb-5 mt-10">Disable Trademarks</h3>
                        <textarea
                            rows={8}
                            className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                            required
                            placeholder="Enter Trademarks (separated by semi-colon)"
                            onChange={(e) => setDisableTrademarks(e.target.value)}
                            value={disableTrademarks}
                        ></textarea>
                        <div className="mt-5 text-center">
                            <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Submit</button>
                        </div>
                    </form>
                </>
                : null}
        </div>
    )
}
export default Main