import React, { useState } from "react";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: any
  title?: string;
}

const Input: React.FC<IProps> = ({
  placeholder,
  onChange,
  error,
  type,
  style,
  className,
  value,
  name,
  readOnly,
  title,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(value !== '');
  };

  return (
    <div className="relative z-0 w-full mb-3">
      <label className="text-green text-[16px] mb-10 bg-white font-bold pl-2">{title}</label>
      <input
        autoComplete="off"
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={name}
        readOnly={readOnly}
        id={name}
        type={type}
        placeholder={placeholder}
        value={value}
        className="mt-2 w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none"
        style={style}
      />
      {error && (
        <p className="text-sm mt-2 font-helvetica text-[#FF0000]">{error}</p>
      )}
      {/* <label
        htmlFor={name}
        className={`absolute duration-300 top-3 left-0 ml-2 ${
          isFocused || value ? 'text-green text-[16px] bg-white ml-4' : 'text-gray-500 text-base'
        }`}
        style={{
          transform: isFocused || value ? 'translateY(-100%)' : 'translateY(0)',
        }}
      >
        {title}
      </label> */}
    </div>
  );
};

export default Input;
