import { useState } from "react"
import { Search, AlertTriangle, AlertCircle, CheckCircle, FileText, Users, Database, BookOpen, Share2 } from 'lucide-react';
import AuditModal from "../AuditModal";
import ApprovalModal from "../ApprovalModal";
const Main = () => {
    const [showAuditModal, setShowAuditModal] = useState(false);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [activeTab, setActiveTab] = useState("conflicts")
    const [searchInput] = useState({
        trademark: 'FRASER',
        owner: 'Fraser Group Limited',
        goodsServices: 'Retail services, Fashion retail, Department store services',
        domain: 'fraser.com'
    });

    const conflicts = [
        {
            type: 'CRITICAL',
            priority: 1,
            category: 'Litigation',
            reference: 'LIT2938GB',
            details: {
                trademark: 'FRASERS',
                owner: 'Frasers Group PLC',
                status: 'Active Litigation',
                similarity: '95% Match',
                action: 'PARTNER APPROVAL REQUIRED',
                goods: 'Retail services, Department stores',
                filingDate: '2020-05-15',
                territory: 'United Kingdom',
                notes: 'Active litigation case regarding mark use'
            }
        },
        {
            type: 'HIGH',
            priority: 2,
            category: 'Opposition',
            reference: 'OT7723GB',
            details: {
                trademark: 'FRASER & SONS',
                owner: 'Fraser & Sons Limited',
                status: 'Opposition Filed',
                similarity: '85% Match',
                action: 'Review Required',
                goods: 'Retail of clothing, footwear',
                filingDate: '2023-08-20',
                territory: 'United Kingdom'
            }
        },
        {
            type: 'MEDIUM',
            priority: 3,
            category: 'Domain',
            reference: 'D98765',
            details: {
                domain: 'fraser.com',
                owner: 'Frasers Group PLC',
                status: 'Active',
                similarity: '100% Match',
                action: 'High Risk - Review Required',
                useType: 'Active retail website'
            }
        }
    ];

    const pipelineConflicts = [
        {
            type: 'PIPELINE',
            priority: 4,
            category: 'Active Matter',
            reference: 'PM2024-123',
            details: {
                client: 'Fraser Boutique Ltd',
                matter: 'Trademark Opposition - FRASER COLLECTION',
                status: 'In Progress',
                similarity: '82% Match',
                attorney: 'Michael Chen',
                openDate: '2024-01-15',
                lastAction: 'Opposition proceedings draft'
            }
        }
    ];
    return (
        <div className="container mx-auto mt-10 pb-5">
            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                <div className="flex items-center gap-2">
                    <Search className="w-5 h-5" />
                    <span className="font-bold">Trademark Conflict Check</span>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-5">
                    {Object.entries(searchInput).map(([key, value]) => (
                        <div key={key} className="flex flex-col space-y-1">
                            <label className="text-sm font-medium">
                                {key.charAt(0).toUpperCase() + key.slice(1)}
                            </label>
                            <input
                                className="p-2 border rounded bg-[#F9FAFB]"
                                value={value}
                                disabled
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex gap-4 my-5">
                <button
                    onClick={() => setShowAuditModal(true)}
                    className="flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg"
                >
                    <BookOpen className="w-4 h-4" />
                    Add to Audit Log
                </button>
                <button
                    onClick={() => setShowApprovalModal(true)}
                    className="flex items-center gap-2 px-4 py-2 bg-[#DC2626] text-white rounded-lg"
                >
                    <Share2 className="w-4 h-4" />
                    Request Partner Approval
                </button>
            </div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select your country</label>
                <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary"
                    onChange={(e) => setActiveTab(e.target.value)}
                >
                    <option value="conflicts">Conflicts</option>
                    <option value="pipeline">Pipeline</option>
                    <option value="summary">Summary</option>
                    <option value="domains">Domains</option>
                </select>
            </div>
            <ul className="hidden text-sm font-medium text-center text-[#6B7280] rounded-lg shadow sm:flex">
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('conflicts')}>
                    <a href="#" className={`flex items-center h-[100%] font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-s-lg focus:outline-none ${activeTab === 'conflicts' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}><AlertTriangle className="w-4 h-4 mr-2" />Conflicts</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('pipeline')}>
                    <a href="#" className={`flex items-center h-[100%] font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'pipeline' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}><Users className="w-4 h-4 mr-2" />Pipeline</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('summary')}>
                    <a href="#" className={`flex items-center h-[100%] font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'summary' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}><FileText className="w-4 h-4 mr-2" /> Summary</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('domains')}>
                    <a href="#" className={`flex items-center h-[100%] font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-e-lg focus:outline-none ${activeTab === 'domains' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}><Database className="w-4 h-4 mr-2" /> Domains</a>
                </li>
            </ul>
            <div className="mt-5">
                {activeTab === 'conflicts' ?
                    <div>
                        {conflicts.filter(c => c.type !== 'MEDIUM').map((conflict) => (
                            <div
                                key={conflict.reference}
                                className={`p-4 mb-4 text-sm border  rounded-lg  ${conflict.type === 'CRITICAL' ? 'bg-[#FEF2F2] border-[#FCA5A5] text-[#991B1B]' : 'bg-[#FEFCE8] border-[#FEF08A] text-[#854D0E]'}`}
                            >
                                <div className="flex items-center">
                                    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <span className="sr-only">Info</span>
                                    <div className="flex items-center gap-2 font-bold">
                                        {conflict.type === 'CRITICAL' ? (
                                            <AlertTriangle className="w-4 h-4" />
                                        ) : (
                                            <AlertCircle className="w-4 h-4" />
                                        )}
                                        {conflict.reference} - {conflict.category}
                                    </div>
                                </div>
                                <div>
                                    <div className="mt-2 space-y-2">
                                        {Object.entries(conflict.details).map(([key, value]) => (
                                            <div key={key} className="grid grid-cols-3 text-sm">
                                                <span className="font-medium">
                                                    {key.charAt(0).toUpperCase() + key.slice(1)}:
                                                </span>
                                                <span className="col-span-2">{value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    : activeTab === 'pipeline' ? <div>
                        {pipelineConflicts.map((conflict) => (
                            <div className="border rounded-lg p-4" key={conflict.reference}>
                                <div className="flex items-center gap-2">
                                    <Users className="w-4 h-4" />
                                    {conflict.reference} - {conflict.category}
                                </div>
                                <div>
                                    <div className="mt-2 space-y-2">
                                        {Object.entries(conflict.details).map(([key, value]) => (
                                            <div key={key} className="grid grid-cols-3 text-sm">
                                                <span className="font-medium">
                                                    {key.charAt(0).toUpperCase() + key.slice(1)}:
                                                </span>
                                                <span className="col-span-2">{value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> : activeTab === 'summary' ?
                        <div>
                            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                                <div className="pt-6">
                                    <div className="space-y-4">
                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
                                            <div className="p-4 bg-[#FEF2F2] rounded border border-[#FFCCCC]">
                                                <h3 className="font-bold text-[#B91C1C]">Critical Conflicts</h3>
                                                <p className="text-2xl font-bold">1</p>
                                                <p className="text-sm text-[#DC2626]">Active Litigation</p>
                                            </div>
                                            <div className="p-4 bg-[#FEFCE8] rounded border border-[#FEF08A]">
                                                <h3 className="font-bold text-[#A16207]">High Priority</h3>
                                                <p className="text-2xl font-bold">1</p>
                                                <p className="text-sm text-[#CA8A04]">Opposition Matter</p>
                                            </div>
                                            <div className="p-4 bg-[#EFF6FF] rounded border border-[#BFDBFE]">
                                                <h3 className="font-bold text-[#1D4ED8]">Domain Conflicts</h3>
                                                <p className="text-2xl font-bold">1</p>
                                                <p className="text-sm text-[#2563EB]">Exact Match</p>
                                            </div>
                                            <div className="p-4 bg-[#F0FDF4] rounded border border-[#BBF7D0]">
                                                <h3 className="font-bold text-[#15803D]">Pipeline Matches</h3>
                                                <p className="text-2xl font-bold">1</p>
                                                <p className="text-sm text-[#16A34A]">Related Matter</p>
                                            </div>
                                        </div>

                                        <div className="mt-6">
                                            <h3 className="font-bold mb-2">Recommended Actions:</h3>
                                            <ul className="space-y-2 text-sm">
                                                <li className="flex items-center gap-2">
                                                    <AlertTriangle className="w-4 h-4 text-[#DC2626]" />
                                                    Immediate partner review required - Active litigation
                                                </li>
                                                <li className="flex items-center gap-2">
                                                    <AlertCircle className="w-4 h-4 text-[#CA8A04]" />
                                                    Review opposition case and domain conflict
                                                </li>
                                                <li className="flex items-center gap-2">
                                                    <Users className="w-4 h-4 text-[#2563EB]" />
                                                    Check existing client matter conflict
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div>
                            {conflicts.filter(c => c.category === 'Domain').map((conflict) => (
                                <div className="border rounded-lg p-4" key={conflict.reference}>
                                    <div className="flex items-center gap-2 font-bold">
                                        <Database className="w-4 h-4" />
                                        {conflict.reference} - Domain Conflict
                                    </div>
                                    <div>
                                        <div className="mt-2 space-y-2">
                                            {Object.entries(conflict.details).map(([key, value]) => (
                                                <div key={key} className="grid grid-cols-3 text-sm">
                                                    <span className="font-medium">
                                                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                                                    </span>
                                                    <span className="col-span-2">{value}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                }
            </div>
            {showAuditModal && <AuditModal setShowAuditModal={setShowAuditModal} />}
            {showApprovalModal && <ApprovalModal setShowApprovalModal={setShowApprovalModal} />}
        </div>
    )
}
export default Main