import React from "react";
import Table from "components/Table";
import { useDownloadReport } from "hooks/useDownloadReport";

type IProps = {
  data: any[];
  columns: any[];
  heading: string;
  clickable?: boolean;
  showTable?: boolean;
  countryTable?: boolean;
  className?:string
  lastElementRef?: (node: HTMLElement | null) => void;
  name?: string
};

const CustomTable = ({
  data,
  columns,
  heading,
  clickable,
  countryTable,
  className,
  name,
lastElementRef
}: IProps) => {
  const { downloadSpecificTableReport } = useDownloadReport()
  return (
    <div className="mb-3">
    {heading ?
      <div className="flex align-items-center justify-between mb-3">
      <h2 className="font-bold text-2xl text-black">{heading}</h2>
      <button className="bg-green text-white cursor-pointer rounded-md hover:bg-green-700 font-bold p-2 text-sm"
              onClick={() => downloadSpecificTableReport(name!, name!)}
            >Download Table Data</button>
      </div>
    : null}
      <Table
        data={data || []}
        columns={columns}
        clickable={clickable}
        CountryTable={countryTable}
        className={className}
        lastElementRef={lastElementRef}
        
      />
    </div>
  );
};

export default CustomTable;
