import { useState } from 'react';
import {
    Upload, FileText, DollarSign, BarChart2, Plus,
    Calendar, Search, AlertCircle, CheckCircle, File
} from 'lucide-react';
const Main = () => {
    const [licenses, setLicenses] = useState([
        {
            id: 1,
            trademark: "SampleMark™",
            licensee: "Tech Corp Ltd",
            type: "Exclusive",
            territory: "Worldwide",
            term: "5 years",
            royaltyRate: "5%",
            minimumPayment: "$10,000/year",
            lastPayment: "2024-01-15",
            status: "Active",
            nextDue: "2024-04-15"
        }
    ]);
    const [activeTab, setActiveTab] = useState('dashboard');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [processingStatus, setProcessingStatus] = useState('');

    const handleFileUpload = async (file:any) => {
        setProcessingStatus('Analyzing document...');
        await new Promise(resolve => setTimeout(resolve, 1500));
        setProcessingStatus('Extracting license terms...');
        await new Promise(resolve => setTimeout(resolve, 1000));
        setProcessingStatus('Generating smart contract...');
        await new Promise(resolve => setTimeout(resolve, 1000));
        setShowUploadModal(false);
      };
    return (
        <>
        </>
    )
}
export default Main