import React from "react";

interface IProps {
  children: React.ReactNode;
}
const WrapperLayout: React.FC<IProps> = ({ children }) => {
  return (
    <section className="w-full sm:p-8 px-1 py-16 max-h-screen bg-[#FBFBFB]">
      {children}
    </section>
  );
};

export default WrapperLayout;
