import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { Chart as ChartJs, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { formatDate } from "utils";
import { api } from "config";
import ChartTableView from "components/ChartTable";
import { MISSINGPRODUCTTERMINCOUNTRYDATACOLUMN, MISSINGPRODUCTTERMINTRADEMARKDATACOLUMN } from "../../constants/index";
import Skeleton from "components/ChartSkeleton";
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

type TProps = {
  data: any;
  chartData1: any;
  index: number;
  onIndexUpdate: (index: number) => void;
  showlabel?: true;
  isProduct: number;
};

const BarChart = ({ data, chartData1, index, onIndexUpdate, showlabel, isProduct }: TProps) => {
  const [showTable, setShowTable] = useState(false);
  const [localIndex, setLocalIndex] = useState(index); // Store index local
  const [individualProductTermInCountry, setIndividualProductTermInCountry] = useState<any[]>([]); // Updated state name to ensure it's an array
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<any>();
  const { filename } = useTrademarkAnalysisData()
  const close = () => {
    setShowTable(false);
  };

  let chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: async function (event: any, elements: any, chart: any) {
      if (elements.length > 0) {
        const newIndex = elements[0]?.index;
        setLocalIndex(newIndex);
        onIndexUpdate(newIndex);
        setIsLoading(true);
        try {
          let response;
          if (isProduct === 1) {
            response = await api.get(`product_term_missing_details?product_name=${data[newIndex]?.product_term}&filename=${filename}`);
          } else if (isProduct === 2) {
            response = await api.get(`product_term_missing_country_details?product_name=${data[newIndex]?.product_term}&filename=${filename}`);
          }
          if (response) {
          
            setIndividualProductTermInCountry(response.data.data);
            if (isProduct === 2) {
              setTableData(productTerms(response.data.data));
            } else if (isProduct === 1) {
              setTableData(tradeMark(response.data.data));
            }
            setShowTable(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        setIsLoading(false);
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
        label: {
          display: true,
          color: "black",
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        label: {
          display: true,
          color: "black",
        },
      },
    },
  };

  const productTerms = (data: any[]) =>
    data?.map((item: any, index) => {
      return {
        col1: item["missing_count"],
        col2: item["product_term"],
        col3: item["product_term_text"],
        col4: item["country"],
      };
    });

  const tradeMark = (data: any[]) =>
    data?.map((item: any, index) => {
      return {
        col1: item["missing_count"],
        col2: item["product_term"],
        col3: item["product_term_text"],
        col4: item["trade_mark"],
        logoSection:item["image_path"].split(",")
      };
    });

  if (showlabel) {
    chartOptions = {
      ...chartOptions,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context: any) {
              const index = context.dataIndex;
              const firstTrademark = Object.values(data)[index];
              const arr: string[] = [];
              if (Array.isArray(firstTrademark)) {
                firstTrademark.forEach((item: any) => {
                  arr.push(`${item["Owner"]}: ${formatDate(item["Exp. date"])}`);
                });
              }
              return arr;
            },
          },
        },
      },
    };
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Always show the skeleton for at least 3 seconds
    const timer = setTimeout(() => {
      if (data) {
        setLoading(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [data]);

  return (
    <div>
      {loading ? (
        <div className="max-w-full">
          <Skeleton />
        </div>
      ) : (
        <div className="border-2 rounded-md mt-4 p-4 border-[#DCDCDC]"style={{width: '99%'}}>
          <Bar data={chartData1} options={chartOptions}  />
        </div>
      )}
      {isLoading == false && tableData && (
        <ChartTableView
          data={tableData}
          openModal={showTable}
          isClose={close}
          column={isProduct === 1 ? MISSINGPRODUCTTERMINTRADEMARKDATACOLUMN : MISSINGPRODUCTTERMINCOUNTRYDATACOLUMN}
          title={isProduct === 2 ? "Product Terms Missing Per Country" : "Product Terms Missing Per Trademark"}
          reportName={isProduct === 2 ? 'result_missing_country_product_term':'result_missing_product_terms'}
        />
      )}
    </div>
  );
};

export default BarChart;
