import { DollarSign, Brain, AlertTriangle, FileText, Target } from 'lucide-react';
import { useState } from 'react';

const formatGBP = (value: any) => {
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
};

const formatPercent = (value: any) => {
    return new Intl.NumberFormat('en-GB', {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    }).format(value / 100);
};
const MoneyCard = ({ title, value, trend, className }: any) => (
    <div className={`rounded-lg p-4 ${className}`}>
        <h3 className="text-sm font-semibold">{title}</h3>
        <div className="flex items-baseline gap-2 mt-1">
            <p className="text-2xl font-bold">{formatGBP(value)}</p>
            {trend && (
                <span className={`text-sm ${trend > 0 ? 'text-[#16A34A]' : 'text-[#DC2626]'}`}>
                    {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
                </span>
            )}
        </div>
    </div>
);
const Main = () => {
    const data = {
        currentValue: 5000000,
        licenseRevenue: 1000000,
        projectedLoss: 250000,
        confidenceScore: 85,
        missingTrademarks: [
            {
                name: 'TECHBRAND+',
                markets: ['USA', 'China', 'Brazil'],
                potentialValue: 150000,
                riskLevel: 'High'
            },
            {
                name: 'TECHBRAND PRO',
                markets: ['EU', 'Japan'],
                potentialValue: 100000,
                riskLevel: 'Medium'
            }
        ],
        marketCoverage: {
            'North America': 65,
            'Europe': 80,
            'Asia': 45,
            'South America': 30
        },
        riskFactors: {
            licenseRisk: {
                impact: 65,
                description: "Significant risk to UK licence revenue streams"
            }
        }
    };
    return (
        <div className="container mx-auto mt-10 pb-5">
            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                <div className="flex items-center gap-2">
                    <DollarSign className="h-6 w-6" />
                    <span className='font-bold text-xl'>License Revenue Risk Analysis</span>
                </div>
                <p className="text-[#4B5563] mb-5">
                    UK Market Analysis with {data.confidenceScore}% AI Confidence
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-4">
                        <MoneyCard
                            title="Current Annual License Revenue"
                            value={data.licenseRevenue}
                            className="bg-[#EFF6FF]"
                        />
                        <MoneyCard
                            title="Projected License Loss"
                            value={data.projectedLoss}
                            trend={-1 * (data.projectedLoss / data.licenseRevenue * 100)}
                            className="bg-[#FEF2F2]"
                        />
                        <MoneyCard
                            title="Protected Revenue"
                            value={data.licenseRevenue - data.projectedLoss}
                            className="bg-[#F0FDF4]"
                        />
                    </div>

                    <div className="bg-[#F9FAFB] p-4 rounded-lg">
                        <h3 className="font-semibold mb-4">Revenue Impact</h3>
                        <div className="space-y-4">
                            <div className="flex justify-between">
                                <span>Protected Revenue:</span>
                                <span className="font-semibold text-[#16A34A]">
                                    {formatGBP(data.licenseRevenue - data.projectedLoss)}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span>At Risk Revenue:</span>
                                <span className="font-semibold text-[#DC2626]">
                                    {formatGBP(data.projectedLoss)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Trade Mark Risk Card */}
            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                <span className='font-bold text-xl'>Missing Trade Mark Value Impact</span>
                <div className="space-y-4 mt-5">
                    {data.missingTrademarks.map((mark, index) => (
                        <div key={index} className="bg-[#EFF6FF] p-4">
                            <span className='font-bold'>Trade Mark: {mark.name}</span>
                            <div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <p className="font-semibold">Key Markets:</p>
                                        <p>{mark.markets.join(', ')}</p>
                                    </div>
                                    <div>
                                        <p className="font-semibold">Value at Risk:</p>
                                        <p>{formatGBP(mark.potentialValue)}</p>
                                    </div>
                                    <div>
                                        <p className="font-semibold">Risk Level:</p>
                                        <p className={`font-bold ${mark.riskLevel === 'High' ? 'text-[#DC2626]' :
                                            mark.riskLevel === 'Medium' ? 'text-yellow-600' :
                                                'text-[#16A34A]'
                                            }`}>{mark.riskLevel}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Financial Summary Card */}
            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                <span className='font-bold text-xl'>Financial Impact Summary</span>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-5">
                    <div className="bg-[#FEF2F2] p-4 rounded-lg">
                        <h3 className="font-bold mb-4">Risk Exposure</h3>
                        <ul className="space-y-2">
                            <li className="flex justify-between">
                                <span>License Revenue at Risk:</span>
                                <span className="font-semibold">{formatGBP(data.projectedLoss)}</span>
                            </li>
                            <li className="flex justify-between">
                                <span>Missing Trade Mark Value:</span>
                                <span className="font-semibold">
                                    {formatGBP(data.missingTrademarks.reduce((acc, mark) => acc + mark.potentialValue, 0))}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className="bg-[#F0FDF4] p-4 rounded-lg">
                        <h3 className="font-bold mb-4">Protection Opportunity</h3>
                        <ul className="space-y-2">
                            <li className="flex justify-between">
                                <span>Protection Cost:</span>
                                <span className="font-semibold">
                                    {formatGBP(data.missingTrademarks.reduce((acc, mark) => acc + (mark.markets.length * 1500), 0))}
                                </span>
                            </li>
                            <li className="flex justify-between">
                                <span>Value Protection:</span>
                                <span className="font-semibold">{formatGBP(data.projectedLoss * 0.8)}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Main