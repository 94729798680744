import { api } from "config";
import { useCallback } from "react";
import { toast } from "react-toastify";

export const useGapFill = () => {
    const generateWishlistData = useCallback(async (payload:any) => {
        try {
            const { data } = await api.post("wishlist", payload);
            return data;
        }
        catch (e: any) {
            toast.error(e)
        }
    }, []);
    return {
        generateWishlistData
    }
}