import { createSlice } from "@reduxjs/toolkit";
import { TCOUNTRYDISTRIBUTION, TDATABASEMANAGEMENTSLICE, TMISSINGMARK, TMISSINGPRODUCTTERM, TMISSINGPRODUCTTERMINCOUNTRY, TTRADEMARKRECORD, UNIQUEOWNERRECORD } from "./type";
const initialState: TDATABASEMANAGEMENTSLICE = {
    tableRecords: null,
    processing:false,
    missingProductTerm:null,
    showResults:false,
    missingProductTermInCountry:null,
    uniqueOwner:null,
    countryDistribution:null,
    missingMark:null,
    filename:null

}
const trademarkAnalysisSlice = createSlice({
    name: "trademarkAnalysis",
    initialState,
    reducers: {
        setTrademarkAnalysisAction: (state, action: { payload: any }) => {
            return {
                ...state,
                tableRecords: action.payload,
            };
        },
        setShowResultsState:(state, action:{payload:boolean})=>{
            return {
                ...state,
                showResults:action.payload
            };
        },
        setProcessingState:(state, action:{payload:boolean})=>{
            return {
                ...state,
                processing:action.payload
            };
        },
        setMissingProductTermAction: (state, action: { payload: TMISSINGPRODUCTTERM }) => {
           
            return {
                ...state,
                missingProductTerm: action.payload
            };
        },
        setMissingMarkAction: (state, action: { payload: any }) => {
           
            return {
                ...state,
                missingMark: action.payload
            };
        },
        setCountryDistributionAction: (state, action: { payload: any }) => {
          
            return {
                ...state,
                countryDistribution: action.payload
            };
        },
        setMissingProductTermInCountriesAction: (state, action: { payload: any }) => {
          
            return {
                ...state,
                missingProductTermInCountry: action.payload
            };
        },
        setUniqueOwnerAction: (state, action: { payload: any }) => {
          
            return {
                ...state,
                uniqueOwner: action.payload
            };
        },
        setUserFileName: (state, action: { payload: any }) => {
          
            return {
                ...state,
                filename: action.payload
            };
        },
        
    }
})
export const { setTrademarkAnalysisAction ,setMissingProductTermAction,setMissingProductTermInCountriesAction,setUniqueOwnerAction,setCountryDistributionAction,setMissingMarkAction, setShowResultsState, setProcessingState, setUserFileName} = trademarkAnalysisSlice.actions;

export default trademarkAnalysisSlice.reducer;