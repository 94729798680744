import React from 'react';

type Option = {
  value: string;
  label: string;
};

type IProps = {
  options: Option[];
  selectedOptions: Option[];
  setSelectedOptions: any
};

const CheckboxGroup = ({ options, selectedOptions, setSelectedOptions }: IProps) => {
  const selectAllOptions = (event:any) =>{
    if(event.target.checked){
      setSelectedOptions(options)
    }
    else{
      setSelectedOptions([])
    }
  }
  const handleCheckboxChange = (value: string) => {
    const isChecked = selectedOptions.some(option => option.value === value);
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions: any[]) =>
        prevSelectedOptions.filter(option => option.value !== value)
      );
    } else {
      setSelectedOptions((prevSelectedOptions: any) =>
        [...prevSelectedOptions, options.find(option => option.value === value)!]
      );
    }
  };

  return (
    <div className="w-full mt-3">
      <div className="flex items-center w-full sm:w-[50%] md:w-[50%] cursor-pointer mb-3">
          <input
            type="checkbox"
            id='select-all'
            value='selectAll'
            className="mr-2 checked:bg-green accent-[#033d44]"
            onChange={(e:any)=>selectAllOptions(e)}
            style={{ backgroundColor: 'green', width: '20px', height: '20px' }}
          />
          <label htmlFor='select-all'>Select All</label>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  {options.map(option => (
    <div
      key={option.value}
      className={`flex col-span-1 md:col-span-1 cursor-pointer p-4 border rounded-md ${
        selectedOptions.some(selectedOption => selectedOption.value === option.value) ? 'bg-lightGreen text-white' : 'bg-white shadow-2xl'
      }`}
      onClick={() => handleCheckboxChange(option.value)}
    >
      <label htmlFor={option.value} className="cursor-pointer">
        {option.label}
      </label>
    </div>
  ))}
</div>
    </div>
  );
};

export default CheckboxGroup;
