import React,{useRef} from "react";
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
import { useMemo, useState } from "react";
import BarChart from "components/BarChart";
import ChartTableView from "components/ChartTable";
import { CHARTDATACOLUMN } from "constants/index";
import { sortDataByQuarter } from "utils/index";
import { exportComponentAsPNG } from "react-component-export-image";

const GroupedByQuarter = () => {
  const [showTable, setShowTable] = useState(false);
  const [index, setIndex] = useState(0);
  const { tableRecords } = useTrademarkAnalysisData();
  function handleIndexUpdate(newIndex: number) {
    setIndex(newIndex);
    setShowTable(true);
  }
  const close = () => {
    setShowTable(false);
  };
  const groupedByQuarter = useMemo(() => {
    return tableRecords?.expiring_soon_trademarks?.reduce(
      (result: any, trademark) => {
        const expDate = new Date(trademark["Exp. date"]);
        const quarter = Math.floor((expDate.getMonth() + 3) / 3);
        const key = `Q${quarter}-${expDate.getFullYear()}`;
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(trademark);
        return result;
      },
      {}
    );
  }, [tableRecords?.expiring_soon_trademarks]);
  if (groupedByQuarter) {
    const expDates: any = tableRecords?.expiring_soon_trademarks.map(
      (trademark: any) => new Date(trademark["Exp. date"]).getFullYear()
    );
    // Get the minimum and maximum years
    const minYear = Math.min(...expDates);
    const maxYear = Math.max(...expDates);

    // Ensure all quarters within the specified range are present
    for (let year = minYear; year <= maxYear; year++) {
      for (let quarter = 1; quarter <= 4; quarter++) {
        const key = `Q${quarter}-${year}`;
        if (!groupedByQuarter[key]) {
          groupedByQuarter[key] = [];
        }
      }
    }
  }
  const chartData = {
    labels: Object.keys(sortDataByQuarter(groupedByQuarter)),
    datasets: [
      {
        label: "Expiring Soon Trademarks By Quarter",
        data: Object.keys(sortDataByQuarter(groupedByQuarter)).map(
          (key) => sortDataByQuarter(groupedByQuarter)[key].length
        ),
        borderWidth: 1,
        borderRadius: 20,
        backgroundColor: "#033d44",
        borderColor: "#033d44",
      },
    ],
  };
  return (
    <>
      <div className="rounded-md mt-4 p-4 border border-[#DCDCDC] bg-[#ECECEC]">
        <BarChart
          data={sortDataByQuarter(groupedByQuarter)}
          chartData1={chartData}
          index={index}
          onIndexUpdate={handleIndexUpdate}
          showlabel={true}
          isProduct={0}
        />
      </div>
      {showTable ? 
      <ChartTableView
        data={Object.values(sortDataByQuarter(groupedByQuarter))[index]}
        openModal={showTable}
        isClose={close}
        column={CHARTDATACOLUMN}
        title="Expiring TradeMarks"
      />
    : null}
    </>
  );
};
export default GroupedByQuarter;
