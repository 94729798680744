import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS } from "constants/index";
import Login from "page/Login";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.LOGIN} element={<Login/>} />
        <Route path="*" element={<Navigate to={PATHS.LOGIN} />} />
    </Routes>
  );
};
