import ReactDOM from "react-dom/client";
import jsPDF from "jspdf";
import { toPng } from "html-to-image";
export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    };
    return date.toLocaleDateString('en-US', options);
}

// export function sortDataByQuarter(data:any) {
//     const sortedQuarterKeys = Object.keys(data).sort((a, b) => {
//       const quarterA = parseInt(a.slice(1));
//       const quarterB = parseInt(b.slice(1));
//       return quarterA - quarterB;
//     });
  
//     const sortedData :any= {};
//     sortedQuarterKeys.forEach(quarterKey => {
//       sortedData[quarterKey] = data[quarterKey];
//     });
  
//     return sortedData;
//   }

export function sortDataByQuarter(data: any) {
  // Ensure data is an object and not null or undefined
  if (!data || typeof data !== 'object') {
    return {};
  }

  const sortedQuarterKeys = Object.keys(data).sort((a, b) => {
    const quarterA = parseInt(a.slice(1));
    const quarterB = parseInt(b.slice(1));
    return quarterA - quarterB;
  });

  const sortedData: any = {};
  sortedQuarterKeys.forEach(quarterKey => {
    sortedData[quarterKey] = data[quarterKey];
  });

  return sortedData;
}

export function getObjectAtIndex(data:any, index:number) {
    if (index >= 0 && index < data.length) {
        return data[index];
    } else {
        return null; // Return null if index is out of bounds
    }
}

export const printPDF = (
  Element: JSX.Element,
  filename: string,
) => {
  const div = document.createElement("div");
  ReactDOM.createRoot(div).render(Element);
  div.id = "table";
  const pdf = new jsPDF("p", "pt", "a4");
  const totalPages = pdf.internal.pages.length
  // console.log('pdf.internal.pages.length',pdf.internal.pageSize.width)
  
  
  for (let i = 1; i <= totalPages; i++) {
    pdf.setPage(i); 
   
  }
  pdf.html(div, {
    autoPaging: 'text', // Crucial for handling text flow across pages
    callback: function () {
      pdf.save(filename);
    },
  });
};

export   const captureContentAsImage = async (
  graphRef: { current: any },
  setImage: (imageUrl: string) => void
) => {
  if (!graphRef.current) return;
  const captureContainer = graphRef.current;
  try {
    const dataUrl = await toPng(captureContainer);
    setImage(dataUrl);
  } catch (error) {
    console.error("Error capturing content as image:", error);
  }
};
