import { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar } from 'recharts';
import { AlertTriangle, BarChart2, Briefcase, DollarSign, Globe, Scale, Target, TrendingUp } from 'lucide-react';
import MetricCard from '../MetricCard';
import InputField from '../InputField';
const Main = () => {
    const [activeTab, setActiveTab] = useState('overview');
    const [inputs, setInputs] = useState({
        // Financial Metrics
        baselineRevenue: 1000000,
        salesYear1: 100000,
        corporateTaxRate: 25,
        royaltyRate: 10,
        discountRate: 10,

        // Growth Rates
        growthRateForecast: 20,
        growthRateResidual: 2,
        historicalGrowthRate: 8,

        // Risk Factors
        royaltyRateLoss: 15,
        ipRiskLoss: 20,
        legalRiskLoss: 10,

        // IP Portfolio Metrics
        totalUniqueOwners: 25,
        countryRepresentatives: 15,
        frequentMarkInstances: 35,
        litigationPercentage: 12,
        missingMarks: 8,
        missingClasses: 5,
        nonUsePercentage: 15,
        expiringMarks: 10,

        // Market Dynamics
        marketGrowthRate: 5,
        baseMarketShare: 10,
        projectedMarketShare: 12,
        competitiveRiskPremium: 2,
        economicRiskPremium: 1,
        consumerDemandFactor: 95,
        regulatoryRiskPremium: 0.5,

        // Historical Performance
        historicalIpRiskLoss: 15,
        ipRiskFrequency: 20,
        averageLegalCosts: 100000,

        // Country Distribution
        keyMarketsCoverage: 85,
        secondaryMarketsCoverage: 70,

        // Product Terms
        missingProductTerms: 5,
        missingProductTermsCountry: 8
    });

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setInputs(prev => ({
            ...prev,
            [name]: parseFloat(value) || 0
        }));
    };
    
    return (
        <div className="container mx-auto mt-10 pb-5">
            <header className="mb-8">
                <h1 className="text-3xl font-bold text-[#111827]">Comprehensive Brand and IP Valuation Dashboard</h1>
                <p className="text-[#4B5563]">Complete valuation analysis with all risk factors and metrics</p>
            </header>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select your country</label>
                <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary"
                    onChange={(e) => setActiveTab(e.target.value)}
                >
                    <option value="overview">Overview</option>
                    <option value="financial">Financial Inputs</option>
                    <option value="ip-portfolio">IP Portfolio</option>
                    <option value="market">Market Dynamics</option>
                    <option value="historical">Historical Performance</option>
                </select>
            </div>
            <ul className="hidden text-sm font-medium text-center text-[#6B7280] rounded-lg shadow sm:flex">
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('overview')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-s-lg focus:outline-none ${activeTab === 'overview' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Overview</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('financial')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'financial' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Financial Inputs</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('ip-portfolio')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'ip-portfolio' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>IP Portfolio</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('market')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'market' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Market Dynamics</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('historical')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-e-lg focus:outline-none ${activeTab === 'historical' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Historical Dynamics</a>
                </li>
            </ul>
            <div className='mt-5'>
                {activeTab === 'overview' ?
                    <>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
                            <MetricCard
                                title="Total Valuation"
                                value={inputs.baselineRevenue * (1 - inputs.ipRiskLoss / 100)}
                                icon={DollarSign}
                                subtitle="Current brand value"
                                format="currency"
                            />
                            <MetricCard
                                title="IP Risk Score"
                                value={inputs.ipRiskLoss}
                                icon={AlertTriangle}
                                subtitle="Portfolio risk assessment"
                                format="percent"
                            />
                            <MetricCard
                                title="Market Position"
                                value={inputs.baseMarketShare}
                                icon={Target}
                                subtitle="Current market share"
                                format="percent"
                            />
                            <MetricCard
                                title="Growth Trajectory"
                                value={inputs.growthRateForecast}
                                icon={TrendingUp}
                                subtitle="Projected growth rate"
                                format="percent"
                            />
                        </div>
                    </>
                    : activeTab === 'financial' ?
                        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                            <span className='font-bold text-xl'>Financial Metrics</span>
                            <p className='mb-3 font-medium'>Core financial parameters for valuation</p>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                <InputField
                                    label="Baseline Revenue (£)"
                                    name="baselineRevenue"
                                    value={inputs.baselineRevenue}
                                    handleInputChange={handleInputChange}
                                />
                                <InputField
                                    label="Sales Year 1 (£)"
                                    name="salesYear1"
                                    value={inputs.salesYear1}
                                    handleInputChange={handleInputChange}
                                />
                                <InputField
                                    label="Corporate Tax Rate (%)"
                                    name="corporateTaxRate"
                                    value={inputs.corporateTaxRate}
                                    min="0"
                                    max="100"
                                    handleInputChange={handleInputChange}
                                />
                                <InputField
                                    label="Royalty Rate (%)"
                                    name="royaltyRate"
                                    value={inputs.royaltyRate}
                                    min="0"
                                    max="100"
                                    handleInputChange={handleInputChange}
                                />
                                <InputField
                                    label="Discount Rate (%)"
                                    name="discountRate"
                                    value={inputs.discountRate}
                                    min="0"
                                    max="100"
                                    handleInputChange={handleInputChange}
                                />
                                <InputField
                                    label="Growth Rate Forecast (%)"
                                    name="growthRateForecast"
                                    value={inputs.growthRateForecast}
                                    min="0"
                                    max="100"
                                    handleInputChange={handleInputChange}
                                />
                            </div>
                        </div>
                        : activeTab === 'ip-portfolio' ?
                            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                                <span className='font-bold text-xl'>IP Portfolio Metrics</span>
                                <p className='mb-3 font-medium'>Trade mark portfolio assessment parameters</p>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                    <InputField
                                        label="Total Unique Owners"
                                        name="totalUniqueOwners"
                                        value={inputs.totalUniqueOwners}
                                        min="0"
                                        handleInputChange={handleInputChange}
                                    />
                                    <InputField
                                        label="Country Representatives"
                                        name="countryRepresentatives"
                                        value={inputs.countryRepresentatives}
                                        min="0"
                                        handleInputChange={handleInputChange}
                                    />
                                    <InputField
                                        label="Frequent Mark Instances"
                                        name="frequentMarkInstances"
                                        value={inputs.frequentMarkInstances}
                                        min="0"
                                        handleInputChange={handleInputChange}
                                    />
                                    <InputField
                                        label="Litigation Percentage (%)"
                                        name="litigationPercentage"
                                        value={inputs.litigationPercentage}
                                        min="0"
                                        max="100"
                                        handleInputChange={handleInputChange}
                                    />
                                    <InputField
                                        label="Missing Marks"
                                        name="missingMarks"
                                        value={inputs.missingMarks}
                                        min="0"
                                        handleInputChange={handleInputChange}
                                    />
                                    <InputField
                                        label="Missing Classes"
                                        name="missingClasses"
                                        value={inputs.missingClasses}
                                        min="0"
                                        handleInputChange={handleInputChange}
                                    />
                                    <InputField
                                        label="Non-Use Percentage (%)"
                                        name="nonUsePercentage"
                                        value={inputs.nonUsePercentage}
                                        min="0"
                                        max="100"
                                        handleInputChange={handleInputChange}
                                    />
                                    <InputField
                                        label="Expiring Marks"
                                        name="expiringMarks"
                                        value={inputs.expiringMarks}
                                        min="0"
                                        handleInputChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            : activeTab === 'market' ?
                                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                                    <span className='font-bold text-xl'>Market Dynamics</span>
                                    <p className='mb-3 font-medium'>Market-related parameters and risk factors</p>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                        <InputField
                                            label="Market Growth Rate (%)"
                                            name="marketGrowthRate"
                                            value={inputs.marketGrowthRate}
                                            min="-100"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                        <InputField
                                            label="Base Market Share (%)"
                                            name="baseMarketShare"
                                            value={inputs.baseMarketShare}
                                            min="0"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                        
                                        <InputField
                                            label="Projected Market Share (%)"
                                            name="projectedMarketShare"
                                            value={inputs.projectedMarketShare}
                                            min="0"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                        <InputField
                                            label="Competitive Risk Premium (%)"
                                            name="competitiveRiskPremium"
                                            value={inputs.competitiveRiskPremium}
                                            min="0"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                        <InputField
                                            label="Economic Risk Premium (%)"
                                            name="economicRiskPremium"
                                            value={inputs.economicRiskPremium}
                                            min="0"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                        <InputField
                                            label="Consumer Demand Factor (%)"
                                            name="consumerDemandFactor"
                                            value={inputs.consumerDemandFactor}
                                            min="0"
                                            max="200"
                                            handleInputChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                : <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                                    <span className='font-bold text-xl'>Historical Performance</span>
                                    <p className='mb-3 font-medium'>Historical data and performance metrics</p>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                        <InputField
                                            label="Historical IP Risk Loss (%)"
                                            name="historicalIpRiskLoss"
                                            value={inputs.historicalIpRiskLoss}
                                            min="0"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                        <InputField
                                            label="IP Risk Frequency (%)"
                                            name="ipRiskFrequency"
                                            value={inputs.ipRiskFrequency}
                                            min="0"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                        <InputField
                                            label="Average Legal Costs (£)"
                                            name="averageLegalCosts"
                                            value={inputs.averageLegalCosts}
                                            min="0"
                                            handleInputChange={handleInputChange}
                                        />
                                        <InputField
                                            label="Historical Growth Rate (%)"
                                            name="historicalGrowthRate"
                                            value={inputs.historicalGrowthRate}
                                            min="-100"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                        <InputField
                                            label="Key Markets Coverage (%)"
                                            name="keyMarketsCoverage"
                                            value={inputs.keyMarketsCoverage}
                                            min="0"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                        <InputField
                                            label="Secondary Markets Coverage (%)"
                                            name="secondaryMarketsCoverage"
                                            value={inputs.secondaryMarketsCoverage}
                                            min="0"
                                            max="100"
                                            handleInputChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                }
            </div>
        </div>
    )
}
export default Main