

import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

type TProps = {
  onFileUpload: (file: File) => void;
};

const LogoForm = ({ onFileUpload }: TProps) => {
  const [processing, setProcessing] = useState(false);
  const [file, setFile] = useState<any>(null)
  const runScript = async (file: File) => {
    console.log(file)
    setProcessing(true);
    try {
      onFileUpload(file);
      setFile(file)
      toast.success("File uploaded successfully!");
    } catch (error) {
      toast.error("Error processing file.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="max-w-[300px]">
      <ToastContainer />
      <div className="flex items-center justify-center w-full">
        <div className="relative max-w-[300px] h-[70px] border-2 border-[#033d44] border-dashed rounded-lg cursor-pointer bg-[#dcdcdc] hover:bg-opacity-50">
          <input
            onChange={(e) => {
              if (e.target.files) {
                runScript(e.target.files[0]!);
              }
            }}
            id="dropzone-file"
            type="file"
            className="cursor-pointer relative block opacity-0 w-full h-full p-20 z-50"
            accept="image/png, image/jpeg, image/jpg"
          />

          {processing && (
            <div className="absolute inset-0 flex items-center justify-center bg-[#dcdcdc] bg-opacity-50 z-50">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green"></div>
            </div>
          )}
          <div className="text-center p-16 absolute top-0 bottom-2 right-0 left-0 m-auto">
            {processing ? (
              <p className="mb-2 text-sm text-green -mt-14">Loading...</p>
            ) : (
              <>
                <svg
                  className="w-10 h-10 ml-[-40px] mt-[-10px] mb-3 text-green"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p className="mb-2 text-sm text-green -mt-[3.5rem]">
                  <span className="font-semibold">{file ? file.name:'Click to upload logo or drag & drop'}</span> 
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoForm;
