import {
  Column,
  useTable,
  useGlobalFilter,
  TableOptions,
  TableInstance,
  TableState,
} from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { Columns } from "types";

type TProps = {
  columns: Column<Columns>[];
  data: Columns[];
  index: number;
};

const MapTable = ({ columns, data, index }: TProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable<Columns>(
    {
      columns,
      data,
    },
    useGlobalFilter
  ) as TableInstance<Columns> & {};

  const { globalFilter }: any = state;
  return (
    <div className="gap-y-3">
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left table-auto">
          <thead className="text-xs text-white uppercase bg-green border border-[#dcdcdc] sticky">
            {index === 0 &&
              headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="">
                  {headerGroup.headers.map((column, cellIndex) => (
                    <th
                      {...column.getHeaderProps()}
                      className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider "
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main "
                >
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      {...cell.getCellProps()}
                      key={cellIndex}
                      className={`px-7 py-4 text-black border border-main ${cellIndex % 2 === 0 ? 'w-[200px]':''}`}
                    >
                      {cell.column.id === 'logoSection' ? cell.value && cell.value !=='null'? <img src={process.env.REACT_APP_URL+cell.value} className="h-12 w-12"/>:"-" :
                                cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MapTable;
