import ToggleSwitch from 'components/Switch';
import { COUNTRIES } from '../../constants';
import { useState } from 'react';
import CustomRadioButton from './components/Checkbox';
import Button from 'components/Button';
const TrawlSection = () => {
    const [selectedCountries, setSelectedCountries] = useState<any>([])
    const [representativeSelectedOption, setRepresentativeSelectedOption] = useState("");
    const [vulnerableSelectedOption, setVulnerableSelectedOption] = useState("");
    const [dueSelectedOption, setDueSelectedOption] = useState("");
    const [timePeriodSelectedOption, setTimePeriodSelectedOption] = useState("");
    const handleToggle = (country: string, price: string) => {

    }
    return (
        <div className="container mx-auto px-5">
            <h3 className="text-3xl sm:mt-8 mt-[140px] text-center text-black font-bold">Create Trawl</h3>
            <h4 className="text-xl mt-8 text-black font-bold">Select Country</h4>
            <div className="flex flex-wrap  gap-x-3 justify-center items-center">
                {[...COUNTRIES, ...[
                    {
                        country: "All Abion Countries",
                        flag: null,
                    },
                ]].map((item) => (
                    <ToggleSwitch
                        key={item.country}
                        country={item.country}
                        flag={item.flag}
                        cssClass={'h-[74px]'}
                        onToggle={handleToggle}
                        select={selectedCountries[0]?.country}

                    />
                ))}
            </div>
            <h4 className="text-xl mt-8 text-black font-bold">Select office of origin OO reference</h4>
            <div className="flex flex-wrap  gap-x-3 justify-center items-center">
                {[
                    {
                        country: "GB",
                        flag: null,
                    },
                    {
                        country: "WO",
                        flag: null,
                    },
                    {
                        country: "US",
                        flag: null,
                    },
                    {
                        country: "NO",
                        flag: null,
                    },
                    {
                        country: "CN",
                        flag: null,
                    },
                    {
                        country: "CH",
                        flag: null,
                    },
                    {
                        country: "SE",
                        flag: null,
                    },
                    {
                        country: "TR",
                        flag: null,
                    }
                ].map((item) => (
                    <ToggleSwitch
                        key={item.country}
                        country={item.country}
                        flag={item.flag}
                        onToggle={handleToggle}
                        select={selectedCountries[0]?.country}

                    />
                ))}
            </div>
            <h4 className="text-xl mt-8 text-black font-bold">Select Representative or No Representatives</h4>
            <div className='flex flex-wrap  gap-x-3 justify-center items-center'>
                <CustomRadioButton
                    name="representative"
                    value="Representative"
                    label="Representative"
                    checked={representativeSelectedOption === "Representative"}
                    onChange={(e: any) => {
                        setRepresentativeSelectedOption(e.target.value);
                    }}
                />
                <CustomRadioButton
                    name="representative"
                    value="No Representative"
                    label="No Representative"
                    checked={representativeSelectedOption === "No Representative"}
                    onChange={(e: any) => {
                        setRepresentativeSelectedOption(e.target.value);
                    }}
                />
            </div>
            <h4 className="text-xl mt-8 text-black font-bold">Select Vulnerable or Not Vulnerable</h4>
            <div className='flex flex-wrap  gap-x-3 justify-center items-center'>
                <CustomRadioButton
                    name="vulnerable"
                    value="Vulnerable"
                    label="Vulnerable"
                    checked={vulnerableSelectedOption === "Vulnerable"}
                    onChange={(e: any) => {
                        setVulnerableSelectedOption(e.target.value);
                    }}
                />
                <CustomRadioButton
                    name="vulnerable"
                    value="Not Vulnerable"
                    label="Not Vulnerable"
                    checked={vulnerableSelectedOption === "Not Vulnerable"}
                    onChange={(e: any) => {
                        setVulnerableSelectedOption(e.target.value);
                    }}
                />
            </div>
            <h4 className="text-xl mt-8 text-black font-bold">Select due for renewal in the next 3 years</h4>
            <div className='flex flex-wrap  gap-x-3 justify-center items-center'>
                <CustomRadioButton
                    name="due"
                    value="Yes"
                    label="Yes"
                    checked={dueSelectedOption === "Yes"}
                    onChange={(e: any) => {
                        setDueSelectedOption(e.target.value);
                    }}
                />
                <CustomRadioButton
                    name="due"
                    value="No"
                    label="No"
                    checked={dueSelectedOption === "No"}
                    onChange={(e: any) => {
                        setDueSelectedOption(e.target.value);
                    }}
                />
            </div>
            <h4 className="text-xl mt-8 text-black font-bold">Select time period</h4>
            <div className='flex flex-wrap  gap-x-3 justify-center items-center'>
                <CustomRadioButton
                    name="timePeriod"
                    value="3 months"
                    label="Last 3 Months"
                    checked={timePeriodSelectedOption === "3 months"}
                    onChange={(e: any) => {
                        setTimePeriodSelectedOption(e.target.value);
                    }}
                />
                <CustomRadioButton
                    name="timePeriod"
                    value="6 months"
                    label="Last 6 Months"
                    checked={timePeriodSelectedOption === "6 months"}
                    onChange={(e: any) => {
                        setTimePeriodSelectedOption(e.target.value);
                    }}
                />
                <CustomRadioButton
                    name="timePeriod"
                    value="24 months"
                    label="Last 24 Months"
                    checked={timePeriodSelectedOption === "24 months"}
                    onChange={(e: any) => {
                        setTimePeriodSelectedOption(e.target.value);
                    }}
                />
                <CustomRadioButton
                    name="timePeriod"
                    value="5 years"
                    label="Last 5 Years"
                    checked={timePeriodSelectedOption === "5 years"}
                    onChange={(e: any) => {
                        setTimePeriodSelectedOption(e.target.value);
                    }}
                />
                <CustomRadioButton
                    name="timePeriod"
                    value="10 years"
                    label="Last 10 Years"
                    checked={timePeriodSelectedOption === "10 years"}
                    onChange={(e: any) => {
                        setTimePeriodSelectedOption(e.target.value);
                    }}
                />
            </div>
            <h4 className="text-xl mt-8 text-black font-bold">Select Interest Areas</h4>
            <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary">
                <option value=""></option>
                <option value="AI">AI</option>
                <option value="Sports">Sports</option>
                <option value="Technology">Technology</option>
                <option value="Music">Music</option>
                <option value="Science">Science</option>
            </select>
            <h4 className="text-xl mt-8 text-black font-bold">Select Funded Companies</h4>
            <select className="focus:outline-none rounded-2xl w-full  py-2 mb-3 px-4 border-4 border-main text-primary">
                <option value=""></option>
                <option value="www.luminousxr.com">Luminous Group</option>
                <option value="outbound.aero">Outbound Aerospace</option>
                <option value="www.aerofugia.com">Aerofugia</option>
                <option value="agrovisioncorp.com">Agrovision</option>
                <option value="Mol Breeding">www.molbreeding.com</option>
            </select>
            <h4 className="text-xl mt-8 text-black font-bold">Request Details</h4>
            <textarea
                className="focus:outline-none shadow-xl mb-3 border-4 border-[#033d44] resize-none block p-2.5 w-full text-black bg-[#fbfbfb] rounded-2xl border focus:ring-mainBlue focus:border-mainBlue"
                rows={10}
            >
            </textarea>
            <h4 className="text-xl mt-8 text-black font-bold">Email Results</h4>
            <input type="email"
                className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl mb-3 focus:outline-none" placeholder="Enter Email to get trawling results" />
              <div className='my-5 text-center w-fit mx-auto'>
                <Button title='Submit'/>
                </div>
        </div>
    )
}
export default TrawlSection