import { useState } from "react"
import {
    FileText, DollarSign, BarChart2, Plus,
    Calendar, Search, Code, CircleSlash,
    CheckCircle, Terminal, Link,
    ArrowRight, Boxes
} from 'lucide-react';
const Main = () => {
    const [selectedLicense, setSelectedLicense] = useState(null);
    const [conversionStatus, setConversionStatus] = useState<any>(null);
    const [showContractModal, setShowContractModal] = useState(false);

    const sampleLicense = {
        id: "TM-2024-001",
        trademark: "SampleMark™",
        owner: "Brand Owner Inc.",
        licensee: "Tech Corp Ltd",
        type: "Exclusive",
        territory: "Worldwide",
        term: {
            start: "2024-01-01",
            end: "2029-01-01",
            renewable: true
        },
        royalties: {
            rate: "5%",
            minimumPayment: "10000",
            currency: "USD",
            paymentFrequency: "Quarterly"
        },
        restrictions: [
            "No sublicensing permitted",
            "Digital use only",
            "Non-transferable"
        ],
        trademarkDetails: {
            registrationNumber: "UK00003123456",
            classes: [9, 42],
            jurisdictions: ["UK", "EU"]
        }
    };

    const handleConvertToSmartContract = async () => {
        setConversionStatus('preparing');
        await new Promise(resolve => setTimeout(resolve, 1500));
        setConversionStatus('converting');
        await new Promise(resolve => setTimeout(resolve, 2000));
        setConversionStatus('validating');
        await new Promise(resolve => setTimeout(resolve, 1000));
        setConversionStatus('complete');
    };

    return (
        <div className="container mx-auto mt-10 pb-5">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold">Smart Contract Conversion</h2>
                <button
                    onClick={() => setShowContractModal(true)}
                    className="flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg"
                >
                    <Code className="w-5 h-5" />
                    Convert License
                </button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 my-5">
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="flex items-center gap-2 mb-3">
                        <FileText className="w-5 h-5" />
                        <span className="font-bold">License Details</span>
                    </div>
                    <div>
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <span className="text-[#4B5563]">Status</span>
                                <span className="px-2 py-1 bg-[#DCFCE7] text-[#166534] rounded-full text-sm text-center">
                                    Ready for Conversion
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#4B5563]">Type</span>
                                <span className="text-[#111827]">Exclusive License</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#4B5563]">Term</span>
                                <span className="text-[#111827]">5 Years</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="flex items-center gap-2 mb-3">
                        <Boxes className="w-5 h-5" />
                        <span className="font-bold"> NFT Structure</span>
                    </div>
                    <div>
                        <div className="space-y-4">
                            <div className="flex items-center gap-2">
                                <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                <span>Trademark Data</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                <span>License Terms</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                <span>Payment Structure</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                <span>Usage Rights</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="flex items-center gap-2 mb-3">
                        <Terminal className="w-5 h-5" />
                        <span className="font-bold"> Smart Contract Status</span>
                    </div>
                    <div>
                        <div className="space-y-4">
                            {conversionStatus === null && (
                                <div className="text-center py-4 text-[#6B7280]">
                                    Ready to generate smart contract
                                </div>
                            )}
                            {conversionStatus === 'preparing' && (
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2">
                                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-emerald-500"></div>
                                        <span>Preparing license data...</span>
                                    </div>
                                </div>
                            )}
                            {conversionStatus === 'converting' && (
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2">
                                        <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                        <span>License data prepared</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-emerald-500"></div>
                                        <span>Converting to smart contract...</span>
                                    </div>
                                </div>
                            )}
                            {conversionStatus === 'complete' && (
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2">
                                        <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                        <span>Smart contract generated</span>
                                    </div>
                                    <button className="w-full mt-4 px-4 py-2 bg-green text-white rounded-lg flex items-center justify-center gap-2">
                                        <Link className="w-4 h-4" />
                                        View on Chain
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                <span className="font-bold">Contract Preview</span>
                <div className="mt-3">
                    <div className="bg-[#111827] text-[#F3F4F6] p-4 rounded-lg font-mono text-sm overflow-x-auto">
                        <pre>
                            {`// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

import "@openzeppelin/contracts/token/ERC721/ERC721.sol";
import "@openzeppelin/contracts/access/Ownable.sol";

contract TrademarkLicense is ERC721, Ownable {
    struct License {
        string trademarkId;
        address licensee;
        uint256 startDate;
        uint256 endDate;
        string territory;
        uint256 royaltyRate;
        bool exclusive;
    }

    mapping(uint256 => License) public licenses;
    
    // Placeholder for actual implementation
    // Add your NFT logic here
}`}
                        </pre>
                    </div>
                </div>
            </div>

            {/* Conversion Modal */}
            {showContractModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                        <span className="font-bold">Convert License to Smart Contract</span>
                        <div>
                            <div className="space-y-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <h3 className="text-sm font-medium text-[#374151] mb-2">License Terms</h3>
                                        <div className="space-y-2">
                                            {Object.entries(sampleLicense).map(([key, value]) => (
                                                <div key={key} className="flex items-center gap-2">
                                                    <CheckCircle className="w-4 h-4 text-[#22C55E] flex-shrink-0" />
                                                    <span className="text-sm text-[#4B5563]">{key}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="text-sm font-medium text-[#374151] mb-2">Smart Contract Elements</h3>
                                        <div className="space-y-2">
                                            <div className="flex items-center gap-2">
                                                <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                                <span className="text-sm text-[#4B5563]">NFT Metadata</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                                <span className="text-sm text-[#4B5563]">Access Control</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                                <span className="text-sm text-[#4B5563]">Payment Logic</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <CheckCircle className="w-4 h-4 text-[#22C55E]" />
                                                <span className="text-sm text-[#4B5563]">Usage Rights</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-end gap-4">
                                    <button
                                        onClick={() => setShowContractModal(false)}
                                        className="px-4 py-2 text-[#4B5563]"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowContractModal(false);
                                            handleConvertToSmartContract();
                                        }}
                                        className="px-4 py-2 bg-green text-white rounded-lg"
                                    >
                                        Convert to Smart Contract
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default Main