

const Skeleton = () => {
    return (
        <div role="status" className="max-w-full p-4 mt-10 mx-5 animate-pulse md:p-6 w-[600px] h-full bg-[#ECECEC]">
            <div role="status" className="max-w-sm p-4 border   animate-pulse md:p-6 dark:border-gray-700">
               
                <div className="flex items-baseline mt-4">
                    <div className="w-28 bg-gray-400 rounded-t-lg h-96 px-8 dark:bg-gray-600"></div>
                    <div className="w-28 h-56 ms-6 bg-gray-400 rounded-t-lg dark:bg-gray-600"></div>
                    <div className="w-28 bg-gray-400 rounded-t-lg h-56 ms-6 px-8 dark:bg-gray-600"></div>
                    <div className="w-28 h-64 ms-6 bg-gray-400 rounded-t-lg  px-8 dark:bg-gray-600"></div>
                    <div className="w-28 bg-gray-400 rounded-t-lg h-80 ms-6  px-8 dark:bg-gray-600"></div>
                    <div className="w-28 bg-gray-400 rounded-t-lg h-72 ms-6  px-8 dark:bg-gray-600"></div>

                    
                </div>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}

export default Skeleton;
