import { api } from "config";
import { useCallback } from "react";
import { toast } from "react-toastify";
export const useDomainAnalysis = () => {
    const getPunnyCode = useCallback(async (domains: string) => {
        try {
            const { data } = await api.post("process_domains", { domains: domains });
            return data;
        }
        catch (e: any) {
            toast.error(e)
        }
    }, []);
    const generateDomainsAnalysis = useCallback(async (email:string, file_name:string) => {
        try {
            const { data } = await api.post("start-process", { email,file_name});
            return data;
        }
        catch (e: any) {
            toast.error(e)
        }
    }, []);
    const getDomainVariations = useCallback(async (words: string[], email:string, status:boolean) => {
        try {
            const payload = {
                words, 
                email: status ? email : ""
            }
            const { data } = await api.post("generate-tlds", payload);
            if(status){
                const fileName = data.file_path.split("/")
                const analysisFileName = fileName[fileName.length - 1]
                await generateDomainsAnalysis(email,analysisFileName)
            toast.success("Domain Analyis Report Generation has been stared. Will be emailed to you shortly.")
            }
            return data;
        }
        catch (e: any) {
            toast.error(e)
        }
    }, []);
    const checkDomainsStatus = useCallback(async (domains: string[]) => {
        try {
            const { data } = await api.post("check-domains", { domains: domains });
            return data;
        }
        catch (e: any) {
            toast.error(e)
        }
    }, []);
    const downloadPunnyCodeReport = useCallback(async () => {
        try {
            const response = await api.get("download_domains", {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'domains-result.xlsx');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }, []);
    const downloadReport = useCallback(async (data:any, fileName:string) => {
        try {
            // Perform the API request to download the file
            const response = await api.post("download_single_file",data, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.xlsx`); // Specify the filename and extension
            document.body.appendChild(link);
            link.click();
            link.remove()
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }, []);
    const sendCompanyNames = useCallback(async (company_names: string[], recipient_email:string) => {
        try {
            const { data } = await api.post("send-company-names", { company_names, recipient_email });
            toast.success("Email Sent Succesfully.")
        }
        catch (e: any) {
            toast.error(e)
        }
    }, []);
    return {
        getPunnyCode,
        downloadPunnyCodeReport,
        getDomainVariations,
        checkDomainsStatus,
        downloadReport,
        sendCompanyNames
    }
}