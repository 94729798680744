import React, { useEffect, useMemo } from "react";
import {
  useTable,
  useRowSelect,
  useGlobalFilter,
  TableInstance,
  TableState,
} from "react-table";
import { Column } from "react-table";
import { GlobalFilter } from "./Table/GlobalFilter";
import { Columns } from "types";
import { Checkbox } from "./Checkbox";
import { JSX } from "react/jsx-runtime";

type TProps = {
  columns: Column<Columns>[];
  data: Columns[];
  select: any;
  onSelectChange: (selection: any[]) => void; // Add onSelectChange callback
  showSelector:boolean

};

export const RowSelection = ({
  columns,
  data,
  select,
  showSelector,
  onSelectChange,
}: TProps) => {
  const [selection, setSelection] = React.useState(select);

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    selectedFlatRows,
  }: any = useTable<Columns>(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: { row: any }) => (
            <Checkbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  ) as TableInstance<Columns> & {
    setGlobalFilter: (filterValue: string) => void;
  };

  const { globalFilter }: any = state;

  useEffect(() => {
    onSelectChange(selectedFlatRows.map((row: any) => row.original));
  }, [selectedFlatRows]);

  useEffect(() => {
    setSelection(select);
  }, [select]);
  return (
    <>
      {showSelector && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />}
      <table className="w-full text-sm text-left">
        <thead className="text-xs text-white uppercase bg-green border border-[#dcdcdc] sticky">
          {headerGroups.map(
            (headerGroup: {
              getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                React.ClassAttributes<HTMLTableRowElement> &
                React.HTMLAttributes<HTMLTableRowElement>;
              headers: any[];
            }) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, cellIndex: Number) => (
                  <th
                    {...column.getHeaderProps()}
                    className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider "
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            )
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, index: Number) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8]  border-main"
              >
                {row.cells.map((cell: any, cellIndex: Number) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-7 py-4 text-black border border-main"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
