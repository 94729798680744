import React, { useState, useEffect } from 'react';
import checkmark from './../../assests/images/checkMark.png';
import { turkey } from 'assests';

type IProps = {
  country: string;
  price?: string;
  flag: any;
  onToggle: (country: string, price: string) => void;
  select: any;
  cssClass?:string;
};

const ToggleSwitch = ({ cssClass, country, price, flag, onToggle, select}: IProps) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(select === country);
  }, [select, country]);

  const handleChange = () => {
    setChecked(!checked);
    onToggle(country, price!);
  };

  return (
    <div className={`w-full max-w-[290px]`}>
      <label
        className={`border p-2 py-4 ${cssClass ? cssClass:""} items-center my-4 gap-x-4 flex justify-center rounded-xl  ${checked ? 'border-[#033d44] border-2' : 'border-[#dadbdb]'
          }`}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          className="toggle-checkbox hidden"
          id="toggle"
        />
        <div className="flex justify-start items-center">
          {flag ?
            <img src={flag} alt="" className={`w-10 h-10 mr-2 ${price} ? '-mt-6':''`} />
            : null}
          <div className="gap-y-4">
            <h1 className="text-xl text-primary font-semibold">{country}</h1>
            {price ? <p className="text-lg text-primary">{price}</p> : null}
          </div>
        </div>
        <div
          className={`w-6 h-6 border rounded-full border-gray-400 ml-auto  ${price} ? '-mt-6':''  ${checked ? 'border-none' : 'border-[#249365]'
            }`}
        >
          <div
            className={`w-5 h-5 border rounded-full border-gray-400 ml-auto mx-auto mt-[6px] ${checked ? 'bg-[#033d44] border-[#033d44] hidden' : 'hidden'
              }`}
          />
          {checked && <img src={checkmark} className="w-7 h-7" />}
        </div>
      </label>
      <div></div>
    </div>
  );
};

export default ToggleSwitch;
