import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import { TDATABASEMANAGEMENTSLICE, TTRADEMARKRECORD } from "./type"
import { setFrequencyAnalysisAction } from ".";
export const useMostFrequency = () => {
    const { Records, showResult, } = useSelector<RootState, TDATABASEMANAGEMENTSLICE>(
        (state) => state.mostFrequency 
    );
    const dispatch = useAppDispatch();
    const setMostFrequency = useCallback(
        (payload: TTRADEMARKRECORD) => {
            dispatch(setFrequencyAnalysisAction(payload));
        },
        [dispatch]
    );
    return {
        Records,
        showResult,
        setMostFrequency
    };
}