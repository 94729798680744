import { useState } from 'react';
import { AlertTriangle, BarChart2, Upload, Brain, FileText, Building, Globe, Target, TrendingUp, DollarSign } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import ValuationReports from '../ValuationReports';
const Main = () => {
    const [activeTab, setActiveTab] = useState('company');
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [analysisComplete, setAnalysisComplete] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<any>(null);
    const [companyProfile, setCompanyProfile] = useState({
        name: '',
        industry: '',
        region: '',
        portfolioSize: '',
        yearEstablished: '',
        primaryMarkets: '',
        secondaryMarkets: ''
    });
    const [analysisResults, setAnalysisResults] = useState<any>({
        overallScore: 0,
        riskScore: 0,
        marketScore: 0,
        growthScore: 0,
        portfolioStrength: 0,
        valuationRange: { min: 0, max: 0 },
        recommendations: []
    });
    const [inputs, setInputs] = useState({
    });

    const handleCompanyProfileChange = (e: any) => {
        const { name, value } = e.target;
        setCompanyProfile(prev => ({
            ...prev,
            [name]: value
        }));
    };
    const handleFileUpload = (e: any) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };

    const runAIAnalysis = async () => {
        setIsAnalyzing(true);
        // Simulate AI analysis
        await new Promise(resolve => setTimeout(resolve, 3000));

        // Sample analysis results
        setAnalysisResults({
            overallScore: 85,
            riskScore: 72,
            marketScore: 88,
            growthScore: 92,
            portfolioStrength: 78,
            valuationRange: { min: 5000000, max: 7500000 },
            recommendations: [
                "Register additional trade marks in emerging markets",
                "Consider defensive registrations for core brands",
                "Review and renew marks approaching expiration",
                "Strengthen protection in key growth markets"
            ]
        });

        setIsAnalyzing(false);
        setAnalysisComplete(true);
        setActiveTab('report');
    };
    return (
        <div className="container mx-auto mt-10 pb-5">
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select your country</label>
                <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary"
                    onChange={(e) => setActiveTab(e.target.value)}
                >
                    <option value="company">Company Profile</option>
                    <option value="portfolio">Portfolio Upload</option>
                    {/* <option value="inputs">Valuation Inputs</option> */}
                    <option value="analysis">AI Analysis</option>
                    <option value="report">Report</option>
                </select>
            </div>
            <ul className="hidden text-sm font-medium text-center text-[#6B7280] rounded-lg shadow sm:flex">
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('company')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-s-lg focus:outline-none ${activeTab === 'company' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Company Profile</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('portfolio')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'portfolio' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Portfolio Upload</a>
                </li>
                {/* <li className="w-full focus-within:z-10" onClick={() => setActiveTab('inputs')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'inputs' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Valuation Inputs</a>
                </li> */}
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('analysis')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'analysis' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>AI Analysis</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('report')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-e-lg focus:outline-none ${activeTab === 'report' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Report</a>
                </li>
            </ul>
            <div className='mt-5'>
                {activeTab === 'company' ?
                    <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                        <span className='font-bold text-xl'>Company Information</span>
                        <p className='mb-3 font-medium'>Enter company details for analysis</p>
                        <div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Company Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={companyProfile.name}
                                        onChange={handleCompanyProfileChange}
                                        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none"
                                    />
                                </div>

                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Industry</label>
                                    <input
                                        type="text"
                                        name="industry"
                                        value={companyProfile.industry}
                                        onChange={handleCompanyProfileChange}
                                        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none" />
                                </div>

                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Region</label>
                                    <input
                                        type="text"
                                        name="region"
                                        value={companyProfile.region}
                                        onChange={handleCompanyProfileChange}
                                        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none" />
                                </div>

                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Portfolio Size</label>
                                    <input
                                        type="number"
                                        name="portfolioSize"
                                        value={companyProfile.portfolioSize}
                                        onChange={handleCompanyProfileChange}
                                        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none" />
                                </div>

                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Year Established</label>
                                    <input
                                        type="number"
                                        name="yearEstablished"
                                        value={companyProfile.yearEstablished}
                                        onChange={handleCompanyProfileChange}
                                        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none" />
                                </div>

                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Primary Markets</label>
                                    <input
                                        type="text"
                                        name="primaryMarkets"
                                        value={companyProfile.primaryMarkets}
                                        onChange={handleCompanyProfileChange}
                                        className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none" />
                                </div>
                            </div>
                        </div>
                    </div>
                    : activeTab === 'portfolio' ?
                        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                            <span className='font-bold text-xl'>Portfolio Upload</span>
                            <p className='mb-3 font-medium'>Upload your trade mark portfolio for analysis</p>
                            <div className="space-y-4">
                                <div className="border-2 border-dashed rounded-lg p-6 text-center">
                                    <Upload className="mx-auto h-12 w-12 text-[#9CA3AF]" />
                                    <p className="mt-2 text-sm text-[#4B5563]">
                                        Drag and drop your portfolio file here, or click to browse
                                    </p>
                                    <input
                                        type="file"
                                        onChange={handleFileUpload}
                                        className="mt-4"
                                        accept=".csv,.xlsx,.xls"
                                    />
                                </div>
                                {uploadedFile && (
                                    <div className='bg-[#F0FDF4] border border-[#16A34A] flex items-center mt-5 p-5 text-[#166534]'>
                                        <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <span className="sr-only">Info</span>

                                        <div>
                                            Successfully uploaded: {uploadedFile.name}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        : activeTab === 'analysis' ?
                            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                                <span className='font-bold text-xl'>AI Analysis</span>
                                <p className='mb-3 font-medium'>Run comprehensive portfolio analysis</p>
                                <div className="space-y-4">
                                    <button
                                        onClick={runAIAnalysis}
                                        disabled={isAnalyzing}
                                        className="w-full flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg"
                                    >
                                        {isAnalyzing ? (
                                            <span className="flex items-center">
                                                <Brain className="animate-pulse mr-2" />
                                                Analyzing...
                                            </span>
                                        ) : (
                                            <span className="flex items-center">
                                                <Brain className="mr-2" />
                                                Run AI Analysis
                                            </span>
                                        )}
                                    </button>

                                    {isAnalyzing && (
                                        <div className="text-center text-sm text-[#4B5563]">
                                            Processing portfolio data and calculating valuation metrics...
                                        </div>
                                    )}
                                </div>
                            </div>
                            : <>
                                {analysisComplete ? (
                                    <ValuationReports companyProfile={companyProfile} analysisResults={analysisResults} />) : (
                                    <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                                        <div className="text-center py-8">
                                            <FileText className="mx-auto h-12 w-12 text-[#9CA3AF]" />
                                            <p className="mt-2 text-[#4B5563]">
                                                Run the AI analysis to generate your valuation report
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </>
                }
            </div>
            {/*  */}
        </div>
    )
}
export default Main