import { useState } from "react"
import { Upload, Search, Globe, CheckCircle, XCircle, Loader, Download, ClipboardPaste } from 'lucide-react';
import * as XLSX from 'xlsx';
const Main = () => {
    const [companies, setCompanies] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [processingStatus, setProcessingStatus] = useState('');
    const [file, setFile] = useState<any>(null)
    const parseCompanyList = (data: any) => {
        const processedCompanies = [];
        for (let i = 0; i < data.length; i++) {
            const name = data[i]
            if (name) {
                processedCompanies.push({
                    name,
                    website: null,
                    websiteStatus: null,
                    bimiStatus: null,
                    checking: false
                });
            }
        }

        return processedCompanies;
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const reader = new FileReader();
        reader.onload = (e: any) => {
            let data: any = null
            if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                data = jsonData
            } else if (fileExtension === 'csv') {
                const csvData = e.target.result;
                const lines = csvData.split('\n');
                const result = [];
                const headers = lines[0].split(',');

                for (let i = 1; i < lines.length; i++) {
                    const obj: any = {};
                    const currentLine = lines[i].split(',');

                    headers.forEach((header: any, index: number) => {
                        obj[header.trim()] = currentLine[index].trim();
                    });

                    result.push(obj);
                }

                data = result
            }
            setLoading(true);
            setError(null);

            try {
                console.log(data.map((item: any) => item['Company Name']))
                const processedCompanies = parseCompanyList(data.map((item: any) => item['Company Name']));
                if (processedCompanies.length === 0) {
                    throw new Error('No valid companies found in the file');
                }
                console.log(processedCompanies)
                setCompanies(processedCompanies);
                setProcessingStatus(`Processed ${processedCompanies.length} companies`);
                analyzeCompanies(processedCompanies)
            } catch (err: any) {
                console.log(err)
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (fileExtension === 'xlsx' || fileExtension === 'xls') {
            reader.readAsBinaryString(file);
        } else if (fileExtension === 'csv') {
            reader.readAsText(file);
        } else {
            alert('Invalid file type!');
        }
    };

    const guessWebsite = (companyName: string) => {
        return `https://www.${companyName
            .toLowerCase()
            .replace(/s\.p\.a\.|spa|s\.r\.l\.|srl|s\.c\.r\.l\.|scrl/gi, '')
            .replace(/[^\w\s-]/g, '')
            .trim()
            .replace(/\s+/g, '-')}.com`;
    };

    const analyzeCompanies = async (processedCompanies: any) => {
        setLoading(true);
        const updatedCompanies = [...processedCompanies];
        console.log(updatedCompanies)
        for (let i = 0; i < updatedCompanies.length; i++) {
            const company = updatedCompanies[i];
            company.checking = true;
            setCompanies([...updatedCompanies]);
            setProcessingStatus(`Analyzing ${company.name} (${i + 1}/${updatedCompanies.length})`);

            try {
                if (!company.website) {
                    company.website = guessWebsite(company.name);
                }

                // Simulate website check
                await new Promise(resolve => setTimeout(resolve, 200));
                company.websiteStatus = Math.random() > 0.2;

                if (company.websiteStatus) {
                    const domain = new URL(company.website).hostname.replace('www.', '');
                    await new Promise(resolve => setTimeout(resolve, 300));
                    company.bimiStatus = {
                        hasBIMI: Math.random() > 0.7,
                        record: Math.random() > 0.7 ?
                            `v=BIMI1; l=https://${domain}/logo.svg; a=${domain}/vmc.pem;` : null
                    };
                }
            } catch (err: any) {
                console.log(err)
                company.error = err.message;
            }

            company.checking = false;
            setCompanies([...updatedCompanies]);
        }

        setLoading(false);
        setProcessingStatus('Analysis complete');
    };

    const exportNoBimiCompanies = () => {
        const companiesWithoutBimi = companies.filter((company: any) =>
            !company.bimiStatus?.hasBIMI || company.bimiStatus?.hasBIMI === false
        );

        const csv = [
            ['Company Name', 'Website'].join(','),
            ...companiesWithoutBimi.map((company: any) => [
                `"${company.name}"`,
                company.website || ''
            ].join(','))
        ].join('\n');

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const date = new Date().toISOString().split('T')[0];
        a.download = `companies-without-bimi-${date}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const companiesWithoutBimi = companies.filter((company: any) =>
        !company.bimiStatus?.hasBIMI || company.bimiStatus?.hasBIMI === false
    ).length;

    const companiesWithBimi = companies.filter((company: any) =>
        company.bimiStatus?.hasBIMI
    ).length;

    const bimiAdoptionRate = companies.length > 0
        ? ((companiesWithBimi / companies.length) * 100).toFixed(1)
        : '0.0';
    return (
        <>
            <div className="container mx-auto mt-10 pb-5">
                <h3 className="text-2xl text-black font-bold mb-5"> Company List BIMI Analyzer</h3>
                <form onSubmit={handleFormSubmit}>
                    <input type="file" className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl mb-3 focus:outline-none" required
                        onChange={(e) => setFile(e.target.files![0])}
                        accept=".xlsx, .xls, .csv"
                    />
                    <div className="flex items-center gap-4 mt-5">
                        <button
                            className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 disabled:pointer-events-none"
                        >
                            Analyze
                        </button>

                        {companies.length > 0 && companies.some((c: any) => c.bimiStatus) && (
                            <button
                                onClick={exportNoBimiCompanies}
                                type="button"
                                className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 disabled:pointer-events-none"
                            >
                                Export Without BIMI ({companiesWithoutBimi})
                            </button>
                        )}
                    </div>
                </form>
                {companies.some((c: any) => c.bimiStatus) && (
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-5">
                        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                            <div className="p-4">
                                <div className="text-sm text-[#6B7280]">Total Companies</div>
                                <div className="text-2xl font-bold">{companies.length}</div>
                            </div>
                        </div>
                        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                            <div className="p-4">
                                <div className="text-sm text-[#6B7280]">Without BIMI</div>
                                <div className="text-2xl font-bold text-[#DC2626]">{companiesWithoutBimi}</div>
                            </div>
                        </div>
                        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                            <div className="p-4">
                                <div className="text-sm text-[#6B7280]">With BIMI</div>
                                <div className="text-2xl font-bold text-[#16A34A]">{companiesWithBimi}</div>
                            </div>
                        </div>
                        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                            <div className="p-4">
                                <div className="text-sm text-[#6B7280]">BIMI Adoption</div>
                                <div className="text-2xl font-bold text-[#2563EB]">{bimiAdoptionRate}%</div>
                            </div>
                        </div>
                    </div>
                )}
                {processingStatus && (
                    <div className="flex items-center p-4 mb-4 text-sm text-[#1E40AF] mt-5 rounded-lg bg-[#EFF6FF]" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div>
                            <span className="font-medium">{processingStatus}</span>
                        </div>
                    </div>
                )}

                {error && (
                    <div className="flex items-center p-4 mb-4 text-sm text-[#991B1B] mt-5 rounded-lg bg-[#FEF2F2]" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div>
                            <span className="font-medium">{error}</span>
                        </div>
                    </div>
                )}
                <div className="grid grid-cols-1 gap-4">
                    {companies.map((company: any, index: any) => (
                        <div className={`bg-white p-[25px] rounded-[5px] shadow-2xl ${company.bimiStatus?.hasBIMI ? 'border border-[#BBF7D0]' : ''}`}>
                            <div className="p-4">
                                <div className="flex justify-between items-start">
                                    <div className="flex-1">
                                        <div className="flex items-center gap-2">
                                            <h4 className="font-bold">{company.name}</h4>
                                            {company.checking && <Loader className="w-4 h-4 animate-spin text-[#3B82F6]" />}
                                        </div>
                                        {company.website && (
                                            <div className="flex items-center mt-1 text-[#4B5563]">
                                                <Globe className="w-4 h-4 mr-2" />
                                                <a
                                                    href={company.website}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-[#3B82F6] hover:text-[#2563EB] flex items-center gap-1"
                                                >
                                                    {company.website.replace('https://', '')}
                                                    {company.websiteStatus && <CheckCircle className="w-4 h-4 text-[#22C55E] ml-2" />}
                                                    {company.websiteStatus === false && <XCircle className="w-4 h-4 text-[#EF4444] ml-2" />}
                                                </a>
                                            </div>
                                        )}
                                        {company.bimiStatus && (
                                            <div className="mt-2">
                                                <div className="flex items-center gap-2">
                                                    <span className="text-sm font-medium">BIMI Status:</span>
                                                    {company.bimiStatus.hasBIMI ? (
                                                        <span className="text-[#22C55E] flex items-center gap-1">
                                                            <CheckCircle className="w-4 h-4" /> Found
                                                        </span>
                                                    ) : (
                                                        <span className="text-[#EF4444] flex items-center gap-1">
                                                            <XCircle className="w-4 h-4" /> Not Found
                                                        </span>
                                                    )}
                                                </div>
                                                {company.bimiStatus.record && (
                                                    <div className="mt-1 text-sm font-mono bg-[#F9FAFB] p-2 rounded border">
                                                        {company.bimiStatus.record}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="ml-4 px-3 py-1 bg-[#DBEAFE] text-[#1E40AF] rounded-full text-sm font-medium">
                                        {company.count} TMs
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default Main