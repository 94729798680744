import Modal from "react-modal";
import close_icon from "../../../../assests/icons/close_icon.png";

type TProps = {
    setTranscript: any;
    transcript: any;
}
const Transcript = ({ setTranscript, transcript }: TProps) => {
    const handleClose = () => {
        setTranscript('')
    }
    return (
        <>
            <Modal isOpen={true}>
                <div className="mx-auto rounded-md">
                    <div
                        onClick={handleClose}
                        className="-mt-[0.8rem] float-right w-4 h-4 cursor-pointer"
                    >
                        <img src={close_icon} alt="close" />
                    </div>
                </div>
                <div className="flex items-center justify-between mb-5">
                    <h1 className="text-3xl font-bold my-4 text-[#033d44] justify-center items-center flex">Transcript</h1>
                </div>
                <div>
                    <textarea className="mt-3 rounded-lg resize-none p-3 border focus-visible:outline-none border-green w-full" value={transcript} disabled rows={5}></textarea>
                </div>
            </Modal>
        </>
    )
}
export default Transcript