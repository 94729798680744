import Main from "./components/Main"
import WrapperLayout from 'components/Layout';
const GapFill = ()=>{
    return(
        <>
        <WrapperLayout>
        <Main/>
        </WrapperLayout>
        </>
    )
}
export default GapFill