
import { object, string } from "yup";
export const loginSchema = object({
  email: string().required("Email is Required").email("Invalid Email"),
  password: string().required("Password is Required"),
});
export const contactSchema = object({
  firstName:string().required('First Name is requried'),
  lastName:string().required('Last Name is Requried'),
  email: string().required("Email is Required").email("Invalid Email"),
  phoneNumber:string(),
  note:string()
  
});


export const trademarkSchema = object({
  trademarkLogo: string().required(),
  wordTrademark: string().when(['trademarkLogo'], ({trademarkLogo}:any) => {
    return trademarkLogo === 'Word trademark' ? string().required("Word of TradeMark is Required") : string();
  }),
  fileUpload: string().required('File upload is required').test('is-image', 'File must be an image', (value: any, context: any) => {
    if (!value) return true; // Allow empty value
    const supportedFormats = ['jpg', 'jpeg', 'png', 'gif']; // Add more formats if needed
    const extension = value.substring(value.lastIndexOf('.') + 1).toLowerCase();
    return supportedFormats.includes(extension);
  }),
  service: string().required(),
  webUrl: string().url("Invalid URL").when(['service'], ({service}: any) => {
    return service === 'Services description' ? string().required("Web URL is Required") : string();
  }),
  serviceDesc: string().when(['service'], ({service}: any) => {
    return service === 'Services description' ? string().required("Services description is Required") : string();
  }),
  selectClass: string().when(['service'], ({service}: any) => {
    return service === 'Enter Manually' ? string().required("Select Class is Required") : string();
  }),
  selectClassNote: string().when(['service'], ({service}: any) => {
    return service === 'Enter Manually' ? string().required("Note is Required") : string();
  }),
  trademarkCount: string().when(['service'], ({service}: any) => {
    return service === 'Select Pre-Approved' ? string().required("Trademark Count is Required") : string();
  }),
});


export const form1Schema=object({
  servies:string().required('Servies Descriptions are requried'),
  webUrl:string().url("Invalid URL").required('web url are requried'),
})
export const form2Schema=object({
  selectClass:string(),
  addGoods:string(),
})

export const  textLogoSchema=object({
   textLogo:string().required("text of the logo is requried")
})

