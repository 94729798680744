import { useState } from "react"
import {
    BarChart2, FileText, DollarSign, AlertTriangle,
    TrendingDown, ArrowUpRight, Code, Users, Link,
    Shield, Clock, CheckCircle, XCircle, AlertOctagon,
    ChevronRight, ArrowRight, Network
} from 'lucide-react';

const Main = () => {
    const [riskIndicator, setRiskIndicator] = useState(68);
    const [blockchainStatus, setBlockchainStatus] = useState('verified');
    const [selectedContract, setSelectedContract] = useState(null);
    const licenseData = {
        activeContracts: 24,
        pendingApprovals: 3,
        totalRevenue: 1250000,
        revenueGrowth: 12.5,
        riskScore: riskIndicator,
        upcomingRenewals: [
            { id: 1, licensee: 'Tech Corp', dueDate: '2024-03-15', value: 50000 },
            { id: 2, licensee: 'Global Industries', dueDate: '2024-03-28', value: 75000 }
        ],
        recentTransactions: [
            {
                id: 'tx1',
                type: 'Royalty Payment',
                amount: 25000,
                date: '2024-02-20',
                status: 'completed',
                hash: '0x1234...5678'
            },
            {
                id: 'tx2',
                type: 'Contract Update',
                date: '2024-02-19',
                status: 'pending',
                hash: '0x8765...4321'
            }
        ]
    };
    return (
        <div className="container mx-auto mt-10 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="p-6">
                        <div className="flex justify-between items-start">
                            <div>
                                <p className="text-sm">Active Licenses</p>
                                <p className="text-2xl font-bold">{licenseData.activeContracts}</p>
                            </div>
                            <div className="p-2 bg-[#DCFCE7] rounded-lg text-center">
                                <FileText className="w-5 h-5 text-[#16A34A]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="p-6">
                        <div className="flex justify-between items-start">
                            <div>
                                <p className="text-sm">Revenue YTD</p>
                                <p className="text-2xl font-bold">${(licenseData.totalRevenue / 1000).toFixed(0)}k</p>
                                <p className="text-sm text-[#16A34A] flex items-center">
                                    <ArrowUpRight className="w-4 h-4 mr-1" />
                                    {licenseData.revenueGrowth}%
                                </p>
                            </div>
                            <div className="p-2 bg-[#DBEAFE] rounded-lg text-center">
                                <DollarSign className="w-5 h-5 text-[#2563EB]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="p-6">
                        <div className="flex justify-between items-start">
                            <div>
                                <p className="text-sm">IP Risk Score</p>
                                <p className="text-2xl font-bold">{riskIndicator}/100</p>
                                <p className="text-sm text-[#CA8A04] flex items-center">
                                    <AlertTriangle className="w-4 h-4 mr-1" />
                                    Moderate Risk
                                </p>
                            </div>
                            <div className="p-2 bg-[#FEF9C3] rounded-lg text-center">
                                <Shield className="w-5 h-5 text-[#CA8A04]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="p-6">
                        <div className="flex justify-between items-start">
                            <div>
                                <p className="text-sm">Blockchain Status</p>
                                <p className="text-2xl font-bold">Active</p>
                                <p className="text-sm text-[#059669] flex items-center">
                                    <CheckCircle className="w-4 h-4 mr-1" />
                                    All Synced
                                </p>
                            </div>
                            <div className="p-2 bg-[#D1FAE5] rounded-lg text-center">
                                <Network className="w-5 h-5 text-[#059669]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {riskIndicator > 65 && (
                <div className="bg-[#FEFCE8] border border-[#FEF08A] flex items-center mt-5 p-5">
                    <AlertOctagon className="w-4 h-4 text-[#CA8A04] mr-3" />
                    <div className="text-[#854D0E]">
                        High risk detected in 3 licenses due to market volatility.
                        <button className="ml-2 text-[#CA8A04] hover:text-[#A16207] font-medium">
                            View Analysis
                        </button>
                    </div>
                </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6 mt-5">
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="flex items-center justify-between font-bold">
                        <span>Recent Blockchain Activity</span>
                        <button className="text-sm text-emerald-600 hover:text-emerald-700">
                            View All
                        </button>
                    </div>
                    <div>
                        <div className="space-y-4">
                            {licenseData.recentTransactions.map((tx) => (
                                <div key={tx.id} className="flex items-center justify-between p-3 bg-[#F9FAFB] rounded-lg mt-3">
                                    <div className="flex items-center gap-3">
                                        {tx.status === 'completed' ? (
                                            <CheckCircle className="w-5 h-5 text-[#22C55E]" />
                                        ) : (
                                            <Clock className="w-5 h-5 text-[#EAB308]" />
                                        )}
                                        <div>
                                            <p className="font-medium">{tx.type}</p>
                                            <p className="text-sm text-[#6B7280]">
                                                {tx.hash}
                                            </p>
                                        </div>
                                    </div>
                                    <ChevronRight className="w-5 h-5 text-[#9CA3AF]" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="flex items-center justify-between font-bold">
                        <span>License Value Risk Analysis</span>
                    </div>
                    <div className="space-y-4 mt-3">
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="text-sm text-[#6B7280]">Overall Portfolio Value</p>
                                <p className="text-2xl font-bold">$2.4M</p>
                            </div>
                            <div>
                                <p className="text-sm text-[#6B7280]">Potential Value Loss</p>
                                <p className="text-2xl font-bold text-[#DC2626]">-$180K</p>
                            </div>
                        </div>
                        <div className="space-y-2">
                            {[
                                { name: 'Market Risk', value: 35 },
                                { name: 'Compliance Risk', value: 28 },
                                { name: 'Contract Risk', value: 45 }
                            ].map((risk) => (
                                <div key={risk.name} className="space-y-1">
                                    <div className="flex justify-between text-sm">
                                        <span>{risk.name}</span>
                                        <span>{risk.value}%</span>
                                    </div>
                                    <div className="h-2 bg-[#F3F4F6] rounded-full overflow-hidden">
                                        <div
                                            className={`h-full rounded-full ${risk.value > 40 ? 'bg-[#EF4444]' :
                                                risk.value > 30 ? 'bg-[#EAB308]' : 'bg-[#22C55E]'
                                                }`}
                                            style={{ width: `${risk.value}%` }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="flex items-center justify-between font-bold">
                        <span>Contract Stakeholders</span>
                    </div>
                    <div className="space-y-4 mt-3">
                        {[
                            {
                                role: 'Licensor',
                                name: 'Brand Holdings Ltd',
                                rep: 'Sarah Johnson',
                                status: 'verified'
                            },
                            {
                                role: 'Licensee',
                                name: 'Tech Innovations Inc',
                                rep: 'Michael Chen',
                                status: 'pending'
                            },
                            {
                                role: 'Legal Representative',
                                name: 'Global IP Law',
                                rep: 'David Smith',
                                status: 'verified'
                            }
                        ].map((party) => (
                            <div key={party.role} className="flex items-center justify-between p-3 bg-[#F9FAFB] rounded-lg">
                                <div>
                                    <p className="font-medium">{party.name}</p>
                                    <p className="text-sm text-[#6B7280]">{party.role}</p>
                                    <p className="text-sm text-[#6B7280]">Rep: {party.rep}</p>
                                </div>
                                {party.status === 'verified' ? (
                                    <div className="flex items-center text-[#16A34A]">
                                        <CheckCircle className="w-5 h-5 mr-2" />
                                        Verified
                                    </div>
                                ) : (
                                    <div className="flex items-center text-[#CA8A04]">
                                        <Clock className="w-5 h-5 mr-2" />
                                        Pending
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                    <div className="flex items-center justify-between font-bold">
                        <span>Smart Contract Verification</span>
                    </div>
                    <div className="space-y-4 mt-3">
                        <div className="p-4 bg-[#ECFDF5] border border-[#A7F3D0] rounded-lg">
                            <div className="flex items-center justify-between mb-4">
                                <div className="flex items-center gap-2">
                                    <Shield className="w-5 h-5 text-[#059669]" />
                                    <span className="font-medium text-[#047857]">Contract Verified</span>
                                </div>
                                <Link className="w-5 h-5 text-[#059669]" />
                            </div>
                            <p className="text-sm text-[#059669]">
                                Latest Hash: 0x7d9c...3f4a
                            </p>
                        </div>

                        <div className="space-y-2">
                            <p className="font-medium">Recent Activity</p>
                            {[
                                { event: 'Payment Terms Updated', time: '2 hours ago' },
                                { event: 'Royalty Payment Processed', time: '1 day ago' },
                                { event: 'Territory Extension Approved', time: '3 days ago' }
                            ].map((activity, index) => (
                                <div key={index} className="flex items-center justify-between text-sm">
                                    <span>{activity.event}</span>
                                    <span className="text-[#6B7280]">{activity.time}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Main