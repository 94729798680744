import { useState, useEffect } from "react";
import Modal from "react-modal";
import Table from "components/Table";
import { MOSTFREQUENCYCOLUMN } from "../../constants/index";
import close_icon from "../../assests/icons/close_icon.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { api } from "config";
import Loader from "components/Loader";
import { useTable } from "react-table";
import { formatDate } from "utils/index";
import { useDownloadReport } from "hooks/useDownloadReport";
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
interface IProp {
  data: any;
  openModal: boolean | undefined;
  isClose: () => void;
  name: string
}
const FrequencyTableView = ({ data, openModal, isClose, name }: IProp) => {
  const [showTable, setShowTable] = useState(openModal);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { downloadSpecificTableReport } = useDownloadReport()
  const { filename } = useTrademarkAnalysisData()
  useEffect(() => {
    fetchMoreData()
  }, []);

  const fetchMoreData = () => {
    setLoading(true); 
    api
      .get(`country_representative_info?country_name=${name}&page=${page}&filename=${filename}`)
      .then((res) => {
        const newData = res.data.data;
        const data = newData
          .map((item: any) => ({
            'App. date': item['App. date'] ? item['App. date'] : '-',
            'App. no.': item['App. no.'] ? item['App. no.'] : '-',
            'Classes': item["Classes"] ? item["Classes"] : '-',
            'Country': item["Country"] ? item["Country"] : '-',
            'Exp. date': item["Exp. date"] ? item["Exp. date"] : '-',
            'ID': item['ID'] ? item['ID'] : '-',
            'Owner': item['Owner'] ? item['Owner'] : '-',
            'Reg. date': item['Reg. date'] ? item['Reg. date'] : '-',
            'Reg. no.': item['Reg. no.'] ? item['Reg. no.'] : '-',
            'Representative': item['Representative'] ? item['Representative'] : '-',
            'Status': item['Status'] ? item['Status'] : '-',
            'Trade Mark': item['Trade Mark'] ? item['Trade Mark'] : '-',
            'logoSection': item["Image Path"] ? item["Image Path"] : '-'
          }));
        setItems((prevItems: any) => [...prevItems, ...data]);

        if (newData.length > 0) {
          setPage((prevPage) => prevPage + 1);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.log("Error fetching data:", err);
        setHasMore(false);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after fetching data
      });
  };
  const handleScroll = (e:any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && hasMore) {
      fetchMoreData()
    }
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: MOSTFREQUENCYCOLUMN, data: items });
  return (
      <Modal
        isOpen={openModal || false}
        onRequestClose={() => isClose}
        contentLabel="Chart Data Modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        id='scrollableModal'
        style={{content:{height:'fit-content'}}}
      >
        <div className="mx-auto rounded-md">
          <div
            onClick={() => {
              setShowTable(false);
              isClose();
            }}
            className="-mt-[1rem] float-right w-4 h-4 cursor-pointer"
          >
            <img src={close_icon} alt="close" />
          </div>
          <div className="flex items-center justify-between mb-5">
            <h1 className="text-3xl font-bold my-4 text-[#033d44] justify-center items-center flex">{name}</h1>
            <button className="bg-green text-white cursor-pointer rounded-md hover:bg-green-700 font-bold py-3 px-4"
              onClick={() => downloadSpecificTableReport(`result_country_representatives_${name.toLowerCase()}`, `${name} Country Representatives`)}
            >Download Table Data</button>
          </div>
          <div className="overflow-y-hidden">
            <div id="scrollableDiv" style={{ maxHeight: "700px", overflow: loading ? 'hidden':"auto" }} onScroll={handleScroll}>
                <table
                  className="w-full text-sm text-left border-main bg-green "
                  {...getTableProps()}
                >
                  <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider"
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main"
                        >
                          {row.cells.map((cell) => (
                            <td
                              {...cell.getCellProps()}
                              className="px-6 py-4 text-black border border-main"
                            >
                              {
                                 cell.column.id === 'logoSection' ? cell.value && cell.value !=='null'? <img src={process.env.REACT_APP_URL+cell.value} className="h-12 w-12"/>:"-" :
                                cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
            </div>
            {loading ? <div className="flex justify-center items-center my-2"><Loader /></div> : null}
          </div>
        </div>
      </Modal>
  );
};

export default FrequencyTableView;
