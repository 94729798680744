import { RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
const ValuationReports = ({ companyProfile, analysisResults }: any) => {
    return (
        <>
            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                <span className='font-bold text-xl'>Valuation Summary</span>
                <p className='mb-3 font-medium'>AI-powered analysis results for {companyProfile.name}</p>
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
                        <div className="p-4 bg-[#EFF6FF] rounded-lg">
                            <h3 className="font-semibold">Estimated Valuation Range</h3>
                            <p className="text-2xl font-bold text-[#1D4ED8]">
                                £{analysisResults.valuationRange.min.toLocaleString()} -
                                £{analysisResults.valuationRange.max.toLocaleString()}
                            </p>
                        </div>

                        <div className="p-4 bg-[#F0FDF4] rounded-lg">
                            <h3 className="font-semibold">Overall Portfolio Score</h3>
                            <p className="text-2xl font-bold text-[#15803D]">
                                {analysisResults.overallScore}/100
                            </p>
                        </div>

                        <div className="p-4 bg-[#FAF5FF] rounded-lg">
                            <h3 className="font-semibold">Risk Assessment</h3>
                            <p className="text-2xl font-bold text-[#7E22CE]">
                                {analysisResults.riskScore}/100
                            </p>
                        </div>
                    </div>
                    <div className="mb-6">
                        <h3 className="font-semibold mb-3">Performance Metrics</h3>
                        <RadarChart width={500} height={300} data={[
                            {
                                metric: 'Portfolio Strength',
                                value: analysisResults.portfolioStrength
                            },
                            {
                                metric: 'Market Position',
                                value: analysisResults.marketScore
                            },
                            {
                                metric: 'Growth Potential',
                                value: analysisResults.growthScore
                            },
                            {
                                metric: 'Risk Management',
                                value: analysisResults.riskScore
                            }
                        ]}>
                            <PolarGrid />
                            <PolarAngleAxis dataKey="metric" />
                            <PolarRadiusAxis angle={30} domain={[0, 100]} />
                            <Radar name="Metrics" dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                        </RadarChart>
                    </div>
                    <div className="mb-6">
                        <h3 className="font-semibold mb-3">Key Recommendations</h3>
                        <ul className="space-y-2">
                            {analysisResults.recommendations.map((rec: any, index: any) => (
                                <li key={index} className="flex items-start gap-2">
                                    <span className="text-[#3B82F6]">•</span>
                                    {rec}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl mt-5">
                <span className='font-bold text-xl'>Detailed Analysis</span>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <h3 className="font-semibold mb-3">Portfolio Strengths</h3>
                        <ul className="space-y-2">
                            <li>Strong coverage in primary markets</li>
                            <li>Well-maintained core trade marks</li>
                            <li>Effective classification strategy</li>
                            <li>Good defensive registrations</li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="font-semibold mb-3">Areas for Improvement</h3>
                        <ul className="space-y-2">
                            <li>Gap in emerging market coverage</li>
                            <li>Some marks approaching renewal</li>
                            <li>Limited protection in secondary classes</li>
                            <li>Potential for strategic filing program</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ValuationReports