import Main from "./Components/Main"
import WrapperLayout from 'components/Layout';
const ConflictCheck = ()=>{
    return(
        <>
        <WrapperLayout>
        <Main/>
        </WrapperLayout>
        </>
    )
}
export default ConflictCheck