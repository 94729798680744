import { useState } from 'react';
import { CheckCircle, XCircle, Search, Mail, ArrowRight, Eye, FileText, PlaneLanding } from 'lucide-react';
const Main = () => {
    const [domain, setDomain] = useState('');
    const [analyzed, setAnalyzed] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState<any>(null);
    const [selectedVMC, setSelectedVMC] = useState<any>(null);
    const [email, setEmail] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [showContactSearch, setShowContactSearch] = useState(false);
    const [showEmailPreview, setShowEmailPreview] = useState(false);
    const [contacts, setContacts] = useState<any>([]);
    const [showSentConfirmation, setShowSentConfirmation] = useState(false);
    const [followUpDate, setFollowUpDate] = useState('');
    const [dealStage, setDealStage] = useState('awareness');
    const mockContacts = [
        { id: 1, email: 'john.doe@example.com', company: 'Tech Corp' },
        { id: 2, email: 'sarah.smith@example.com', company: 'Digital Solutions' },
        { id: 3, email: 'mike.jones@example.com', company: 'Innovation Inc' }
    ];

    const vmcMaterials = [
        { id: 1, name: 'Enterprise Security Package', description: 'Complete BIMI implementation guide for enterprise' },
        { id: 2, name: 'SMB Starter Kit', description: 'Basic BIMI setup for small businesses' },
        { id: 3, name: 'Digital Class 09 Companies', description: 'Specialized BIMI package for digital companies' },
        { id: 4, name: 'Gaming/Casino Bundle', description: 'BIMI implementation for gaming industry' }
    ];

    const emailTemplate = `Subject: Win the Inbox Battle with a Verified Mark Certificate

Dear [Contact Name],

With over 293 billion emails circulating every day, establishing trust and recognition has become more essential than ever. This is where a Verified Mark Certificate (VMC) comes into play.

What is a VMC Certificate?
A VMC is a digital certificate that allows your company's logo to appear directly in email inboxes alongside a blue verified Google checkmark, providing a more secure, authentic, engaging, and easily recognisable email presence. This feature is supported by major email platforms like Gmail, Yahoo, and Apple Mail.

Why Do You Need a VMC?
* Increase brand visibility: Your logo appears next to your emails, helping you stand out from the crowd and improve recognition before customers even open them.
* Improve email deliverability: Thanks to the enhanced email security management, deliverability increases by 5-10% and prevents emails from being flagged as SPAM.
* Better open rates: Customers feel more confident engaging with verified communications and open rates can increase by up to 39%!
* Enhance customer trust: The verified checkmark boosts credibility, showing customers your emails are authentic.
* Prevent phishing and spoofing protection: VMC helps prevent fraud by verifying that emails are truly coming from your domain.
* Build confidence in authenticity: Customers can easily identify legitimate emails, helping them avoid phishing attempts.

Learn more about VMC
[Service Page Link]

How Rule Enhanced Their Email Trust with VMC
Read Customer Case
[Rule Case Link]

Secure your Email
Discover our Email Compromise Protection Plan

If you'd like to explore how VMC can boost your brand visibility, email security and deliverability, we'd be happy to walk you through the process and requirements.

Feel free to reach out with any questions or to schedule a quick call.
Contact us at [Phone Number] or [Email Address] to learn more.

Best regards,
[Your Name]`;
    const analyzeDomain = () => {
        setTimeout(() => {
            setAnalyzed(true);
            setVerificationStatus(Math.random() > 0.3);
        }, 1000);
    };
    const getFollowUpDates = () => {
        const dates = [];
        const today = new Date();
        for (let i = 3; i <= 14; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            dates.push(date.toISOString().split('T')[0]);
        }
        return dates;
    };

    const dealStages: any = {
        awareness: 'Awareness',
        consideration: 'Consideration',
        evaluation: 'Technical Evaluation',
        negotiation: 'Negotiation',
        implementation: 'Implementation'
    };
    const searchContacts = (query: string) => {
        const filtered = mockContacts.filter(contact =>
            contact.email.toLowerCase().includes(query.toLowerCase()) ||
            contact.company.toLowerCase().includes(query.toLowerCase())
        );
        setContacts(filtered);
    };

    const handleSend = () => {
        setShowSentConfirmation(true);
    };
    const handleClose = () => {
        setShowSentConfirmation(false);
        setEmail('');
        setSelectedVMC(null);
        setFollowUpDate('');
        setShowEmailPreview(false);
    };
    return (
        <div className="container mx-auto mt-10 pb-5">
            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                <span className="text-2xl font-bold">BIMI Analyzer & VMC Distribution</span>
                <div className="space-y-6 mt-5">
                    <div className="space-y-4">
                        <div className="flex gap-4">
                            <input
                                placeholder="Enter domain to analyze"
                                value={domain}
                                onChange={(e) => setDomain(e.target.value)}
                                className="w-full border-4 border-[#033d44] p-2.5 text-[#000000] bg-[#fbfbfb] rounded-2xl focus:outline-none flex-1" />
                            <button className="flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg" onClick={analyzeDomain} disabled={!domain}>Analyze</button>
                        </div>
                        {analyzed && (
                            <div className={`border flex items-center mt-5 p-5 ${verificationStatus ? 'bg-[#F0FDF4] border-[#22C55E]' : 'bg-[#FEF2F2] border-[#EF4444]'}`}>
                                <div className="flex items-center gap-2">
                                    {verificationStatus ? (
                                        <>
                                            <CheckCircle className="h-5 w-5 text-[#22C55E] mr-3" />
                                            <span className='text-[#22C55E]'> BIMI verification successful! Channel is now open.</span>
                                        </>
                                    ) : (
                                        <>
                                            <XCircle className="h-5 w-5 text-[#EF4444] mr-3" />
                                            <span className='text-[#EF4444]'>BIMI verification failed. Please check requirements.</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {analyzed && verificationStatus && (
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Select VMC Material</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {vmcMaterials.map((material) => (
                                    <button
                                        key={material.id}
                                        className={`w-full flex items-center gap-2 px-4 py-2 bg-green rounded-lg ${selectedVMC === material.id ? 'bg-green text-white' : 'bg-white border text-[#000000]'}`}
                                        onClick={() => setSelectedVMC(material.id)}
                                    >
                                        <span className="font-semibold">{material.name}</span>
                                        <span className="text-sm text-[#6B7280]">{material.description}</span>
                                    </button>
                                ))}
                            </div>

                            {selectedVMC && (
                                <div className="space-y-4 pt-4">
                                    <div className="flex gap-4">
                                        <button
                                            onClick={() => setShowContactSearch(!showContactSearch)}
                                            className="w-full flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg"
                                        >
                                            <Search className="h-4 w-4" />
                                            Search Contacts
                                        </button>
                                        <button
                                            onClick={() => setShowEmailPreview(!showEmailPreview)}
                                            className="w-full flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg"
                                        >
                                            <Eye className="h-4 w-4" />
                                            Preview Email
                                        </button>

                                    </div>
                                    <div className="flex-1 flex gap-2">
                                        <input
                                            placeholder="Or enter email directly"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="w-full border-4 border-[#033d44] p-2.5 text-[#000000] bg-[#fbfbfb] rounded-2xl focus:outline-none" />
                                        {/* <select
                                            value={followUpDate}
                                            onChange={(e) => setFollowUpDate(e.target.value)}
                                            className="px-3 py-2 border rounded-md"
                                        >
                                            <option value="">Select follow-up date</option>
                                            {getFollowUpDates().map(date => (
                                                <option key={date} value={date}>{date}</option>
                                            ))}
                                        </select> */}
                                        <button
                                            onClick={handleSend}
                                            disabled={!email}
                                            className="flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg"
                                        >
                                            <Mail className="h-4 w-4" />
                                            Send
                                        </button>
                                    </div>
                                    {showContactSearch && (
                                        <div className="space-y-4">
                                            <input
                                                placeholder="Search HubSpot contacts..."
                                                value={searchQuery}
                                                onChange={(e) => {
                                                    setSearchQuery(e.target.value);
                                                    searchContacts(e.target.value);
                                                }}
                                                className="w-full border-4 border-[#033d44] p-2.5 text-[#000000] bg-[#fbfbfb] rounded-2xl focus:outline-none" />
                                            <div className="space-y-2">
                                                {contacts.map((contact: any) => (
                                                    <div
                                                        key={contact.id}
                                                        className="flex items-center justify-between p-3 border rounded hover:bg-[#F9FAFB] cursor-pointer"
                                                        onClick={() => {
                                                            setEmail(contact.email);
                                                            setShowContactSearch(false);
                                                        }}
                                                    >
                                                        <div>
                                                            <div className="font-medium">{contact.email}</div>
                                                            <div className="text-sm text-[#6B7280]">{contact.company}</div>
                                                        </div>
                                                        <ArrowRight className="h-4 w-4 text-[#9CA3AF]" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {showEmailPreview && (
                                        <div className="mt-4 p-4 border rounded-lg bg-white">
                                            <h4 className="font-semibold mb-2">Email Preview</h4>
                                            <pre className="whitespace-pre-wrap text-sm font-mono bg-[#F9FAFB] p-4 rounded">
                                                {emailTemplate}
                                            </pre>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showSentConfirmation && (
                <div className="fixed inset-0 bg-[#000] bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                        <div className="text-center space-y-6">
                            <div className="flex justify-center">
                                <PlaneLanding className="h-20 w-20 text-[#3B82F6] animate-bounce" />
                            </div>
                            <h3 className="text-2xl font-bold text-[#2563EB]">Campaign Launched Successfully!</h3>
                            <div className="grid grid-cols-2 gap-4 text-left">
                                <div className="space-y-3">
                                    <h4 className="font-semibold flex items-center gap-2">
                                        <Mail className="h-4 w-4 text-[#22C55E]" />
                                        Email Sequence
                                    </h4>
                                    <ul className="text-sm space-y-2 text-[#4B5563]">
                                        <li>✓ Initial VMC introduction sent</li>
                                        <li>✓ Follow-up scheduled for {followUpDate}</li>
                                        <li>✓ Technical review scheduled</li>
                                    </ul>
                                </div>
                                <div className="space-y-3">
                                    <h4 className="font-semibold flex items-center gap-2">
                                        <FileText className="h-4 w-4 text-[#3B82F6]" />
                                        HubSpot Updates
                                    </h4>
                                    <ul className="text-sm space-y-2 text-[#4B5563]">
                                        <li>✓ Contact tagged & updated</li>
                                        <li>✓ Deal stage set to {dealStages[dealStage]}</li>
                                        <li>✓ Technical checklist created</li>
                                    </ul>
                                </div>
                            </div>
                            <button className="flex justify-center items-center gap-2 px-4 py-2 bg-green text-white rounded-lg w-full"
                                onClick={handleClose}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default Main