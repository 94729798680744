import { useState } from "react"
import { toast } from "react-toastify";
import { useDomainAnalysis } from "hooks/useDomainAnalysis";
const PunnyCode = () => {
    const { getPunnyCode, downloadPunnyCodeReport } = useDomainAnalysis()
    const [domainsData, setDomainsData] = useState("")
    const [domains, setDomains] = useState<string>("")
    const [variations, setVariations] = useState<any[]>([])
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const domains = domainsData
        setDomains(domains)
        const data = await getPunnyCode(domains)
        setVariations([...data])
    }
    const findTotalDomains = (domains:any) =>{
        return domains.map((item:any)=>{
            if(item.no_of_permutations) return item.no_of_permutations
        })
    }
    return (
        <div className="container mx-auto mt-10 ">
        <h3 className="text-2xl text-black font-bold mb-5">Punny Code</h3>
            {variations?.length ?  <h2 className="font-bold text-2xl text-black mb-3 text-center">Total no. of Domains: {findTotalDomains(variations)}</h2>: null}
            <form onSubmit={handleFormSubmit}>
                <div className="block sm:flex justify-between">
                    <textarea
                        id="input"
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Domain (separated by line breaks)"
                        required
                        value={domainsData}
                        onChange={(e) => setDomainsData(e.target.value)}
                    ></textarea>
                    <div
                        id="punnyCode"
                        className="h-[268px] overflow-y-auto mt-3 sm:mt-0 focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                    >
                        {variations.map((variation, index) => (
                            <p key={index}>{variation.punycode}</p>
                        ))}
                    </div>
                </div>
                <div className="flex mt-8 justify-center">
                    <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Submit</button>
                    <button onClick={() => downloadPunnyCodeReport()} type="button" disabled={!variations?.length} className="hover:bg-opacity-50 text-[#dcdcdc] bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 ml-3 disabled:opacity-50 disabled:pointer-events-none">Download All Domains</button>
                </div>
            </form>
        </div>

    )
}
export default PunnyCode