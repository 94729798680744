import WrapperLayout from 'components/Layout';
import Main from "./Components/Main"
const Licensing = () =>{
    return(
        <>
        <WrapperLayout>
            <Main/>
        </WrapperLayout>
    </>
    )
}
export default Licensing