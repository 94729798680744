import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToggleSwitch from 'components/Switch';
import { COUNTRIES } from './../../constants';
import "./module.css"
import TradeMarkForm from './TradeMarkForm';
import { ContactAccordion } from './components/Contact';
import { ContactSchema, TradeMarkSchema } from 'types';
import { TradeMarkSummary } from './components/Summary';
import left from './../../assests/icons/prev.png'

interface AddTrademark {
  trademark?: TradeMarkSchema;
  countries?: { country: string; price: string }[];
  contact?: ContactSchema;
}

function Form2() {
  const formArray = [1, 2, 3];
  const [formNo, setFormNo] = useState(formArray[0]);
  const [trademark, setTrademark] = useState<TradeMarkSchema>();
  const [contact, setContact] = useState<ContactSchema>();
  const [addTradeMark, setAddTrademark] = useState<AddTrademark>();

  const [previousTrademark, setPreviousTrademark] = useState<TradeMarkSchema>(); // State to store previous trademark form values
  const [previousContact, setPreviousContact] = useState<ContactSchema>(); // State to store previous contact form values
  const [previousCountry, setPreviousCountry] = useState<{ country: string; price: string }[]>(); // State to store previous contact form values

  const [selectedCountries, setSelectedCountries] = useState<{ country: string; price: string }[]>([]);

  useEffect(() => {
    if (contact) {
      setAddTrademark({
        ...trademark,
        countries: selectedCountries,
        contact: contact
      });
    }
  }, [addTradeMark]);

  const finalSubmit = () => {
    if (trademark && selectedCountries.length > 0) {
      setAddTrademark({
        ...trademark,
        countries: selectedCountries,
      });
      // You may perform additional actions here if needed
      toast.success('Form submitted successfully');
    } else {
      toast.error('Please fill up all input fields');
    }
  };

  const handleTradeMarkFormSubmit = (formValues: TradeMarkSchema) => {
    setTrademark(formValues);
    setPreviousTrademark(formValues); // Store current form values for later use
    if (formValues) { next(); }
  };

  const handleContactFormSubmit = (formValues: ContactSchema) => {
    setContact(formValues);
    setPreviousContact(formValues); // Store current form values for later use
    if (formValues) { next(); }
    finalSubmit();
  };

  // Previous button click handler
  const handlePrevButtonClick = () => {
    if (formNo === 2 && previousTrademark) {
      setTrademark(previousTrademark);
    } else if (formNo === 3 && previousCountry) {
      setSelectedCountries(previousCountry)
    }
    else if (formNo === 3 && previousContact) {
      setContact(previousContact)
    }

    pre(); // Navigate to previous step
  };

  const next = () => {
    if (formNo < formArray.length) {
      setFormNo(formNo + 1);
    }
  }

  const pre = () => {
    if (formNo > 1) {
      setFormNo(formNo - 1);
    }
  }

  const [showTable, setShowTable] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState('');

  const handleSearch = () => {
    if (searchValue.trim() === '') {
      setError('Search input cannot be empty');
    } else {
      setShowTable(true)
      setError(''); // Clear any previous error messages
    }
  };
  useEffect(() => {
    const storedCountries = JSON.parse(localStorage.getItem('selectedCountries') || '[]');
    if (storedCountries.length > 0) {
      setSelectedCountries(storedCountries);
    }
  }, []);
  const handleToggle = (country: string, price: string) => {
    const index = selectedCountries.findIndex((selected) => selected.country === country);
    let newSelectedCountries: { country: string; price: string }[];
    if (index !== -1) {
      newSelectedCountries = [...selectedCountries];
      newSelectedCountries[index] = { country, price };
    } else {
      newSelectedCountries = [{ country, price }];
    }
    setPreviousCountry(newSelectedCountries)
    setSelectedCountries(newSelectedCountries);
  };


  const handleCountriesFormSubmit = () => {

    if (selectedCountries.length > 0) { next() }
    else {
      toast.error('Please fill up all input fields');
    }
  };

  return (
    <section className="flex-row lg:flex md:flex-row xl:flex-row gap-y-5  sm:flex-col sm:p-8 px-1 py-4 overflow-auto max-h-screen w-full bg-[#FBFBFB] justify-between gap-x-5 mx-auto ">
      <ToastContainer />
      <div className="">
        <img src={left} className='w-5 h-5 ' onClick={handlePrevButtonClick} />
        <div className='flex justify-center items-center -mt-2'>
          {formArray.map((v, i) => (
            <React.Fragment key={i}>
              <div className={`w-[35px] py-3 -mt-4 text-white rounded-full ${formNo >= i + 1 ? 'bg-green' : 'bg-slate-400'} h-[35px] flex justify-center items-center`}>
                {v}
              </div>
              {i !== formArray.length - 1 && (
                <div className={`w-[100px] h-[2px] -mt-4 ${formNo === i + 1 ? 'bg-green' : 'bg-slate-400'}`}></div>
              )}
            </React.Fragment>
          ))}
        </div>
        {formNo === 1 &&
          <div className=' rounded-md  pl-12 '>
            <h2 className="text-4xl mobile:text-3xl font-semibold mb-2 py-4 cursor-pointer text-primary text-center justify-center flex">Trademark</h2>
            <div className="mt-6 ">
              <h2 className="text-3xl mobile:text-xl pr-4 font-bold mb-2 cursor-pointer text-primary text-center justify-center flex">Which option best describes your trademark?</h2>
              <TradeMarkForm onSubmit={handleTradeMarkFormSubmit} previousValues={previousTrademark} />
            </div>
          </div>
        }
        {formNo === 2 &&
          <div className='max-w-[640px]  py-4 justify-center flex-col' >
            <h2 className="text-4xl py-4 mobile:text-3xl font-semibold mb-2 cursor-pointer text-primary text-center justify-center flex">Countries</h2>
            <div>
              <h2 className="text-2xl font-semibold mb-2 mt-5 cursor-pointer text-primary text-center justify-center flex">Where are you going to use this trademark?</h2>
              <div className='w-full overflow-y-auto max-h-[700px]  py-4'>
                <div className="flex flex-wrap  gap-x-3 justify-center overflow-y-auto max-h-full items-center">
                  {COUNTRIES.map((item) => (
                    <ToggleSwitch
                      key={item.country}
                      country={item.country}
                      price={item.price}
                      flag={item.flag}
                      onToggle={handleToggle}
                      select={selectedCountries[0]?.country}

                    />
                  ))}
                </div>

              </div>
              <button className="w-full bg-[#033d44] rounded-md justify-center items-center flex py-4   px-4 text-white mb-4 max-w-[400px] mx-auto" type="submit" onClick={handleCountriesFormSubmit}>Continue</button>
            </div>

          </div>
        }
        {formNo === 3 &&
          <div className='pr-10 py-4 pt-8 rounded-md shadow- bg-white pl-12'>
            <h2 className="text-4xl mobile:text-3xl font-semibold cursor-pointer text-primary text-center justify-center flex">Contact</h2>
            <ContactAccordion onSubmit={handleContactFormSubmit} previousValues={previousContact} />
          </div>
        }


      </div>
      <div className='py-6 mt-6 rounded-md shadow-lg bg-white max-h-[500px] px-10 min-w-[350px]'>
        <TradeMarkSummary trademark={trademark?.trademarkLogo} country={selectedCountries} />
      </div>

    </section>
  );
}

export default Form2;

