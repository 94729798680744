import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IProps {
    title: string;
    chartData: any;
    className?: string;
    count?: number;
    sign?: string;
    sendSliceData: (data: string) => void;
}

export function DoughnutChart({ title, chartData, sign = "", sendSliceData }: IProps) {
    const options: any = {
        plugins: {
            legend: {
                labels: {
                    color: 'black', // Set the color of legend labels to black
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            tooltip: {
                callbacks: {
                    label: (ttItem: any) => `${ttItem.label}: ${sign ? `${parseFloat(ttItem.parsed).toFixed(2)} ${sign}` : ttItem.parsed}`,
                },
            },
        },
        onClick: (evt: any, item: any) => {
            sendSliceData(chartData.labels[item[0].index]);
        },
    };

    const textCenter: any = {
        id: 'textCenter',
    };


    return (
        <div className='bg-[#ECECEC] rounded-md mt-4 p-4 border border-[#DCDCDC]'>
            <h1 className='font-bold text-xl text-center text-black'>{title}</h1>
            <div className='text-center m-auto p-[20px] text-black'>
                <Doughnut data={chartData} options={options} plugins={[textCenter]}  />
            </div>
        </div>
    );
}

