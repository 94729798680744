import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { dummyData } from 'utils/dummyData'
import TableRows from './components/TableRows'
import VideoReply from './components/VideoReply'
import Transcript from './components/Transcript'
import VideoStory from './components/VideoStory'
const Schedule = () => {
    const [showVideoStory, setShowVideoStory] = useState(false)
    const [showVideoModal, setShowVideoModal] = useState(false)
    const [caseRef, setCaseRef] = useState('')
    const [officialNo, setOfficialNo] = useState('')
    const [summary, setSummary] = useState('')
    const [transcript, setTranscript] = useState('')
    const [title, setTitle] = useState('')
    const [videoLink, setVideoLink] = useState('')
    return (
        <div className="mt-[5rem] container mx-auto">
            <div className='overflow-x-auto'>
                <table className="text-xs text-left border-main mb-5">
                    <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                        <tr>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Budget Tracker
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Case Ref.
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Country
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Official No.
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Title
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Local Classes
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Instructor
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Registered Owner
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Status
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                IPREELS
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Client Review
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Transcript
                            </th>
                            <th className="px-2 py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-center">
                                Summary
                            </th>
                            <th className="px-2 text-center py-4 border border-main bg-green  text-white font-bold text-xs uppercase tracking-wider text-[22px]"><FontAwesomeIcon icon={faCartShopping} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dummyData.map((item, index) => (
                            <TableRows data={item} key={index} setShowVideoStory={setShowVideoStory} setTitle={setTitle} setVideoLink={setVideoLink}
                                setCaseRef={setCaseRef} setSummary={setSummary} setTranscript={setTranscript} setShowVideoModal={setShowVideoModal}
                                setOfficialNo={setOfficialNo}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            {showVideoModal ?
                <VideoReply setShowVideoModal={setShowVideoModal} title={title} caseRef={caseRef} officialNo={officialNo} summary={summary} />
                : null}
            {!!transcript ? <Transcript transcript={transcript} setTranscript={setTranscript} /> : null}
            {showVideoStory ? <VideoStory setShowVideoStory={setShowVideoStory} title={title} videoLink={videoLink}/> : null}
        </div>
    )
}

export default Schedule