import { createSlice } from "@reduxjs/toolkit";
import { TDATABASEMANAGEMENTSLICE, TTRADEMARKRECORD } from "./type";
const initialState: TDATABASEMANAGEMENTSLICE = {
    CountryRecords: null,
    showResult:false
}
const CountryRepresentative = createSlice({
    name: "CountryAnalysis",
    initialState,
    reducers: {
        setCountryisAction: (state, action: { payload: TTRADEMARKRECORD}) => {
            return {
                ...state,
                CountryRecords: action.payload,
                showResult:true
            };
        },
    }
})
export const { setCountryisAction } =CountryRepresentative.actions;

export default CountryRepresentative.reducer;