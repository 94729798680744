import { useState } from "react"
import MetricCard from "../MetricCard";
import InputField from "../InputField";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import { DollarSign, Activity, TrendingUp, AlertTriangle } from 'lucide-react';
const Main = () => {
    const [activeTab, setActiveTab] = useState('company');
    const [results, setResults] = useState<any>(null);

    const [companyProfile, setCompanyProfile] = useState({
        name: 'TechBrand Solutions',
        industry: 'Technology',
        region: 'Europe',
        portfolioSize: 150,
        yearEstablished: 2000,
        primaryMarkets: 'UK, Germany, France',
        secondaryMarkets: 'Spain, Italy, Netherlands'
    });

    const [portfolioMetrics, setPortfolioMetrics] = useState<any>({
        totalUniqueOwners: 25,
        countryRepresentatives: 15,
        frequentMarkInstances: 35,
        litigationPercentage: 12,
        missingMarks: 8,
        missingClasses: 5,
        nonUsePercentage: 15,
        expiringMarks: 10,
        keyMarketsCoverage: 85,
        secondaryMarketsCoverage: 70,
        missingProductTerms: 5,
        missingProductTermsCountry: 8
    });

    const [valuationInputs, setValuationInputs] = useState({
        baselineRevenue: 1000000,
        salesYear1: 100000,
        corporateTaxRate: 25,
        royaltyRateLoss: 15,
        ipRiskLoss: 20,
        legalRiskLoss: 10,
        royaltyRate: 10,
        discountRate: 10,
        growthRateForecast: 20,
        growthRateResidual: 2,
        marketGrowthRate: 5,
        baseMarketShare: 10,
        projectedMarketShare: 12,
        competitiveRiskPremium: 2,
        economicRiskPremium: 1,
        consumerDemandFactor: 95,
        regulatoryRiskPremium: 0.5,
        historicalIpRiskLoss: 15,
        ipRiskFrequency: 20,
        averageLegalCosts: 100000,
        historicalGrowthRate: 8
    });

    const handleInputChange = (section: any, field: any, value: any) => {
        switch (section) {
            case 'company':
                setCompanyProfile(prev => ({ ...prev, [field]: value }));
                break;
            case 'portfolio':
                setPortfolioMetrics((prev: any) => ({ ...prev, [field]: parseFloat(value) || 0 }));
                break;
            case 'valuation':
                setValuationInputs(prev => ({ ...prev, [field]: parseFloat(value) || 0 }));
                break;
        }
    };

    const calculateValuation = () => {
        // Simulate calculation using the formulas from the Python backend
        const ipRiskScore = (portfolioMetrics.missingMarks / portfolioMetrics.portfolioSize) * 100;
        const marketScore = (valuationInputs.baseMarketShare / valuationInputs.projectedMarketShare) * 100;
        const growthScore = (valuationInputs.growthRateForecast / valuationInputs.historicalGrowthRate) * 100;

        const baseValue = valuationInputs.baselineRevenue *
            (1 - valuationInputs.ipRiskLoss / 100) *
            (1 - valuationInputs.legalRiskLoss / 100);

        setResults({
            companyName: companyProfile.name,
            valuationRange: {
                min: baseValue * 0.9,
                max: baseValue * 1.1,
                base: baseValue
            },
            scores: {
                overall: (ipRiskScore + marketScore + growthScore) / 3,
                risk: ipRiskScore,
                market: marketScore,
                growth: growthScore
            },
            portfolioStrength: portfolioMetrics.keyMarketsCoverage,
            recommendations: [
                "Register additional trade marks in emerging markets",
                "Review and renew expiring marks",
                "Strengthen protection in key markets",
                "Consider defensive registrations"
            ]
        });

        setActiveTab('results');
    };
    return (
        <div className="container mx-auto mt-10 pb-5">
            <header className="mb-8">
                <h1 className="text-3xl font-bold">Valuation Test Environment</h1>
                <p className="text-[#4B5563]">Test different scenarios and view results</p>
            </header>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select your country</label>
                <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary"
                    onChange={(e) => setActiveTab(e.target.value)}
                >
                    <option value="company">Company</option>
                    <option value="portfolio">Portfolio</option>
                    <option value="valuation">Valuation</option>
                    <option value="results">Results</option>
                </select>
            </div>
            <ul className="hidden text-sm font-medium text-center text-[#6B7280] rounded-lg shadow sm:flex">
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('company')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-s-lg focus:outline-none ${activeTab === 'company' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Company</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('portfolio')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'portfolio' ? 'active bg-[#F3F4F6]' : 'bg-white'}`}>Portfolio</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('valuation')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] focus:outline-none ${activeTab === 'valuation' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Valuation</a>
                </li>
                <li className="w-full focus-within:z-10" onClick={() => setActiveTab('results')}>
                    <a href="#" className={`inline-block font-bold w-full p-4 text-[#111827] border-r border-[#E5E7EB] rounded-e-lg focus:outline-none ${activeTab === 'results' ? 'active bg-[#F3F4F6] ' : 'bg-white'}`}>Results</a>
                </li>
            </ul>
            <div className='mt-5'>
                {activeTab === 'company' ?
                    <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                        <span className="text-xl font-bold">Company Profile</span>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-5">
                            {Object.entries(companyProfile).map(([key, value]) => (
                                <InputField
                                    key={key}
                                    label={key.replace(/([A-Z])/g, ' $1').trim()}
                                    value={value}
                                    onChange={(val: any) => handleInputChange('company', key, val)}
                                    type={typeof value === 'number' ? 'number' : 'text'}
                                />
                            ))}
                        </div>
                    </div>
                    : activeTab === 'portfolio' ?
                        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                            <span className="text-xl font-bold">Portfolio Metrics</span>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-5">
                                {Object.entries(portfolioMetrics).map(([key, value]) => (
                                    <InputField
                                        key={key}
                                        label={key.replace(/([A-Z])/g, ' $1').trim()}
                                        value={value}
                                        onChange={(val: any) => handleInputChange('portfolio', key, val)}
                                    />
                                ))}
                            </div>
                        </div>
                        : activeTab === 'valuation' ?
                            <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                                <span className="text-xl font-bold">Valuation Inputs</span>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-5">
                                    {Object.entries(valuationInputs).map(([key, value]) => (
                                        <InputField
                                            key={key}
                                            label={key.replace(/([A-Z])/g, ' $1').trim()}
                                            value={value}
                                            onChange={(val: any) => handleInputChange('valuation', key, val)}
                                        />
                                    ))}
                                </div>
                            </div>
                            : <>
                                {results ? (
                                    <div className="space-y-6">
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                            <MetricCard
                                                title="Valuation Range"
                                                value={`£${results.valuationRange.min.toLocaleString()} - £${results.valuationRange.max.toLocaleString()}`}
                                                icon={DollarSign}
                                                subtitle="Estimated value range"
                                            />
                                            <MetricCard
                                                title="Overall Score"
                                                value={`${results.scores.overall.toFixed(1)}/100`}
                                                icon={Activity}
                                                subtitle="Combined performance"
                                            />
                                            <MetricCard
                                                title="Growth Score"
                                                value={`${results.scores.growth.toFixed(1)}/100`}
                                                icon={TrendingUp}
                                                subtitle="Growth potential"
                                            />
                                            <MetricCard
                                                title="Risk Score"
                                                value={`${results.scores.risk.toFixed(1)}/100`}
                                                icon={AlertTriangle}
                                                subtitle="Risk assessment"
                                            />
                                        </div>
                                        <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                                            <span className="text-xl font-bold">Performance Analysis</span>
                                            <div className="h-[300px]">
                                                <RadarChart width={500} height={300} data={[
                                                    { subject: 'Risk', value: results.scores.risk },
                                                    { subject: 'Market', value: results.scores.market },
                                                    { subject: 'Growth', value: results.scores.growth },
                                                    { subject: 'Portfolio', value: results.portfolioStrength }
                                                ]}>
                                                    <PolarGrid />
                                                    <PolarAngleAxis dataKey="subject" />
                                                    <PolarRadiusAxis angle={30} domain={[0, 100]} />
                                                    <Radar name="Performance" dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                                                </RadarChart>
                                            </div>
                                        </div>
                                    </div>
                                )
                                    :
                                    <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
                                        <div className="text-center py-8">
                                            <p className="text-[#4B5563]">Run the calculation to see results</p>
                                        </div>
                                    </div>
                                }
                            </>
                }
            </div>
            <div className="mt-6">
                <button
                    onClick={calculateValuation}
                    className="w-full flex items-center gap-2 px-4 py-2 bg-green text-white rounded-lg"
                >
                    Calculate Valuation
                </button>
            </div>
        </div>
    )
}
export default Main