

const TableSkeleton = () => {
  return (
    <div className="relative overflow-x-auto">
    <table className="w-full text-sm text-left border-main bg-green max-h-[700px]">
      <thead className="text-xs text-white uppercase bg-green border border-main sticky">
        <tr>
          <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">
          <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </th>
          <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">
          <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </th>
          <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">
          <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main">
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
        </tr>
        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main">
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
        </tr>
        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main">
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
        </tr>
        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main">
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
        </tr>
        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main">
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
        </tr>
        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main">
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
          <td className="px-6 py-4 text-black border border-main">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  )
}

export default TableSkeleton