import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import { TDATABASEMANAGEMENTSLICE, TTRADEMARKRECORD } from "./type"
import { setCountryisAction } from ".";
export const CountryRepresentative = () => {
    const {  CountryRecords, showResult, } = useSelector<RootState, TDATABASEMANAGEMENTSLICE>(
        (state) => state.CountryAnalysis
    );
    const dispatch = useAppDispatch();
    const setCountry = useCallback(
        (payload: TTRADEMARKRECORD) => {
            dispatch(setCountryisAction(payload));
        },
        [dispatch]
    );
    return {
        CountryRecords,
        showResult,
        setCountry
    };
}