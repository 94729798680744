import { api } from "config";

export const generateAndDownloadPDF = async (reportData: any, filename:any) => {
  try {
    const formData = new FormData();

    // Append each field from reportData to formData
    Object.keys(reportData).forEach((key) => {
      if (key === 'logo') {
        formData.append('logo', reportData.logo, 'logo.png');
      } else {
        formData.append(key, reportData[key]);
      }
    });
    formData.append("filename", filename)
    const response = await api.post("/generate-pdf", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob", // Ensure response type is set to blob
    });

    if (response.status >= 200 && response.status < 300) {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "report.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      console.error("Failed to generate PDF", response.statusText);
    }
  } catch (error) {
    console.error("Error while generating PDF", error);
  }
};


