import { Chart, registerables } from 'chart.js';
import { Bar } from "react-chartjs-2";
Chart.register(...registerables);

type TProps = {
    budget: any;
    cost: any
};
const BudgetTracker = ({ budget, cost }: TProps) => {
    const options:any = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        indexAxis: "y",
        plugins: {
            tooltip: {
                callbacks: {
                    label: (value: any) =>
                        value.dataset.label + ": GBP " + value.formattedValue,
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    callback: function (value: any, index: any, values: any) {
                        return "GBP " + value;
                    },
                },
            },
            y: {
                stacked: true,
            },
        },
    };

    let data = {
        labels: [new Date().toLocaleDateString()],

        datasets: [
            {
                data: [budget],
                backgroundColor: "rgba(233,78,25,1)",
                hoverBackgroundColor: "rgba(233,78,25,0.5)",
                label: "Budget",
            },
            {
                data: [budget * 1.1],
                backgroundColor:
                    cost > budget * 1.1 ? "rgba(255,193,7,1)" : "rgba(124,252,0,1)",
                hoverBackgroundColor:
                    cost > budget * 1.1 ? "rgba(255,193,7,0.5)" : "rgba(124,252,0,0.5)",
                label: "10% Margin",
            },
            {
                data: [budget * 1.15],
                backgroundColor:
                    cost > budget * 1.15 ? "rgba(220,0,0,1)" : "rgba(0,128,0,1)",
                hoverBackgroundColor:
                    cost > budget * 1.15 ? "rgba(255,0,0,0.5)" : "rgba(0,128,0,0.5)",
                label: "15% Margin",
            },
        ],
    };
    return (
        <div className='w-[220px] h-[100px]'>
            <p>
                Current Cost: <span className="font-bold text-sm">GBP {cost}</span>
            </p>
            <Bar data={data} options={options}/>
        </div>
    );
};

export default BudgetTracker;
