
const CustomRadioButton = ({
    id,
    name,
    value,
    label,
    imageSrc,
    checked,
    onChange,
    title,
}: any) => {
    console.log("checked", value);
    return (
        <div className="w-full max-w-[290px]">
            <label className={`border  p-2 py-4 items-center my-4 gap-x-4 flex justify-center rounded-xl border-[#033d44] ${checked ? "border-2 " : ""}`}>
                <input
                    type="radio"
                    id={id}
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    className="hidden"
                />
                <div className="flex-col">
                    <div className="flex">
                        <div className="gap-y-4">
                            <h1 className="text-xl text-primary font-semibold">{label}</h1>
                            <div>
                            </div>

                            <div>
                            </div>
                        </div>

                    </div>

                </div>

                <div
                    className={`border rounded-full border-gray-400 ml-auto w-10 h-10 ${checked ? "border-[#033d44]" : ""
                        }`}
                >
                    <div
                        className={`w-[29px] h-[29px] border rounded-full border-gray-400 ml-auto mx-auto mt-[5px] ${checked ? "bg-[#033d44] border-[#033d44]" : "hidden"
                            }`}
                    />
                </div>
            </label>

        </div>
    );
};
export default CustomRadioButton
