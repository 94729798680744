import React, { useState } from "react";
import * as d3 from "d3";
import { FeatureCollection } from "geojson";

type MapProps = {
  width: number;
  height: number;
  geoData: any;
  numData: { col1: string; col2: number }[] | undefined;
  selection:{ col1: string; col2:  number }[] | undefined;
  
};

export const Map = ({ width, height, geoData, numData,selection }: MapProps) => {
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCountryData, setSelectedCountryData] = useState<any | null>(
    null
  );

  const customColorScale = d3
    .scaleQuantize<string>()
    .domain([0, 100]) // Adjust the domain as per your data
    .range(["#033d44", "#033d44", "#033d44", "#033d44"]); // Define your custom colors

  // Your existing code
  const projection = d3
    .geoMercator()
    .scale(width / 2 / Math.PI - 20)
    .center([10, 34]);

  const geoPathGenerator = d3.geoPath().projection(projection);

  const handleCountryHover = (name: string) => {
    setSelectedCountry(name);
  };

  const handleCountryMouseOut = () => {
    setSelectedCountry(null);
  };


  const allSvgPaths = geoData.features
    .filter((shape: any) => shape.id !== "ATA")
    .map((shape: any) => {
      const select= selection?.length? selection: numData;
      const regionData =  select?.find(
        (region) => region.col1 === shape.properties.name
      );
      const color = regionData ? customColorScale(regionData?.col2) : "#A8A8A8";
      return (
        <path
          key={shape.id}
          d={geoPathGenerator(shape) || ""}
          stroke="lightGrey"
          fill={color}
          fillOpacity={1}
          onMouseOver={() => handleCountryHover(shape.properties.name)}
          onMouseOut={handleCountryMouseOut}
        />
      );
    });
  const renderModal = () => {
    if (selectedCountry) {
      const countryData = numData?.find(
        (region) => region.col1 === selectedCountry
      );
      return (
        <div className="relative  text-green justify-center items-center">
          {/* Element triggering the tooltip */}
          <div className="cursor-pointer">
            {/* Content of the element */}
            <div className="text-green font-bold ml-4">{`Country Name: ${selectedCountry}`}</div>
            <p
              className={
                countryData
                  ? countryData.col2 === 0
                    ? "text-[#FF0000]"
                    : "text-green font-bold"
                  : "text-blue"
              }
            >
              {countryData ? (
                <span className="text-green font-bold ml-4">{`Number of TradeMarks : ${countryData.col2}`}</span>
              ) : (
                <span className="text-[#FF0000] font-bold ml-8">
                  No Trade Mark
                </span>
              )}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal justify-center item-center flex  text-green ml-8">
          <h3 className="font-bold ml-4">Hover to See Trade Mark Value</h3>
        </div>
      );
    }
  };

  return (
    <div className="flex justify-center items-center flex-col mx-auto">
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMid meet"
        style={{ width: "100%", height: "auto" }}
      >
        {allSvgPaths}
      </svg>
      {renderModal()}
    </div>
  );
};
