
import './App.css';
import MyRoutes from 'routes';
import MainLayout from 'components/Layout/mainLayout';
import Toast from 'components/Notification';

function App() {
  return (
    <MainLayout>
       <MyRoutes />
      <Toast />
      
    </MainLayout>
  );
}

export default App;
