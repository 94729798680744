import React from "react";
import LoginForm from "./components/Form";

const Login = () => {
  return (
    <div className="h-screen w-full bg-[#A1ADA8] justify-center flex items-center ">
      <div className="justify-center flex items-center mx-3">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
