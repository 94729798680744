const InputField = ({ label, name, value, type = "number", min, max, step = "any", handleInputChange }: any) => (
    <div className="space-y-2">
        <label className="text-sm font-medium">{label}</label>
        <input
            type={type}
            name={name}
            value={value}
            onChange={handleInputChange}
            min={min}
            max={max}
            step={step}
            className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl focus:outline-none"
        />
    </div>
);

export default InputField