const AuditModal = ({ setShowAuditModal }: any) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Conflict Audit Log</h2>
                <button onClick={() => setShowAuditModal(false)} className="text-gray-500">✕</button>
            </div>
            <div className="space-y-4">
                <div className="flex items-center p-4 mb-4 text-sm text-[#991B1B] border border-[#FCA5A5] rounded-lg bg-[#FEF2F2]">
                    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-bold">Critical Conflict Detected</span>
                        <p>Active litigation with Frasers Group PLC</p>
                    </div>
                </div>
                <div className="mt-4">
                    <h3 className="font-bold mb-2">Audit Trail</h3>
                    <div className="space-y-2">
                        <div className="text-sm">
                            <div className="font-medium">2024-02-28 14:30</div>
                            <div className="text-gray-600">Initial conflict check performed</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default AuditModal