import { createSlice } from "@reduxjs/toolkit";
import { TDATABASEMANAGEMENTSLICE, TTRADEMARKRECORD } from "./type";
const initialState: TDATABASEMANAGEMENTSLICE = {
     Records: null,
    showResult:false
}
const mostFrequencySlice = createSlice({
    name: "FrequencyAnalysis",
    initialState,
    reducers: {
        setFrequencyAnalysisAction: (state, action: { payload: TTRADEMARKRECORD}) => {
            return {
                ...state,
                Records: action.payload,
                showResult:true
            };
        },
    }
})
export const { setFrequencyAnalysisAction } = mostFrequencySlice.actions;

export default mostFrequencySlice.reducer;