import React, { useState, useEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { api } from "config";
import Loader from "components/Loader";
import { useTable } from "react-table";
import { formatDate } from "utils/index";
import { MOSTVULNERABLENONUSETRADEMARKS } from "./../../../../constants";
import ChartTableView from "components/ChartTable";
interface IProps {
  data: any;
  showUnusedTrademarks: boolean;
  setShowUnusedTrademarks: any
}
const MostVulnerableNonUseTrademarks = ({ data, showUnusedTrademarks, setShowUnusedTrademarks }: IProps) => {
  const [items, setItems] = useState<any>([]);
  const close = () => {
    setShowUnusedTrademarks(false);
  };
  useEffect(() => {
    const newItems = data?.filter((item: { [x: string]: any }) => item["App. date"] !== null)
      .map((item: any) => ({
        col1: item.Classes,
        col2: item.Country,
        col3: item["Reg. date"],
        col4: item["Reg. no."] ? item["Reg. no."] : item["App. date"],
        col5: item["Trade Mark"],
        logoSection:item["Image Path"]
      }));
    setItems(newItems);

  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: MOSTVULNERABLENONUSETRADEMARKS, data: items });
  const divElement: any = document.querySelector('.infinite-scroll-component');

  // Check if the element is found
  if (divElement) {
    // Set overflow to hidden
    divElement.style.overflow = 'hidden';
  }
  return (
    <>
      <div className="overflow-hidden">
        <div id="scrollableDiv" style={{ height: "700px", overflow: "auto" }} >
          <table
            className="w-full text-sm text-left border-main bg-green "
            {...getTableProps()}
          >
            <thead className="text-xs text-white uppercase bg-green border border-main sticky">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main"
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-6 py-4 text-black border border-main"
                      >
                        {cell.column.id === "col3"
                          ? formatDate(cell.value): cell.column.id === 'logoSection' ? cell.value && cell.value !=='null'? <img src={process.env.REACT_APP_URL+cell.value} className="h-12 w-12"/>:"-"
                          : cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {showUnusedTrademarks ?
        <ChartTableView column={MOSTVULNERABLENONUSETRADEMARKS} data={null}
          title="Trade Marks Vulnerable to Cancellation for Non-Use"
          endpoint={'unused_trademarks'}
          params={''}
          paramsValue={"Most Vulnerable Non-Use Trademarks"}
          openModal={showUnusedTrademarks}
          isClose={close}
          fields={
            ["Classes", "Country", "Reg. date", "Reg. no.", "Trade Mark","Image Path"]
          }
          reportName="result_vulnerable_nonuse_trademark"
        />
        : null
      }
    </>
  );
};

export default MostVulnerableNonUseTrademarks;


