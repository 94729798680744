// "use client"
// import Skeleton from "components/ChartSkeleton";
// import React, { useEffect, useRef, useState } from "react";
// import { Bar } from "react-chartjs-2";
// import { TCOUNTRYDISTRIBUTION } from "state/trademarkAnalysis/type";

// interface IProps {
//   Data: TCOUNTRYDISTRIBUTION | null
//   axis: "x" | "y";
// }

// const StackBarChart = ({ Data, axis }: IProps) => {
//   const [reDraw, setRedraw] = useState(false);
//   const graphRef = useRef<HTMLDivElement>(null);


//   const totalCount = Data.reduce((total, item) => total + item.Count, 0);

//   const percentages = Data.map((item) => ({
//     ...item,
//     Percentage: (item.Count / totalCount) * 100,
//   }));


//   const data = {
//     labels: percentages.map((item) => item.Country),
//     datasets: [
//       {
//         label: "Country Distribution",
//         data: percentages.map((item) => item.Percentage),
//         borderWidth: 1,
//         borderRadius: 20,
//         backgroundColor: "#033d44",
//       },
//     ],
//   };

//   const options = {
//     indexAxis: axis,
//     scales: {
//       x: {
//         display: true,
//         ticks: {
//           autoSkip: false,
//           color: "black",
//           font: {
//             size: 10,
//           },
//         },
//         stacked: true,
//         grid: {
//           display: true,
//           color: "#11241D",
//         },
//       },
//       y: {
//         display: true,
//         ticks: {
//           autoSkip: false,
//           color: "black",
//           font: {
//             size: 15,
//           },
//         },
//         stacked: true,
//         grid: {
//           display: true,
//           color: "#11241D",
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         labels: {
//           color: "black",
//         },
//       },
//       tooltip: {
//         callbacks: {
//           label: function (tooltipItem: any) {
//             return `Country Distribution: ${tooltipItem.raw.toFixed(2)}%`;
//           },
//         },
//       },
//     },
//   };

//   const gradientBackground = `#ECECEC`;
//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     // Always show the skeleton for at least 3 seconds
//     const timer = setTimeout(() => {
//       if (Data.length) {
//         setLoading(false);
//       }
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, [Data]);

//   return (
//     <div>
//       {loading ? (
//         <div className="max-w-full">
//           <Skeleton />
//         </div>
//       ) : ( <div
//         className="border-2 rounded-md mt-4 p-4 border-[#DCDCDC] max-w-full"
//         style={{ background: gradientBackground }}
//       >
//         <Bar data={data} options={options} />
//       </div>)}
     
//     </div>
//   );
// };

// export default StackBarChart;


"use client";
import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { TCOUNTRYDISTRIBUTION } from "state/trademarkAnalysis/type";
import Skeleton from "components/ChartSkeleton";

interface IProps {
  Data: TCOUNTRYDISTRIBUTION | null;
  axis: "x" | "y";
}

const StackBarChart = ({ Data, axis }: IProps) => {
  const [loading, setLoading] = useState(true);
  const graphRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Always show the skeleton for at least 1 second
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (!Data || Data.length === 0) {
    return <div>  <Skeleton /></div>;
  }

  const totalCount = Data.reduce((total, item) => total + item.Count, 0);

  const percentages = Data.map((item) => ({
    ...item,
    Percentage: (item.Count / totalCount) * 100,
  }));

  const chartData = {
    labels: percentages.map((item) => item.Country),
    datasets: [
      {
        label: "Country Distribution",
        data: percentages.map((item) => item.Percentage),
        borderWidth: 1,
        borderRadius: 20,
        backgroundColor: "#033d44",
      },
    ],
  };

  const options = {
    indexAxis: axis,
    scales: {
      x: {
        display: true,
        ticks: {
          autoSkip: false,
          color: "black",
          font: {
            size: 10,
          },
        },
        stacked: true,
        grid: {
          display: true,
          color: "#11241D",
        },
      },
      y: {
        display: true,
        ticks: {
          autoSkip: false,
          color: "black",
          font: {
            size: 15,
          },
        },
        stacked: true,
        grid: {
          display: true,
          color: "#11241D",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "black",
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `Country Distribution: ${tooltipItem.raw.toFixed(2)}%`;
          },
        },
      },
    },
  };

  return (
    <div>
      {loading ? (
        <div className="max-w-full">
          <Skeleton />
        </div>
      ) : (
        <div
          className="border-2 rounded-md mt-4 p-4 border-[#DCDCDC] "
          style={{ background: "#ECECEC"} }
        >
          <Bar data={chartData} options={options} />
        </div>
      )}
    </div>
  );
};

export default StackBarChart;

