import LeftSidebar from "components/SideBar/LeftsideBar";
import { AppRoutes } from "./AppRoutes";
import { AuthRoutes } from "./AuthRoutes";
import { useEffect,useState } from "react";
import ScrollToTop from "./components/ScrollToTop";

function MyRoutes() {
  const [userData, setUserData] = useState(false);
  useEffect(() => {
 
    const storedUserData = localStorage.getItem('user');
    // Check if user data exists in local storage
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      const { isAuthenticate } = parsedUserData;
      setUserData(isAuthenticate);
    }
  }, []);
  return (
    <div className="root">
       <ScrollToTop />
      {localStorage.getItem('user') ? (
        <>
          <LeftSidebar />
          <AppRoutes />
        </>
      ) : (
        <AuthRoutes />
      )}
    </div>
  );
}

export default MyRoutes;
