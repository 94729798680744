import { useState } from "react"
import { Link } from "react-router-dom";
type TProps = {
   open: boolean;
   data: any
}
const NavItemWithChildren = ({ open, data }: any) => {
   const [showMenu, setShowMenu] = useState(false)
   return (
      <>
         <div onClick={() => setShowMenu(!showMenu)} className="flex rounded-md px-2 pb-2 pt-1 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
            <img
               src={data.src}
               className="w-5 h-5 object-contain"
               style={{ filter: "invert(100%)" }}
            />
            <span className={`${!open && "hidden"
               } text-[#dcdcdc] origin-left duration-200`}>{data.title}</span>
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
               <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
            </svg>
         </div>
         <ul id="dropdown-example" className={`${showMenu || !open ? '' : 'hidden'} space-y-1 ${open && 'px-3'}`}>
            {data.children.map((item: any) => (
               <li className="flex rounded-md px-2 pb-2 pt-1 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2">
                  <img
                     src={item.src}
                     className="w-5 h-5 object-contain"
                     style={{ filter: "invert(100%)" }}
                  />
                  <Link to={item.link} className={`${!open && "hidden"
                     } flex text-[#dcdcdc] origin-left duration-200 text-sm`}>{item.title}</Link>
               </li>
            ))}

         </ul>
      </>
   )
}
export default NavItemWithChildren