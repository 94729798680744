const MetricCard = ({ title, value, icon: Icon, subtitle }: any) => (
    <div className="bg-white p-[25px] rounded-[5px] shadow-2xl">
        <div className="flex flex-row items-center justify-between space-y-0 pb-2">
            <span className="text-sm font-medium">{title}</span>
            <Icon className="h-4 w-4 text-muted-foreground" />
        </div>
        <div>
            <div className="text-2xl font-bold">{value}</div>
            <p className="text-xs text-muted-foreground">{subtitle}</p>
        </div>
    </div>
);

export default MetricCard