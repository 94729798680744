import Button from 'components/Button'
import React from 'react'
type IProps={
    title:String
    desc:String
    onClick:any
}
const GoodServices = ({title,desc,onClick}:IProps) => {
  return (
    <button className="w-full max-w-[600px] bg-white border border-main my-2 rounded-md px-4 text-left" onClick={onClick} type='button'>
        <h1 className="text-xl font-bold mb-2 cursor-pointer text-primary py-2">{title}</h1>
        <p className=' text-primary py-2'>{desc}</p>
  </button>
  )
}

export default GoodServices