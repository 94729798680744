import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export const GlobalFilter = ({ filter, setFilter }: any) => {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);
  return (
    <span className="font-bold mt-3 flex items-center justify-center gap-x-4 text-[20px] text-green">
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Type to search...`}
        className="border border-[#dcdcdc] p-4 rounded-md w-full h-12 text-black my-2 font-normal"
      />
    </span>
  );
};
